import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";


function PerformaInvoice({singleOrder}) {
    const invoiceRef = useRef();
    // Function to generate PDF

    const handlePrint = useReactToPrint({
      content: () => invoiceRef.current,
      documentTitle: `${singleOrder?.numericOrderId}-Invoice`,
    });

    let totalQuantity  = singleOrder?.products.reduce((acc,item)=>acc+item?.quantity,0)
  


  return (
    <div style={{position:'relative'}}>
      {/* Invoice Content */}
      <div ref={invoiceRef} style={{ padding: "20px",maxWidth: "800px", margin: "auto" }}>
        <h1 style={{ textAlign: "center" }}>Proforma Invoice</h1>
        <p>Invoice Number: <strong>#{singleOrder?.numericOrderId}</strong></p>
        <p>Customer Name: <strong>{singleOrder?.address?.name}</strong></p>
        <p>Date: <strong>{new Date(singleOrder?.createdAt).toLocaleDateString()}</strong></p>
        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Item</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Mrp</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {
                singleOrder && singleOrder.products.map((product)=>(
                    <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.name}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{product.mrp}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.quantity}</td>
                  </tr>
                ))
            }
            <tr>
              <th  style={{ border: "1px solid #ddd", padding: "8px",textAlign:'left' }} colSpan='2' >Total Quality</th>
              <th style={{ border: "1px solid #ddd", padding: "8px",textAlign:'left' }}>{totalQuantity||0}</th>
            </tr>
          </tbody>
        </table>

      </div>

      {/* Download Button */}
      <div style={{ textAlign: "center", marginTop: "20px",position:'absolute',right:10,top:10 }}>
        <button onClick={handlePrint} style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer" }}>
          Download PDF
        </button>
      </div>
    </div>
  )
}

export default PerformaInvoice