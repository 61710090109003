import React, { useEffect,useRef } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import MainCard from "../../ui-component/cards/MainCard";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Box, IconButton, Stack, Paper } from "@mui/material";
import S3 from "react-aws-s3";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  AddPhotoAlternate as AddPhotoIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

// Styled components
const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const ImagePreview = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
  backgroundColor: theme.palette.grey[100],
  "& img": {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
}));

const config = {
  bucketName: "buyumecrm",
  region: "ap-south-1",
  accessKeyId: "AKIAXJQSPHOB6EX3NSOV",
  secretAccessKey: "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",
  dirName: "order-packing",
};
function NonCrmPackingOrder() {
  const tableNumber = localStorage.getItem("tableNumber");
  const [disableBtn, setDisableBtn] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [weight, setWeight] = useState("");
  const [date, setDate] = useState({
    startDate: moment().clone().subtract(1, "day").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    if (selectedFiles.length + files.length > 3) {
      alert("You can only upload up to 3 images");
      return;
    }

    const newFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setSelectedFiles((prev) => [...prev, ...newFiles]);
  };

  const handleRemoveImage = (index) => {
    setSelectedFiles((prev) => {
      const newFiles = [...prev];
      URL.revokeObjectURL(newFiles[index].preview);
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setDisableBtn(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PATH}/fulfilment/orders/orderPackeging`,
      data: {
        orderId: orderId,
        nonCrmOrder: true,
        weight: +weight,
        packingImages: uploadedUrls,
      },
    })
      .then((res) => {
        if (res.data.headers?.success) {
          setDisableBtn(false);

          alert(res?.data?.headers?.message);
          setOrderId("");
          window.location.reload();
        } else {
          setDisableBtn(false);

          alert(res?.data?.headers?.message);
        }
      })
      .catch((err) => {
        setDisableBtn(false);
        alert(err?.response?.data?.headers?.message);
      });
  };
  const uploadImages = async () => {
    setUploadedUrls([]);
    setIsUploading(true);
    console.log("selectedFiles", selectedFiles);
    try {
      selectedFiles.forEach(async (fileObj, index) => {
        const ReactS3Client = new S3(config);
        await ReactS3Client.uploadFile(fileObj.file)
          .then((res) => {
            console.log("----", res);
            setUploadedUrls((pre) => [...pre, res.location]);
          })
          .catch((error) => {
            console.log("error", error);
            alert(error || "Someting went wrong");
          });
      });
    } catch (error) {
      console.error("Upload error:", error);
      alert("Error uploading files");
    } finally {
      setIsUploading(false);
    }
  };

  const dataLoad = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_PATH}/fulfilment/orders/packersOrders`, {
        startDate: date.startDate,
        endDate: date.endDate,
        nonCrmOrder: true,
      })
      .then((resp) => {
        setLoading(false);
        console.log("response of fulfilment/orders/packersOrders", resp.data);
        if (resp.data.headers?.success) {
          setList(resp.data.body);
        } else {
          alert(resp?.data?.headers?.message);
        }
      })
      .catch((err) => {
        alert("err?.response?.data?.headers?.message");
      });
  };

  useEffect(() => {
    dataLoad();
  }, []);

  return (
    <>
      <SubCard>
        <form onSubmit={(e) => submitForm(e)}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Table Number
                </InputLabel>
                <Select
                  value={tableNumber}
                  label="Table Number"
                  fullWidth
                  defaultValue={tableNumber}
                  disabled
                >
                  <MenuItem value={tableNumber}>{tableNumber}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} sm={4} xs={12}>
              <TextField
                label="Order Id"
                required
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item lg={3} sm={4} xs={12}>
              <TextField
                label="Order Weight"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Box sx={{ py: 2 }}>
            <Stack spacing={3}>
              <Box>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<AddPhotoIcon />}
                  disabled={
                    selectedFiles.length >= 3 ||
                    uploadedUrls.length > 0 ||
                    orderId.trim().length == 0
                  }
                >
                  Select Images
                  <VisuallyHiddenInput
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileSelect}
                  />
                </Button>
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                  Maximum 3 images allowed
                </Typography>
              </Box>

              <Grid container spacing={2}>
                {selectedFiles.map((file, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <ImagePreview elevation={2}>
                      <img
                        src={file.preview || "/placeholder.svg"}
                        alt={`Preview ${index + 1}`}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          bgcolor: "background.paper",
                        }}
                        disabled={uploadedUrls.length > 0}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ImagePreview>
                  </Grid>
                ))}
              </Grid>
              <Grid container>
                {selectedFiles.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={uploadImages}
                    disabled={isUploading || uploadedUrls.length > 0}
                    sx={{ width: 220 }}
                  >
                    {isUploading
                      ? "Uploading..."
                      : uploadedUrls.length > 0
                      ? "Image Uploaded"
                      : "Upload Image"}
                  </Button>
                )}
              </Grid>

              {/* {uploadedUrls.length > 0 && (
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Uploaded Image URLs:
                  </Typography>
                  {uploadedUrls.map((url, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      sx={{
                        wordBreak: "break-all",
                        mb: 1,
                      }}
                    >
                      {url}
                    </Typography>
                  ))}
                </Paper>
              )} */}
            </Stack>
          </Box>
          <Grid container>
            <Grid item lg={3} sm={12} xs={12}>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                disabled={disableBtn||(selectedFiles.length!=uploadedUrls.length)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </SubCard>
      {/* --------------------------Packer list---------------------------------- */}
      <MainCard title="Packer Orders">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} lg={2}>
            <TextField
              id="datetime-local"
              label="Packing Start Date"
              type="date"
              defaultValue={date.startDate}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "100%" }}
              size="small"
              onChange={(e) => setDate({ ...date, startDate: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              id="datetime-local"
              label="Packing End Date"
              type="date"
              defaultValue={date.endDate}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: "100%",
              }}
              onChange={(e) => setDate({ ...date, endDate: e.target.value })}
              size="small"
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <LoadingButton
              size="small"
              loading={loading}
              onClick={dataLoad}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Grid>
          {loading ? (
            <Typography
              style={{ marginTop: "20px" }}
              variant="h4"
              align="center"
            >
              Loading....
            </Typography>
          ) : (
            <TableContainer style={{ maxHeight: 440 }}>
              {list.length > 0 && (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Order Id</TableCell>
                      <TableCell align="left">Packing Time</TableCell>
                      <TableCell align="left">Weight</TableCell>
                      {/* <TableCell align="left" >Group Order Id</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((pack, idx) => {
                      return (
                        <TableRow
                          sx={{ py: 3 }}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={pack?._id}
                        >
                          <TableCell align="left">{pack?.orderId}</TableCell>
                          {moment(pack?.packingTime).format(
                            "YYYY-MM-DD hh:mm:ss A"
                          )}
                          {/* <TableCell align="left" >{pack?.groupOrderId ? pack?.groupOrderId : ""}</TableCell> */}
                          <TableCell align="left">{pack?.weight}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}
        </Grid>
      </MainCard>
    </>
  );
}

export default NonCrmPackingOrder;
