"use client"

import { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { Cancel, Send } from "@mui/icons-material"

export default function OrderCancelRemarkModal({ open, onClose, onSubmit }) {
  const [remark, setRemark] = useState("")
  const [buttonDis,setButtonDis] = useState(false)

  const handleSubmit = () => {
    setButtonDis(true)
    onSubmit(remark)
    setRemark("")
  }

  const handleClose = () => {
    setRemark("")
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Cancel Order</DialogTitle>
      <DialogContent>
        <DialogContentText>Please provide a reason for cancelling this order.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="remark"
          label="Remark"
          type="text"
          fullWidth
          variant="outlined"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={handleClose} variant="outlined" color="error" startIcon={<Cancel />}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          startIcon={<Send />}
          disabled={!remark.trim()||buttonDis}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

