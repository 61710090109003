import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconBuildingStore } from "@tabler/icons";

// constant
const icons = {
  IconBuildingStore: IconBuildingStore,
};
//-----------------------|| Customer MENU ITEMS ||-----------------------//

export const BulkOrders = {
  id: "Bulk Orders",
  title: <FormattedMessage id="Bulk Orders" />,
  type: "group",
  children: [
    {
      id: "Bulk Order",
      title: <FormattedMessage id="All Bulk Orders" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: [
        {
          id: "bulksearchorder",
          title: <FormattedMessage id="All Orders" />,
          type: "item",
          url: "/bulk/searchorders",
        },
        {
          id: "Reached At Destinations",
          title: <FormattedMessage id="Reached At Destinations Orders" />,
          type: "item",
          url: "/bulk/reachedatdestinationorder",
        },
        {
          id: "Processing Orders",
          title: <FormattedMessage id="Processing Orders" />,
          type: "item",
          url: "/bulk/processingorders",
        },
        {
          id: "Brand Purchase Report",
          title: <FormattedMessage id="Brand Purchase Report" />,
          type: "item",
          url: "/bulk/bulkbrandwisepurchasereport",
        },
        {
          id: "Stage wise order",
          title: <FormattedMessage id="Stage wise order" />,
          type: "item",
          url: "/bulk/stagewisebulkorders",
        },
      ],
    },
  ],
};
