import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { FormControl, InputLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ErrorIcon from "@mui/icons-material/Error";

import XLSX from "xlsx";
import { Checkbox, Divider, FormGroup, Select, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import * as ga from "../../ga";
import SubCard from "../../ui-component/cards/SubCard";
import { calculateDiscount } from "../Dashboard/Leads/CalcDiscount";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { CSVLink } from "react-csv";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

let appVersion = 693;
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const CreateOrderForBulk = () => {
  const accessNumbers = [
    "9719836187",
    "7001029850",
    "9315405080",
    "7701974309",
    "9667530528",
    "9891774071",
    "7701974309",
    "8374454823",
    "8178277221",
    "7532035127",
    "9268096291",
    "8826956878",
    "9873804039",
    "8750751510",
    "8969708515",
    "9693813591",
    "8294360196",
    "7838688729",
  ];
  const agentTypeFromLocal = localStorage.getItem("agentType");
  const agentPhoneNumber = getDecryptDataFromLocal("phoneNumber");
  const [showPackModal, setShowPackModal] = useState(false);
  const [sellerId, setSellerid] = useState(null);
  const [sellerAddress, setSellerAddress] = useState([]);
  const [sellerBillingAddress, setSellerBillingAddress] = useState([]);
  const [mobileSearchLoader, setMobileSearchLoader] = useState(false);
  const [sellerPhoneNumber, setSellerPhoneNumber] = useState("");
  const [searchClear, setSearchClear] = useState("");
  const [product, setProduct] = useState({});
  const [searchProduct, setSearchProduct] = useState([]);
  const [isMemberShip, setMembership] = useState(0);
  const [totalProductView, setTotalProductView] = useState([]);
  const [totalCartValue, setTotalCartValue] = useState([]);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [productQuantity, setProductQuantity] = useState(1);
  const [freeProductsFromBackend, setFreeProductsFromBackend] = useState([]);
  const [orderID, setOrderID] = useState("");
  const [captureOrderId, setCaptureOrderId] = useState("");
  const [disableAddToCartButton, setDisableAddToCartButton] = useState(true);
  const [selectAddressId, setSelectAddressId] = useState(null);
  const [selectBillingAddressId, setSelectBillingAddressId] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [facility, setFacility] = useState("1");
  const [packProductIds, setPackProductIds] = useState([]);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [isBillingModal, setIsBillingModal] = useState(false);
  const purchaseLimit = agentTypeFromLocal == "BULK" ? 300000000 : 1000000;
  const [pinCodeError, setPinCodeError] = React.useState("");
  const [pinCodeErrorForBill, setPinCodeErrorForBill] = React.useState("");
  const [phoneNumberError, setPhoneNumerError] = useState("");
  const [phoneNumberErrorForBill, setPhoneNumerErrorForBill] = useState("");
  const [loadingOfPlaceOrder, setLoadingOfPlaceOrder] = useState(false);
  const [packProductIdsExits, setPackProductIdsExits] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState(null);
  const [gstNumber, setGstNumber] = useState(null);
  const [traderName, setTraderName] = useState(null);
  const [sellerName, setSellerName] = useState(null);
  const [sellerType, setSellerType] = useState("");
  const [file, setFile] = useState({});
  const [fileUploadDis, setFileUploadDis] = useState(true);
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
  const [productWiseBulkdiscount, setProductWiseBulkdiscount] = useState({});
  const [productWiseBulkdiscountOld, setProductWiseBulkdiscountOld] = useState(
    {}
  );
  const [productWithForecast, setProductWithForecast] = useState([]);
  const [productWiseBulkdiscountFromFile, setProductWiseBulkdiscountFromFile] =
    useState({});
  const [productCnDiscount, setProductCnDiscount] = useState(10);
  const [cnDiscountFromFile, setCnDiscountFromFile] = useState({});
  const [actualDiscountFromFile, setActualDiscountFromFile] = useState({});
  const [cloneOrderId, setCloneOrderId] = useState("");
  const [cloneOrderModal, setCloneOrderModal] = useState(false);
  const [organization, setOrganization] = useState(1);
  const [orderType, setOrderType] = useState(1);
  const [shippingCostType, setShippingCostType] = useState(1);
  const [isGstNumberExist, setIsGstNumberExist] = useState(false);
  const [isPaymentGenerate,setIsPaymentGenerate] = useState(false)
  const [addAddress, setAddAddress] = useState({
    name: "",
    phoneNumber: "",
    landmark: "",
    address1: "",
    address2: "",
    alternatePhoneNumber: "",
    postalCode: "",
    city: "",
    state: "",
  });
  const [addBillingAddress, setBillingAddAddress] = useState({
    name: "",
    phoneNumber: "",
    landmark: "",
    address1: "",
    address2: "",
    alternatePhoneNumber: "",
    postalCode: "",
    city: "",
    state: "",
  });
  const [addressId, setAddressId] = useState(null);
  const [addressIdForBill, setAddressIdForBill] = useState(null);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [isPrepaid, setIsPrepaid] = useState(false);
  const [orderDate, setOrderDate] = useState(new Date());
  const [cloneData, setCloneData] = useState(null);
  // const [onlinePurchaseLimit, setOnlinePurchaseLimit] = useState(100000);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectDeliveryPartner, setSelectDeliveryPartner] =
    useState("ROCKETBOX");
  const [shippingData, setShippingData] = useState({});
  const [codError, setCodError] = useState("");
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  const cloneOrder = (orderId) => {
    axios
      .get(process.env.REACT_APP_PATH + "/sales/getBulkOrderById/" + orderId)
      .then((res) => {
        if (res.data.success) {
          let sellerData = res.data.data;
          setCloneData(sellerData);
          setSellerPhoneNumber(sellerData.phoneNumber);
          getSellerProfile(sellerData.phoneNumber);
          addMultipleProductOnCart(
            sellerData.products,
            [],
            sellerData.sellerId
          );
          let filterAgents = agentsData.filter(
            (agent) => agent._id == sellerData.agentId
          );
          setSelectedAgents(filterAgents[0]?._id||null);
          setCloneOrderModal(false);
        } else {
          alert(res.data.message);
          setCloneOrderModal(false);
        }
      })
      .catch((error) => {
        alert("someting went wrong");
        setCloneOrderModal(false);
      });
  };

  const handlePinCode = (e) => {
    // setPinCode(e.target.value)
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      if (isBillingModal) {
        setBillingAddAddress((prev) => ({
          ...prev,
          postalCode: e.target.value,
        }));
      } else {
        setAddAddress((prev) => ({
          ...prev,
          postalCode: e.target.value,
        }));
      }
    }

    if (e.target.value.length === 6) {
      if (Number(e.target.value)) {
        axios
          .get(
            process.env.REACT_APP_PATH +
              `/leads/getCityAndState?pincode=${e.target.value}`
          )
          .then((res) => {
            if (res.data.success) {
              if (isBillingModal) {
                setPinCodeErrorForBill("");
                setBillingAddAddress((prev) => ({
                  ...prev,
                  city: res?.data?.data?.city,
                  state: res?.data?.data?.state,
                }));
              } else {
                setPinCodeError("");
                setAddAddress((prev) => ({
                  ...prev,
                  city: res?.data?.data?.city,
                  state: res?.data?.data?.state,
                }));
              }
            }
          })
          .catch((err) => {
            if (isBillingModal) {
              setPinCodeErrorForBill(err?.response?.data?.message || "Invalid");
              setBillingAddAddress((prev) => ({
                ...prev,
                city: "",
                state: "",
              }));
            } else {
              setPinCodeError(err?.response?.data?.message || "Invalid");
              setAddAddress((prev) => ({
                ...prev,
                city: "",
                state: "",
              }));
            }
          });
      }
    } else if (e.target.value != undefined && e.target.value.length > 0) {
      if (isBillingModal) {
        setBillingAddAddress((prev) => ({
          ...prev,
          city: "",
          state: "",
        }));
        setPinCodeErrorForBill("Invalid pincode");
      } else {
        setAddAddress((prev) => ({
          ...prev,
          city: "",
          state: "",
        }));
        setPinCodeError("Invalid pincode");
      }
    }
  };

  const checkPhoneNumber = (phoneNumber, isBilling) => {
    axios
      .post(process.env.REACT_APP_PATH + "/seller/checkPhoneNumberForCrm", {
        phoneNumber: phoneNumber,
        sellerId: sellerId,
      })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            if (isBilling) {
              setPhoneNumerErrorForBill("This phone number can't add");
            }
            setPhoneNumerError("This phone number can't add");
          } else {
            if (isBilling) {
              setPhoneNumerErrorForBill("");
            } else {
              setPhoneNumerError("");
            }
          }
        }
      })
      .catch((error) => {
        console.log("error of check phonenumber", error);
      });
  };

  const handelAddressChange = (e, isBilling) => {
    if (
      e.target.name == "phoneNumber" &&
      e.target.value.length == 10 &&
      localStorage.getItem("agentType") != "BULK" && isBilling==false
    ) {
      checkPhoneNumber(e.target.value, isBilling);
    }
    if (!isBilling) {
      setAddAddress((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else {
      setBillingAddAddress((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const saveAddess = () => {
    let addressObj = {
      sellerId: sellerId,
      ...addAddress,
    };
    let addressObForBilling = {
      sellerId: sellerId,
      ...addBillingAddress,
    };
    let url = process.env.REACT_APP_PATH + "/seller/addNewAddressCrm";
    let urlForBilling =
      process.env.REACT_APP_PATH + "/seller/addNewBillingAddressForBulkOrder";

    axios
      .post(
        isBillingModal ? urlForBilling : url,
        isBillingModal ? addressObForBilling : addressObj
      )
      .then((res) => {
        if (res.data.success) {
          if (isBillingModal) {
            // setSellerBillingAddress((pre) => [
            //   ...pre,
            //   { ...addAddress, _id: res.data.data.addressId, isActive: true },
            // ]);

            setBillingAddAddress({
              name: "",
              phoneNumber: "",
              landmark: "",
              address1: "",
              address2: "",
              alternatePhoneNumber: "",
              postalCode: "",
              city: "",
              state: "",
            });
            setOpenAddressModal(false);
          } else {
            // setSellerAddress((pre) => [
            //   ...pre,
            //   { ...addAddress, _id: res.data.data.addressId, isActive: true },
            // ]);

            setAddAddress({
              name: "",
              phoneNumber: "",
              landmark: "",
              address1: "",
              address2: "",
              alternatePhoneNumber: "",
              postalCode: "",
              city: "",
              state: "",
            });
            setOpenAddressModal(false);
          }
          getSellerProfile(sellerPhoneNumber);
        } else {
          window.alert(res.data?.message || "Something went wrong");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const editAddressInCrm = () => {
    let addressObj = {
      sellerId: sellerId,
      addressId: addressId,
      ...addAddress,
    };
    let addressObjForBilling = {
      sellerId: sellerId,
      addressId: addressIdForBill,
      isBillingAddress: isBillingModal,
      ...addBillingAddress,
    };

    axios
      .post(
        process.env.REACT_APP_PATH + "/role1/updateSellerAddressByCrm",
        isBillingModal ? addressObjForBilling : addressObj
      )
      .then((res) => {
        if (res.data.success) {
          if (isBillingModal) {
            setBillingAddAddress({
              name: "",
              phoneNumber: "",
              landmark: "",
              address1: "",
              address2: "",
              alternatePhoneNumber: "",
              postalCode: "",
              city: "",
              state: "",
            });
            setOpenAddressModal(false);
            setAddressIdForBill(null);
          } else {
            setAddAddress({
              name: "",
              phoneNumber: "",
              landmark: "",
              address1: "",
              address2: "",
              alternatePhoneNumber: "",
              postalCode: "",
              city: "",
              state: "",
            });
            setOpenAddressModal(false);
            setAddressId(null);
          }

          getSellerProfile(sellerPhoneNumber);
        } else {
          window.alert(res.data?.message || "Something went wrong");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getSellerProfile = async (value, status) => {
    if (value) {
      axios
        .post(`${process.env.REACT_APP_PATH}/role1/allSeller`, {
          phoneNumber: value,
          isCrmOrder: true,
        })
        .then((res) => {
          if (res?.data?.success) {
            let sellerres = res.data.data[0]?.data[0];
            setMembership(sellerres?.activeMembership[0]?.discountPercent || 0);
            if (sellerres.refferdByAgenttype !== "BULK") {
              alert(`Seller is not BULK type, order can't be placed`);
            }
            setSellerType(sellerres?.refferdByAgenttype || "");

            setGstNumber(sellerres?.gstNumber || "");
            setIsGstNumberExist(
              sellerres?.gstNumber?.trim()?.length == 15 ? true : false
            );
            setTraderName(sellerres?.businessName);
            setSellerName(sellerres?.name);
            setSellerid(res.data.data[0]?.data[0]?._id);
            setSellerAddress(res.data.data[0]?.data[0]?.addresses || []);
            setSellerBillingAddress(sellerres?.billingAddress || []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handelSellerSearch = (event) => {
    setSellerPhoneNumber(event.target.value);
    if (event.target.value.length == 10) {
      getSellerProfile(event.target.value);
    } else {
      setSellerid(null);
    }
  };

  const checkDealProduct = async (product) => {
    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_PATH}/role5/checkForDeal?productId=${
        product._id || product.productId
      }`,
    };
    let response = await axios(config);
    if (response.data.success) {
      if (response.data.data.isDealProduct) {
        product.dealId = response.data.data.dealId;
        product.productType = response.data.data.productType;

        return [product];
      } else {
        return [product];
      }
    } else {
      console.log("errr.");
    }
  };

    // Parse product API
    const parseProduct = async (productId) => {
      setDisableAddToCartButton(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_PATH}/sales/parseProductForCrm/?productId=${productId}&sellerId=${sellerId}`);
        if (res.data.success && res.data.data[0]?.isAvailable) {
          setDisableAddToCartButton(false);
          return { status: true, data: res.data.data[0] };
        }
        setDisableAddToCartButton(false);
        return { status: false, productId };
      } catch (error) {
        console.error("Parse Product Error", error);
        setDisableAddToCartButton(false);
        return { status: false, productId };
      }
    };

  const currentProductCart = async (item) => {
    const res = await checkDealProduct(item);
    item.dealId = res[0]?.dealId || undefined;
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PATH}/role5/addProductsToCurrentCart`,
      data: {
        product: item,
        sellerId: sellerId,
      },
    })
      .then((res) => {
        if (res.data.success) {
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };


  const checkPurchaseLimit = (item, qty) => {
    let tt = 0;
    if (qty >= 0) {
      tt = item.price * qty - item.price * item.quantity;
    } else {
      tt = item.price;
    }

    let checkTotalAmount = totalPayableAmount + tt;
    return checkTotalAmount;
  };

  const getDuplicateProduct = (product) => {
    return totalProductView.filter((item) => {
      if (product?.comboId) {
        return item?.comboId == product.comboId;
      } else {
        return item.productId == product.productId;
      }
    });
  };

  // const productBulkDiscountUpdate = async () => {
  //   // productWiseBulkdiscount
  //   for (const product in productWiseBulkdiscount) {
  //     let res = await axios.post(
  //       process.env.REACT_APP_PATH + "/role1/updateSellerProducts",
  //       {
  //         productId: product,
  //         bulkDiscount:
  //           productWiseBulkdiscount[product] &&
  //           productWiseBulkdiscount[product] >= 100
  //             ? 99.9
  //             : productWiseBulkdiscount[product] || 0,
  //       }
  //     );
  //   }
  //   window.alert("Product Discount Update Successful");
  //   createOrderApi('productBulkDiscountUpdate',totalProductView);
  // };

  const productBulkDiscountUpdate = async () => {
    setLoadingCreateOrder(true)
    try {
      const updatePromises = Object.keys(productWiseBulkdiscount).map(product =>
        axios.post(
          `${process.env.REACT_APP_PATH}/role1/updateSellerProducts`,
          {
            productId: product,
            bulkDiscount:
              productWiseBulkdiscount[product] && productWiseBulkdiscount[product] >= 100
                ? 99.9
                : productWiseBulkdiscount[product] || 0,
          }
        )
      );
  
      await Promise.all(updatePromises);
  
      window.alert("Product Discount Update Successful");
      createOrderApi('productBulkDiscountUpdate', totalProductView);
    } catch (error) {
      console.error("Error updating product discounts:", error);
      window.alert("Failed to update some product discounts. Please try again.");
    }
  };

  const updateSeller = async () => {
    try {
      var sellerProfileReq = {
        sellerId: sellerId,
        gstNumber: gstNumber,
        businessName: traderName,
        name: sellerName,
      };
      await axios
        .post(
          process.env.REACT_APP_PATH + "/role1/updateSellerProfile",
          sellerProfileReq
        )
        .then(function (ress) {
          alert("Profile updated successfully");
        })
        .catch(function (error) {
          alert("Profile Not Update");
          console.log(error, "Seller profile details error");
        });
    } catch (error) {
      console.log(error, "api error");
    }
  };

  let findProductsById = async (productId) => {
    try {
      let response = await axios.get(
        process.env.REACT_APP_PATH +
          "/api/productDetailById?productId=" +
          productId
      );
      if (response.data.success) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const updateFreeProductDiscount = async (productId, item, newDiscount) => {
    let discount = calculateDiscount(item, isMemberShip);
    try {
      let res = await axios.post(
        process.env.REACT_APP_PATH + "/role1/updateSellerProducts",
        {
          productId: productId,
          bulkDiscount: newDiscount - discount,
        }
      );
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const addFreeProductsInOrder = async (products) => {
    let findFreeProducts = products.filter((pro) =>
      ["65b8e061fde3e7301d0c45ea", "6618c1b84118b3cdcc681ad0"].includes(
        pro.productId
      )
    );

    if (findFreeProducts.length == 0) return products

    let sumOfFreeProducts =
      findFreeProducts &&
      findFreeProducts.length > 0 &&
      findFreeProducts.reduce((acc, item) => (acc += +item.quantity), 0);

    let freeProduct = await findProductsById("65c05ec4dc8f05a1e1c37474");

    let findIndexOfFreeProducts = products.findIndex(
      (item) => item.productId == "65c05ec4dc8f05a1e1c37474"
    );
    let updateDiscount = await updateFreeProductDiscount(
      "65c05ec4dc8f05a1e1c37474",
      freeProduct,
      99.99
    );

    if (!updateDiscount) return products

    if (freeProduct) {
      if (findIndexOfFreeProducts > -1) {
        products[findIndexOfFreeProducts].quantity = sumOfFreeProducts;
        products[findIndexOfFreeProducts].discount = 99.99;
      } else {
        products.push({
          ...freeProduct,
          quantity: sumOfFreeProducts,
          discount: 99.99,
        });
      }
    }

    return products;
  };

  const createOrderApi = async (
    source,
    products,
    tempBulkDis = productWiseBulkdiscountFromFile,
    addressId = selectAddressId,
    address2 = selectAddressId || null
  ) => {
    console.log('source',source)
    if(isPaymentGenerate) return 
    setLoadingCreateOrder(true);
    setOrderID(null);
    setLoadingOfPlaceOrder(true);
    try {
      let reqObj = null;
    let freeProductAdded = await addFreeProductsInOrder(products);
    reqObj = {
      products: products,
      paymentMethod: Number(paymentMethod),
      bookingSource: 2,
      rewardProducts: undefined,
      freeProducts: [],
      sellerId: sellerId,
      allowCoupon: false,
      appVersion,
      gstNumber,
      createdAt: orderDate,
      shippingCostType: shippingCostType,
    };
    if (address2?.postalCode) {
      reqObj.pincode = address2?.postalCode;
    }

    if (!gstNumber) {
      delete reqObj.gstNumber;
    }
      // API Request
      const { data } = await axios.post(
      `${process.env.REACT_APP_PATH}/sales/createBulkOrder`,
      reqObj
    );

    if (!data.success) throw new Error(data.message);

    // Process API response
    const orderData = data.data;
    setIsPrepaid(orderData?.isMandatoryPrepaid);
    setOrderID(orderData._id);
    setCaptureOrderId(orderData._id);


    if (orderData.isMandatoryPrepaid) {
      checkCodLimit(orderData.sellerId, orderData.totalPayableAmount);
    }

    if (data.message?.length > 0) {
      alert(data?.message||"Something went wrong")
      resetCartStates();
    } else {
      processOrderResponse(orderData, tempBulkDis);
    }

    // Handle address association
    if (addressId) addAddressInOrder(address2, orderData._id);
    if (cloneOrderId) addAddressInOrder(cloneData.address, orderData._id);

    }  catch (error) {
      alert(error.message || "Order creation failed.");
    } finally {
      setLoadingCreateOrder(false);
      setLoadingOfPlaceOrder(false);
    }
    // axios({
    //   method: "POST",
    //   url: `${process.env.REACT_APP_PATH}/sales/createBulkOrder`,
    //   data: reqObj,
    // })
    //   .then((res) => {
    //     if (res.data.success) {
    //       setIsPrepaid(res.data.data?.isMandatoryPrepaid);
    //       setOrderID(res.data.data._id);
    //       setCaptureOrderId(res.data.data._id);
    //       if (res.data.data?.isMandatoryPrepaid) {
    //         checkCodLimit(
    //           res.data.data?.sellerId,
    //           res.data.data?.totalPayableAmount
    //         );
    //       }
    //       if (res?.data?.message?.length > 0) {
    //         setTotalCartValue([]);
    //         setTotalProductView([]);
    //         setPackProductIds([]);
    //         setLoadingOfPlaceOrder(false);
    //       } else {
    //         setTotalPayableAmount(res.data.data?.totalPayableAmount || 0);
    //         setTotalCartValue(res.data.data);
    //         if (
    //           paymentMethod == "6" ||
    //           paymentMethod == 6 ||
    //           paymentMethod == "2" ||
    //           paymentMethod == 2
    //         ) {
    //           createRazorPayId(res.data.data._id);
    //         }
    //         res.data.data.products?.length > 0 &&
    //           setFreeProductsFromBackend(
    //             res.data.data.products.filter((a, ind) => {
    //               return a.productType == "Free";
    //             })
    //           );
    //         let temp = [...reqObj.products];
    //         if (res.data.data.products && res.data.data.products.length > 0) {
    //           res.data.data.products.forEach((item) => {
    //             setPackProductIdsExits([
    //               ...packProductIdsExits,
    //               item.productId,
    //             ]);
    //             if (item.comboId) {
    //               setPackProductIds([...packProductIds, item.comboId]);
    //             }
    //             temp.forEach((pro) => {
    //               if (
    //                 pro.comboId != undefined &&
    //                 item?.comboId != undefined &&
    //                 pro.comboId == item.comboId
    //               ) {
    //                 pro.discount = item.discount;
    //                 // setProductWiseOnlineBulkdiscount((pre) => ({
    //                 //   ...pre,
    //                 //   [item._id]: tempOnlineBulkDis[item._id]
    //                 //     ? tempOnlineBulkDis[item._id] - item?.discount == 0
    //                 //       ? item?.onlineBulkDiscount
    //                 //       : tempOnlineBulkDis[item._id] +
    //                 //         item?.onlineBulkDiscount -
    //                 //         item?.discount
    //                 //     : item?.onlineBulkDiscount || 0,
    //                 // }));

    //                 // setProductWiseOnlineBulkdiscountOld((pre) => ({
    //                 //   ...pre,
    //                 //   [item._id]: item?.onlineBulkDiscount || 0,
    //                 // }));
    //                 setCnDiscountFromFile((pre) => ({
    //                   ...pre,
    //                   [item._id]: item?.cnDiscount || 0,
    //                 }));

    //                 setProductWiseBulkdiscount((pre) => ({
    //                   ...pre,
    //                   [item._id]: tempBulkDis[item._id]
    //                     ? tempBulkDis[item._id] - item?.discount == 0
    //                       ? item?.bulkDiscount
    //                       : tempBulkDis[item._id] +
    //                         item?.bulkDiscount -
    //                         item?.discount
    //                     : item?.bulkDiscount || 0,
    //                 }));
    //                 setProductWiseBulkdiscountOld((pre) => ({
    //                   ...pre,
    //                   [item._id]: item?.bulkDiscount || 0,
    //                 }));
    //               } else if (
    //                 pro._id == item._id &&
    //                 item.comboId == undefined &&
    //                 pro.comboId == undefined
    //               ) {
    //                 pro.discount = item.discount;
    //                 // setProductWiseOnlineBulkdiscount((pre) => ({
    //                 //   ...pre,
    //                 //   [item._id]:
    //                 //     // item?.onlineBulkDiscount || 0,
    //                 //     tempOnlineBulkDis[item._id]
    //                 //       ? tempOnlineBulkDis[item._id] - item?.discount == 0
    //                 //         ? item?.onlineBulkDiscount
    //                 //         : tempOnlineBulkDis[item._id] +
    //                 //           item?.onlineBulkDiscount -
    //                 //           item?.discount
    //                 //       : item?.onlineBulkDiscount || 0,
    //                 // }));
    //                 // setProductWiseOnlineBulkdiscountOld((pre) => ({
    //                 //   ...pre,
    //                 //   [item._id]: item?.onlineBulkDiscount || 0,
    //                 // }));
    //                 setProductWiseBulkdiscountOld((pre) => ({
    //                   ...pre,
    //                   [item._id]: item?.bulkDiscount || 0,
    //                 }));

    //                 setCnDiscountFromFile((pre) => ({
    //                   ...pre,
    //                   [item._id]: item?.cnDiscount || 0,
    //                 }));

    //                 setProductWiseBulkdiscount((pre) => ({
    //                   ...pre,
    //                   [item._id]: tempBulkDis[item._id]
    //                     ? tempBulkDis[item._id] - item?.discount == 0
    //                       ? item?.bulkDiscount
    //                       : tempBulkDis[item._id] +
    //                         item?.bulkDiscount -
    //                         item?.discount
    //                     : item?.bulkDiscount || 0,
    //                 }));
    //               }
    //             });
    //           });
    //         }
    //         setTotalProductView(res.data.data.products);
    //         setLoadingCreateOrder(false);
    //         setSelectBillingAddressId(null);

         
    //         setLoadingOfPlaceOrder(false);
    //         setLoadingCreateOrder(false);

    //         if (addressId) {
    //           addAddressInOrder(address2, res.data.data._id);
    //         }
    //         if (cloneOrderId) {
    //           addAddressInOrder(cloneData.address, res.data.data._id);
    //         }
    //       }
    //     } else {
    //       alert(res.data.message);
    //       setLoadingOfPlaceOrder(false);
    //       setLoadingCreateOrder(false);
    //     }
    //   })
    //   .catch((err) => {
    //     alert(err);
    //     setLoadingOfPlaceOrder(false);
    //     setLoadingCreateOrder(false);
    //   });
  };

  // Function to reset cart-related states
const resetCartStates = () => {
  setTotalCartValue([]);
  setTotalProductView([]);
  setPackProductIds([]);
};

// Function to process order response
const processOrderResponse = (orderData, tempBulkDis) => {
  setTotalPayableAmount(orderData.totalPayableAmount || 0);
  setTotalCartValue(orderData);
  
  if ([6, "6", 2, "2"].includes(paymentMethod)) {
    createRazorPayId(orderData._id);
  }

  // Extract free products
  const freeProducts = orderData.products?.filter(
    (item) => item.productType === "Free"
  );
  setFreeProductsFromBackend(freeProducts);

  // Process discount updates
  let updatedBulkDiscount = {};
  let updatedCnDiscount = {};
  let oldBulkDis = {}
  let updatedPackProductIds = new Set(packProductIdsExits);

  orderData.products?.forEach((item) => {
    updatedPackProductIds.add(item.productId);
    if (item.comboId) {
      updatedPackProductIds.add(item.comboId);
    }

    updatedCnDiscount[item._id] = item?.cnDiscount || 0;
    oldBulkDis[item._id] = item?.bulkDiscount || 0;


    updatedBulkDiscount[item._id] =
      tempBulkDis[item._id] && tempBulkDis[item._id] - item.discount == 0
        ? item.bulkDiscount
        : (tempBulkDis[item._id] || 0) + item.bulkDiscount - item.discount;
  });

  // Bulk state updates (avoids multiple renders)
  setCnDiscountFromFile((prev) => ({ ...prev, ...updatedCnDiscount }));
  setProductWiseBulkdiscount((prev) => ({ ...prev, ...updatedBulkDiscount }));
  setPackProductIds([...updatedPackProductIds]);
  setTotalProductView(orderData.products);
  setProductWiseBulkdiscountOld(oldBulkDis);

  // Reset UI states
  setSelectBillingAddressId(null);
};



  const AddProductToFavourites = async (item, qnt = 1) => {
    let bulkForecast = getForecast(item.forecast);
    item.quantity = qnt;
    // console.log("dayLimit", bulkForecast);
    item.cnDiscount = productCnDiscount;
    if (Object.keys(item).length > 0) {
      if (qnt <= bulkForecast) {
        const cartUpdatedData = item;
        var temp = [];
        for (let item of [...totalProductView, cartUpdatedData]) {
          let getDealProduct = await checkDealProduct(item);
          temp = [...totalProductView, ...getDealProduct];
        }

        const currentCartPrice = checkPurchaseLimit(item, 1);
        if (currentCartPrice > purchaseLimit) {
          alert(`Max Order Limit ${purchaseLimit}`);
        } else {
          const checkDuplicateProduct = getDuplicateProduct(item);
          if (checkDuplicateProduct.length > 0) {
            alert("This Product Already Added In Cart");
          } else {
            if (item.comboId) {
              setPackProductIds([...packProductIds, item.comboId]);
            }
            setPackProductIdsExits([...packProductIdsExits, item.productId]);
            setTotalProductView(temp);
            createOrderApi('AddProductToFavourites',temp);
            currentProductCart(item);
            item.image = item?.image || item?.images;
            // }
          }
        }
      } else {
        alert(
          `Inventory of (${item?.name}) is not available in forecast,only ${
            bulkForecast || 0
          } inventory available`
        );
      }
    }
    setProductCnDiscount(10);
  };

  const TagsChange = async (event, value) => {
    let product = await parseProduct(value._id, value?.name || "");

    if(!product.status){
      alert('Out of stock product')
    }else{
      setProduct(product.data);
      setSearchClear(value.name);
    }
  };

  const productSearch = (e) => {
    setSearchClear(e.target.value);
    if (searchClear.length < 1) {
      setProduct({});
    }
    axios
      .post(process.env.REACT_APP_PATH + "/role1/sellerProductsList", {
        name: e.target.value,
        pageLimit: 100,
        page: "",
      })
      .then((response) => {
        setSearchProduct(response.data.data[0].data);
      })
      .catch((error) => {
        console.log("search product error", error);
      });
  };

  // packproduct
  const addPackProduct = (product, comboId, qty) => {
    if (
      packProductIds.includes(comboId) ||
      packProductIdsExits.includes(product.productId)
    ) {
      alert("Already added in cart");
    } else {
      AddProductToFavourites(
        { ...product, comboId: comboId, quantity: qty },
        qty
      );
    }
  };

  // Favorite products

  const handleQtyChange = async (e, index, item) => {
    let temp = [...totalProductView];
    const currentCartPrice = checkPurchaseLimit(item, e.target.value);
    if (currentCartPrice > purchaseLimit) {
      alert("Max Order Limit " + purchaseLimit);
    } else {
      temp[index].quantity = e.target.value;
      item.image = item?.image || item?.images;

      setTotalProductView(temp);
      currentProductCart(item);
      const res = await checkDealProduct(item);
      temp[index].dealId = res[0]?.dealId || undefined;
      createOrderApi('handleQtyChange',temp);
    }
  };

  const removeProduct = (item, index) => {
    setPackProductIdsExits((pre) => pre.filter((pro) => pro != item.productId));
    if (item?.comboId) {
      let temp = [...totalProductView];
      const tempObj = temp.filter((el) => {
        return el.comboId !== item.comboId;
      });
      setTotalProductView([...tempObj]);

      setPackProductIds((pre) => pre.filter((pr) => pr != item.comboId));
      createOrderApi('removeProduct with combo',[...tempObj]);
      item.quantity = 0;
      currentProductCart(item);
    } else {
      let temp = [...totalProductView];
      const tempObj = temp.filter((el) => {
        return el?.comboId ? true : el.productId !== item.productId;
      });
      setTotalProductView([...tempObj]);

      createOrderApi('removeProduct',[...tempObj]);
      item.quantity = 0;
      currentProductCart(item);
    }
  };

  const addAddressInOrder = async (address, orderId = captureOrderId) => {
    setLoadingCreateOrder(true)
    axios
      .post(process.env.REACT_APP_PATH + "/sales/addAddressToCrmOrder", {
        address: address,
        addressId: address?._id,
        orderId: orderId,
        bulkOrder: true,
      })
      .then((res) => {
        if (res.data.success) {
          setLoadingCreateOrder(false)
          setSelectAddressId(address || null);
        } else {
          setSelectAddressId(null)
          setLoadingCreateOrder(false)
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error of add address", error);
        setLoadingCreateOrder(false)
      });
  };
  const addBillingAddressInOrder = async (
    address,
    orderId = captureOrderId
  ) => {
    axios
      .post(process.env.REACT_APP_PATH + "/sales/addAddressToCrmOrder", {
        address: address,
        addressId: address?._id,
        orderId: orderId,
        bulkOrder: true,
        isBillingAddress: true,
      })
      .then((res) => {
        if (res.data.success) {
          setSelectBillingAddressId(address || null);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error of add address", error);
      });
  };

  const createRazorPayId = (orderId) => {
    axios
      .post(
        `${process.env.REACT_APP_PATH}/products/createRazorpayOrderForCrm`,
        {
          orderId: orderId,
          sellerId: sellerId,
        }
      )
      .then((res) => {
        setRazorpayOrderId(res.data.data.razorPayOrderId);
        setTotalPayableAmount(res.data.data?.totalPayableAmount || 0);
      })
      .catch((e) => console.log("Error!", e));
  };

  const placeOrder = () => {
    ga.event({
      action: "conversion",
      params: {
        send_to: "tBD20gy1OaQUzsEIRUWkXCOm",
        value: Math.round(totalPayableAmount),
        currency: "INR",
      },
    });
    if (paymentMethod == 1) {
      captureProductOrder(undefined);
    } else {
      setIsPaymentGenerate(true)
      var options = {
        key: "rzp_live_E2nnls6jq3jBMt", // Enter the Key ID generated from the Dashboard
        // key: "rzp_test_Ax7N4MA8EfQkcO", // Enter the Key ID generated from the Dashboard
        amount:
          Math.round(
            paymentMethod == 6
              ? totalCartValue?.prepaidCollectedAmount || 0
              : totalPayableAmount
          ) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Buyume",
        description: `To Seller: ${sellerName}`,
        image:
          "https://res.cloudinary.com/dyqcevdpm/image/upload/v1623494311/Buyum_Logo_mfb5xz.png",
        order_id: razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: "",
        handler: function (response) {
          captureProductOrder(response.razorpay_payment_id);
        },
        prefill: {
          name: `{sellerName}`,
          email: "test@buyume.com",
        },
        notes: {
          orderId: orderID,
          orderType: "",
        },
        theme: {
          color: "#002A56",
        },
        modal: {
          escape: false,
          ondismiss: function () {
            setIsPaymentGenerate(false)
            // alert("Payment popup closed by user!");
          },
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.description);
        setIsPaymentGenerate(false)
      });
      rzp1.open();
    }
  };

  const captureOrder = (razorPayId) => {
    setLoadingOfPlaceOrder(true);
    axios
      .post(process.env.REACT_APP_PATH + "/sales/captureBulkOrder", {
        orderId: captureOrderId,
        paymentMethod: paymentMethod,
        razorPayOrderId: razorpayOrderId,
        agentId: selectedAgents,
        razorPayId: razorPayId,
        // facility:facility
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data.message);
          setLoadingOfPlaceOrder(false);
          window.location.reload();
        } else {
          setIsPaymentGenerate(false)
          alert(res.data.message);
          setLoadingOfPlaceOrder(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsPaymentGenerate(false)
        alert(error?.response?.data?.message || "something went wrong");
        setLoadingOfPlaceOrder(false);
      });
  };

  const captureProductOrder = (razorPayId) => {
    let cnDiscountArrays = [];
    let acctualDiscount = [];
    if (Object.keys(cnDiscountFromFile).length > 0) {
      for (let cn in cnDiscountFromFile) {
        cnDiscountArrays.push({
          productId: cn,
          cnDiscount: cnDiscountFromFile[cn],
        });
      }
    }
    if (Object.keys(actualDiscountFromFile).length > 0) {
      for (let accDis in actualDiscountFromFile) {
        acctualDiscount.push({
          productId: accDis,
          actualDiscount: actualDiscountFromFile[accDis],
        });
      }
    }
    setLoadingOfPlaceOrder(true);
    axios
      .post(process.env.REACT_APP_PATH + "/sales/captureBulkOrder", {
        orderId: captureOrderId,
        paymentMethod: Number(paymentMethod),
        agentId: selectedAgents,
        facility: Number(facility),
        cnDiscountArray: cnDiscountArrays,
        actualDiscountArray: acctualDiscount,
        organization: organization,
        orderType: orderType,
        shippingSuggestion: selectDeliveryPartner,
        razorPayId: razorPayId,
        razorpayOrderId: razorpayOrderId,
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data.message);
          setLoadingOfPlaceOrder(false);
          window.location.reload();
        } else {
          alert(res.data.message);
          setIsPaymentGenerate(false)
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert("Something went wrong");
        setIsPaymentGenerate(false)
        setLoadingOfPlaceOrder(false);
      });
  };

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
      setFileUploadDis(false);
    }
  };

  const addMultipleProductOnCart = (
    pro,
    temp,
    sellerIdFromOrder = sellerId
  ) => {
    axios
      .post(
        process.env.REACT_APP_PATH + "/products/multipleProductInCurrCart",
        // 'http://192.168.2.153:4000'+"/products/multipleProductInCurrCart",
        {
          products: pro,
          sellerId: sellerIdFromOrder,
        }
      )
      .then((res) => {
        if (res.data.success) {
          getSellerCurrentCart(temp);
        } else {
          setLoadingCreateOrder(false);
        }
      })
      .catch((error) => {
        console.log("error of multiple cart value", error.response);
      });
  };

  const handleFile = () => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      setLoadingCreateOrder(true);
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      let tempBulkDis = {};
      let tempOnlineBulkDis = {};
      let temp = [];
      for (const pro of data) {
        pro._id = pro.productId;
        temp.push(pro);
        setProductWiseBulkdiscountFromFile((pre) => ({
          ...pre,
          [pro.productId]: pro.bulkdiscount,
        }));
        // setProductWiseOnlineBulkdiscountFromFile((pre) => ({
        //   ...pre,
        //   [pro.productId]: pro.onlinebulkdiscount,
        // }));
        setCnDiscountFromFile((pre) => ({
          ...pre,
          [pro.productId]: pro.cnDiscount,
        }));

        setActualDiscountFromFile((pre) => ({
          ...pre,
          [pro.productId]: pro.actualDiscount,
        }));
        // tempDis.push({[pro.productId]: pro.discount})
        tempBulkDis[pro.productId] = pro.bulkdiscount;
      }
      let findAcctualDiscount = data.some(
        (item) => item.actualDiscount == undefined
      );
      if (findAcctualDiscount) {
        alert("Actual discount is mandatory in every product");
        setLoadingCreateOrder(false);
        return;
      }

      addMultipleProductOnCart(temp, tempBulkDis);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };
  const handleFile2 = () => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      // setLoadingCreateOrder(true);
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      var data = XLSX.utils.sheet_to_json(ws);

      let tempBulkDis = {};
      let tempOnlineBulkDis = {};
      let overallCogs = 0;
      let overallMargin = 0;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        let totalMrp = data[i].mrp * data[i].quantity;
        let totalPayable = totalMrp * (1 - data[i].discount / 100);

        let cogsValue = totalMrp * data[i].cogs;
        let marginValue = totalPayable - cogsValue;

        data[i].marginValue = marginValue;
        data[i].cogsValue = cogsValue;
        data[i].totalPayable = totalPayable;
        data[i].totalMrp = totalMrp;

        // pro._id = pro.productId;
        // temp.push(pro);
        // setProductWiseBulkdiscountFromFile((pre) => ({
        //   ...pre,
        //   [pro.productId]: pro.bulkdiscount,
        // }));
        // // setProductWiseOnlineBulkdiscountFromFile((pre) => ({
        // //   ...pre,
        // //   [pro.productId]: pro.onlinebulkdiscount,
        // // }));
        // setCnDiscountFromFile((pre) => ({
        //   ...pre,
        //   [pro.productId]: pro.cnDiscount,
        // }));
        // // tempDis.push({[pro.productId]: pro.discount})
        // tempBulkDis[pro.productId] = pro.bulkdiscount;
      }
      for (let i = 0; i < data.length; i++) {
        overallCogs += data[i].cogsValue;
        overallMargin += data[i].marginValue;
      }
      for (let i = 0; i < data.length; i++) {
        let reviesedMargin = data[i].cogsValue * (overallMargin / overallCogs);
        let reviesedPayable = data[i].cogsValue + reviesedMargin;
        let newDiscount = (1 - reviesedPayable / data[i].totalMrp) * 100;
      }

      // addMultipleProductOnCart(temp, tempBulkDis);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const getSellerCurrentCart = async (tempBulkDis = [], tempOnlineBulkDis = []) => {
    if (!sellerId) return;
    setLoadingCreateOrder(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_PATH}/role5/getSellerCurrentCart`, {
        sellerId,
      });
  
      if (!res.data.success) {
        setLoadingCreateOrder(false);
        alert(res.data.message);
        return;
      }
  
      const products = res.data.data.products || [];
      if (products.length === 0) {
        setLoadingCreateOrder(false);
        return;
      }
  
      let totalForecastNotAvailable = [];
      let outOfStockProduct = []
  
      // Run all `parseProduct` API calls in parallel
      const parsedProducts = await Promise.all(products.map((proTemp) => parseProduct(proTemp._id)));
  
      // Filter out unavailable products
      const validProducts = parsedProducts
        .map((parse, index) => {
          if (!parse.status){
            outOfStockProduct.push(parse.productId)
            return null
          }
  
          let proTemp = products[index];
          let bulkForecast = getForecast(parse.data.forecast);
  
          if (bulkForecast < proTemp.quantity) {
            totalForecastNotAvailable.push(parse.data.name);
            return null; // Skip products that exceed forecast availability
          }
  
          return { ...parse.data, ...proTemp, quantity: proTemp.quantity };
        })
        .filter(Boolean); // Remove null values
  
      if (totalForecastNotAvailable.length > 0) {
        alert(`Inventory of [${totalForecastNotAvailable.join(", ")}] is not available in forecast`);
      }
      if (outOfStockProduct.length > 0) {
        alert(`Out of stock products [${outOfStockProduct.join(', ')}]`);
      }
  
      setTotalProductView(validProducts);
  
      if (validProducts.length > 0) {
        createOrderApi('getSellerCurrentCart validProducts',validProducts, tempBulkDis);
      }
    } catch (err) {
      console.error("Error fetching cart:", err);
      setLoadingCreateOrder(false);
    }
  };

  const clearCart = () => {
    axios
      .post(process.env.REACT_APP_PATH + "/products/clearCurrentCart", {
        sellerId: sellerId,
      })
      .then((res) => {
        if (res.data.success) {
          alert("Seller cart has been remove");
          getSellerCurrentCart();
        } else {
          alert("Someting went wring");
        }
      })
      .catch((error) => {
        alert("Someting went wring");
      });
  };
  const verifiPincode = (address) => {
    if (address.address1.length >= 50 || address.address2.length >= 50) {
      alert(
        `${
          address.address1.length >= 50 ? "address1" : "address2"
        } is greater than 50 characters`
      );
      setAddAddress({
        name: address?.name,
        phoneNumber: address?.phoneNumber,
        landmark: address?.landmark,
        address1: address?.address1,
        address2: address?.address2,
        alternatePhoneNumber: "",
        postalCode: address?.postalCode,
        city: address?.city,
        state: address?.state,
      });
      setAddressId(address._id);
      setOpenAddressModal(true);
      return false;
    }

    setLoadingCreateOrder(true)

    axios
      .get(
        `${process.env.REACT_APP_PATH}/leads/getCityAndState?pincode=${address?.postalCode}`
      )
      .then((res) => {
        if (res.data.success) {
          setShippingData(res.data.data);
          createOrderApi(
            'verify pincode',
            totalProductView,
            productWiseBulkdiscountFromFile,
            address._id,
            address
          );
          // addAddressInOrder(address,orderID)
        } else {
          alert(res.data.message || "Someting went wring");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const verifiPincodeForBilling = (address) => {
    if (address.address1.length >= 50 || address.address2.length >= 50) {
      alert(
        `${
          address.address1.length >= 50 ? "address1" : "address2"
        } is greater than 50 characters`
      );
      setBillingAddAddress({
        name: address?.name,
        phoneNumber: address?.phoneNumber,
        landmark: address?.landmark,
        address1: address?.address1,
        address2: address?.address2,
        alternatePhoneNumber: "",
        postalCode: address?.postalCode,
        city: address?.city,
        state: address?.state,
      });
      setAddressIdForBill(address._id);
      setOpenAddressModal(true);
      return false;
    }

    axios
      .get(
        `${process.env.REACT_APP_PATH}/leads/getCityAndState?pincode=${address?.postalCode}`
      )
      .then((res) => {
        if (res.data.success) {
          setShippingData(res.data.data);
          addBillingAddressInOrder(address);
        } else {
          alert(res.data.message || "Someting went wring");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const checkCodLimit = (sellerId, totalPayableAmount) => {
    axios
      .post(
        process.env.REACT_APP_PATH + "/sales/checkCodLimitForCrmBulkOrderV2",
        {
          sellerId,
          totalPayableAmount,
        }
      )
      .then((res) => {
        if (res.data.success) {
          setCodError(res.data?.data.other);
        } else {
          setCodError("");
        }
      })
      .catch((error) => {
        console.log("error of check cod limit", error);
      });
  };
  useEffect(() => {
    getSellerCurrentCart();
  }, [sellerId]);

  useEffect(() => {
    if (sellerId && totalProductView.length > 0) {
      createOrderApi('shippingCostType,paymentMethod,orderDate',
        totalProductView,
        productWiseBulkdiscountFromFile,
        selectAddressId?._id,
        selectAddressId
      );
    }
  }, [shippingCostType,paymentMethod,orderDate]);

  useEffect(() => {
    if (isPrepaid) {
      setPaymentMethod("6");
    } else {
      setPaymentMethod("1");
    }
  }, [isPrepaid]);

  const handelChangeDiscount = (value, item, type) => {
    let bulkDis = productWiseBulkdiscountOld[item?.productId];
    let diffBulk = 99.9 - item.discount - (item?.bulkDiscount || 0) + bulkDis;
    if (type == "BULK") {
      if (item.productId == "66448d631d7fee1d5f6164a2") {
        if (value <= diffBulk) {
          setProductWiseBulkdiscount((pre) => ({
            ...pre,
            [item.productId]: value,
          }));
        }
      } else if (value <= 40) {
        setProductWiseBulkdiscount((pre) => ({
          ...pre,
          [item.productId]: value,
        }));
      }
    } else {
      // console.log(item.discount,value)
      if (item.discount - value > 0) {
        setCnDiscountFromFile((pre) => ({
          ...pre,
          [item.productId]: +value,
        }));
      }
    }
  };

  const getForecast = (forecast) => {
    // return 1000000

    if (new Date(orderDate).getTime() <= "1722450599000") {
      return 1000000;
    }

    let bulkforecast = forecast.filter((item) => item.source == "BULK");

    if (bulkforecast.length > 0) {
      return bulkforecast[0].dayWise;
    }
  };

  useEffect(async() => {
    try {
      let [agentRes,forcastRes] =await Promise.all([axios.get(`${process.env.REACT_APP_PATH}/admin/getBulkAgents`),axios.get(process.env.REACT_APP_PATH + "/products/getBulkForecast")])
      if (agentRes.data.success) {
        if (agentRes?.data?.data?.length > 0) {
          setAgentsData(agentRes.data.data);
        }
      }
      if (forcastRes.data.success) {
        setProductWithForecast(forcastRes.data.data);
      }
    } catch (error) {
        console.log('error',error)
    }
  }, []);

  return (
    <div>
      {loadingCreateOrder && (
        <div
          style={{
            width: "100%",
            height: "400%",
            justifyContent: "center",
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 10000000,
          }}
        >
          <h1 style={{ color: "#000", marginTop: 400 }}>Loading....</h1>
        </div>
      )}
      <Typography style={{ paddingLeft: 10, margin: "10px 0px" }}>
        Step - 1
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12} md={12} sm={12} alignItems={"center"}>
          <SubCard>
            <Grid container alignItems={"center"} gap={1}>
              <Grid
                container
                alignItems={"center"}
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                spacing={2}
              >
                <Grid item xs={12} lg={2}>
                  <TextField
                    variant="outlined"
                    onChange={handelSellerSearch}
                    value={sellerPhoneNumber}
                    fullWidth
                    label="Seller Phone Number"
                  />
                  <Typography>
                    {mobileSearchLoader ? "Loading..." : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Autocomplete
                    id="tags-outlined"
                    options={agentsData}
                    fullWidth
                    getOptionLabel={(option) =>
                      `${option.name} (${option.userName})`
                    }
                    onChange={(event, newValue) => setSelectedAgents(newValue ? newValue._id : null)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Agents"
                        placeholder="Select Agents"
                      />
                    )}
                    value={agentsData.find((item)=>item._id==selectedAgents)||null}
                  />
                  {/* <InputLabel
                    id="demo-simple-select-helper-label"
                    style={{ backgroundColor: "#fff" }}
                  >
                    Select Agent
                  </InputLabel>
                  <Select
                    value={selectedAgents}
                    labelId="demo-simple-select-helper-label"
                    onChange={(e) => setSelectedAgents(e.target.value)}
                    fullWidth
                    label="Select Agents"
                  >
                    {agentsData.map((item) => (
                      <MenuItem value={item._id} key={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                </Grid>
                <Grid item xs={12} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      indicatorColor="primary"
                      renderInput={(props) => (
                        <TextField fullWidth {...props} helperText="" />
                      )}
                      label="Date & Time"
                      value={orderDate}
                      onChange={(newValue) => {
                        setOrderDate(newValue);
                      }}
                      sx={{
                        "& .MuiTabs-flexContainer .MuiTabs-indicator": {
                          bgcolor: "primary.main",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button
                    onClick={() => {
                      // cloneOrder('66a79108c6e61fe1e657aeeb')
                      setCloneOrderModal(true);
                    }}
                    variant="contained"
                  >
                    Clone Order
                  </Button>
                </Grid>
                {sellerId && (
                  <Grid item xs={12} lg={2}>
                    <Button variant="contained" onClick={clearCart}>
                      Clear Cart
                    </Button>
                  </Grid>
                )}
                <Grid xs={12} md={4} lg={2}>
                  {productWithForecast.length > 0 && (
                    <CSVLink
                      data={productWithForecast}
                      style={{
                        textDecoration: "none",
                        border: "1px solid #999",
                        padding: "10px 20px",
                        backgroundColor: "#95c7f1",
                        marginTop: 15,
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 120,
                      }}
                      headers={[
                        { key: "_id", label: "productId" },
                        { key: "name", label: "Product Name" },
                        { key: "EANCode", label: "EANCode" },
                        { key: "usedQuantity", label: "usedQuantity" },
                        { key: "forecast[0].dayWise", label: "Forecast" },
                        { key: "0", label: "quantity" },
                        { key: "0", label: "bulkdiscount" },
                        { key: "0", label: "cnDiscount" },
                      ]}
                      title="Product Forecast"
                      filename="Product Forecast"
                    >
                      Download
                    </CSVLink>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
      {/* Search products */}
      <Typography style={{ paddingLeft: 10, margin: "10px 0px" }}>
        Step - 2
      </Typography>
      <Grid item xs={12} lg={12}>
        <SubCard title="Search Products">
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={5}>
                <Autocomplete
                  id=""
                  size="small"
                  key={product}
                  disabled={sellerId == null}
                  inputValue={searchClear}
                  options={searchProduct}
                  getOptionLabel={(option) => option.name}
                  onChange={TagsChange}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      variant="standard"
                      label="Search Product"
                      onChange={productSearch}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={productQuantity}
                  onChange={(Event) => setProductQuantity(Event.target.value)}
                  variant="outlined"
                  label="Quantity"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={productCnDiscount}
                  onChange={(Event) => setProductCnDiscount(Event.target.value)}
                  variant="outlined"
                  label="CN Discount"
                />
              </Grid>
              <Grid item md={3}>
                {searchClear.length > 0 && (
                  <a href="#productInCart" style={{ textDecoration: "none" }}>
                    <Button
                      variant="contained"
                      onClick={(e) =>
                        AddProductToFavourites(product, productQuantity)
                      }
                      disabled={disableAddToCartButton}
                    >
                      Add To Cart
                    </Button>
                  </a>
                )}

                {product?.combos?.length > 0 && (
                  <Button
                    variant="contained"
                    onClick={(e) => setShowPackModal(true)}
                    style={{ marginLeft: 10 }}
                  >
                    Show All Pack
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
      {/* Product in cart */}
      <Typography style={{ paddingLeft: 10, margin: "10px 0px" }}>
        Step - 3
      </Typography>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          style={{
            maxHeight: 1000,
            overflow: "scroll",
            position: "sticky",
            top: 0,
          }}
        >
          <SubCard
            title={
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <label htmlFor="file" style={{ color: "black" }}>
                    Upload an excel File
                  </label>
                  <br />
                  <input
                    type="file"
                    className="form-control"
                    id="file"
                    accept={SheetJSFT}
                    onChange={handleChange}
                    disabled={sellerId == null}
                  />
                </div>
                <LoadingButton
                  onClick={handleFile}
                  variant="contained"
                  loadingIndicator="Uploading..."
                  style={{ padding: "5px 10px", width: 120, marginTop: 5 }}
                  disabled={fileUploadDis}
                >
                  Upload
                </LoadingButton>
              </div>
            }
            style={{ fontSize: 16 }}
            id="productInCart"
            secondary={
              <div>
                {/* <Button
                    disabled={sellerId == null}
                    variant="contained"
                    onClick={() =>
                      createOrderApi(totalProductView)
                    }
                  >
                    Refresh
                  </Button> */}
                {/* <Button
                  style={{ marginLeft: 10 }}
                  disabled={sellerId == null}
                  variant="contained"
                  onClick={() => productUpdate()}
                >
                  Update Online Bulk Discount
                </Button> */}
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  onClick={() => productBulkDiscountUpdate()}
                  disabled={sellerId == null}
                >
                  Update Bulk Discount
                </Button>
              </div>
            }
          >
            {totalProductView.length > 0 ? (
              <>
                <TableContainer
                  style={{ position: "sticky", top: 0, maxHeight: 600 }}
                >
                  <Table style={{ position: "relative" }}>
                    <TableHead
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#fff",
                        zIndex: 10,
                      }}
                    >
                      <TableRow>
                        <TableCell sx={{ pl: 3, width: 150 }}>Name</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Forecast Inventory</TableCell>
                        <TableCell align="center">MRP</TableCell>
                        <TableCell align="center">
                          Base Discount + Membership
                        </TableCell>
                        {/* <TableCell>Online Bulk Discount</TableCell> */}
                        <TableCell>Bulk Discount</TableCell>
                        <TableCell align="center">CN Discount</TableCell>
                        <TableCell align="center">Discount</TableCell>
                        <TableCell align="center">Amount</TableCell>
                        <TableCell align="center">Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[...totalProductView, ...freeProductsFromBackend].map(
                        (item, ind) => (
                          <>
                            {item.productType != "Free" ? (
                              <TableRow key={ind}>
                                <TableCell>
                                  {item?.productType && (
                                    <span
                                      style={{
                                        color: "white",
                                        background: "rgb(255 167 65)",
                                        position: "relative",
                                        top: "-5px",
                                        borderRadius: "20px",
                                        padding: "5px",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item?.productType}
                                    </span>
                                  )}
                                  <Typography>
                                    {item?.comboId
                                      ? `${item?.name} - Pack Of ${item?.quantity}`
                                      : item?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  {item?.productType == "Free" ? (
                                    <Typography variant="h5">
                                      {item.quantity}
                                    </Typography>
                                  ) : (
                                    <FormControl fullWidth>
                                      <TextField
                                        value={item.quantity}
                                        defaultValue={item.quantity}
                                        label="Quantity"
                                        disabled={
                                          item?.productType == "Free" ||
                                          item?.comboId
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleQtyChange(e, ind, item)
                                        }
                                      />
                                      {/* <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={item.quantity}
                                        defaultValue={item.quantity}
                                        d
                                        label="Quantity"
                                        onChange={(e) =>
                                          handleQtyChange(e, ind, item)
                                        }
                                        disabled={
                                          item?.productType == "Free" ||
                                          item?.comboId
                                            ? true
                                            : false
                                        }
                                      >
                                        {Array(item.qtyLimit)
                                          .fill(0)
                                          .map((num, i) => (
                                            <MenuItem value={i + 1}>
                                              {i + 1}
                                            </MenuItem>
                                          ))}
                                      </Select> */}
                                    </FormControl>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {getForecast(item.forecast)}
                                </TableCell>
                                <TableCell align="center">
                                  {item.price !== undefined
                                    ? Math.round(item.mrp)
                                    : "0"}
                                </TableCell>
                                <TableCell align="center">
                                  {item.baseDiscount +
                                    (item?.clubDiscount || 0)}
                                </TableCell>
                                {/* <TableCell>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {accessNumbers.includes(
                                      agentPhoneNumber
                                    ) && (
                                      <>
                                        <FormControl>
                                          <TextField
                                            labelId="demo-simple-select-label"
                                            // type="number"
                                            placeholder="Online Bulk Discount"
                                            label="Online Bulk Discount"
                                            defaultValue="Online Bulk Discount"
                                            id="outlined-required"
                                            InputProps={{
                                              inputProps: {
                                                min: -100,
                                                max: 40,
                                              },
                                            }}
                                            value={
                                              productWiseOnlineBulkdiscount[
                                                item.productId
                                              ] || 0
                                            }
                                            onChange={(e) => {
                                              handelChangeDiscount(
                                                e.target.value,
                                                item,
                                                "ONLINEBULK"
                                              );
                                            }}
                                            style={{
                                              width: "80px",
                                            }}
                                          />
                                        </FormControl>
                                      </>
                                    )}
                                  </Grid>
                                </TableCell> */}
                                <TableCell>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {accessNumbers.includes(
                                      agentPhoneNumber
                                    ) && (
                                      <>
                                        <FormControl>
                                          <TextField
                                            labelId="demo-simple-select-label"
                                            // type="number"
                                            placeholder="Bulk Discount"
                                            label="Bulk Discount"
                                            defaultValue="Bulk Discount"
                                            id="outlined-required"
                                            InputProps={{
                                              inputProps: {
                                                min: -100,
                                                max: 40,
                                              },
                                            }}
                                            value={
                                              productWiseBulkdiscount[
                                                item.productId
                                              ] || 0
                                            }
                                            onChange={(e) => {
                                              handelChangeDiscount(
                                                e.target.value,
                                                item,
                                                "BULK"
                                              );
                                            }}
                                            style={{
                                              width: "80px",
                                            }}
                                          />
                                        </FormControl>
                                      </>
                                    )}
                                  </Grid>
                                </TableCell>
                                {/*                                                     <TableCell align="center" style={{ color: 'red' }}> ₹ {Math.round((item?.mrp - (item.mrp - (calculateDiscount(item, membership) / 100) * item.mrp) || 0) * item.quantity)} ({calculateDiscount(item, membership)}%)</TableCell>
                                 */}{" "}
                                <TableCell>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {accessNumbers.includes(
                                      agentPhoneNumber
                                    ) && (
                                      <>
                                        <FormControl>
                                          <TextField
                                            labelId="demo-simple-select-label"
                                            // type="number"
                                            placeholder="CN Discount"
                                            label="CN Discount"
                                            defaultValue="CN Discount"
                                            id="outlined-required"
                                            InputProps={{
                                              inputProps: {
                                                min: -100,
                                                max: 40,
                                              },
                                            }}
                                            value={
                                              cnDiscountFromFile[
                                                item.productId
                                              ] || 0
                                            }
                                            onChange={(e) => {
                                              handelChangeDiscount(
                                                e.target.value,
                                                item,
                                                "CN"
                                              );
                                            }}
                                            style={{
                                              width: "80px",
                                            }}
                                          />
                                        </FormControl>
                                      </>
                                    )}
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  ₹{" "}
                                  {Math.round(
                                    (item?.mrp -
                                      (item.mrp -
                                        (item.discount / 100) * item.mrp) ||
                                      0) * item.quantity
                                  )}{" "}
                                  ({item.discount}%)
                                </TableCell>
                                {/*                                                     <TableCell align="center">{item.price !== undefined ? Math.round((item.mrp - (calculateDiscount(item, membership) / 100) * item.mrp) * item.quantity) : "0"} ({item.price !== undefined ? Math.round((item.mrp - ((calculateDiscount(item, membership) / 100) * item.mrp))) : "0"}*{item.quantity})</TableCell>
                                 */}{" "}
                                <TableCell align="center">
                                  {Math.round(
                                    item.mrp -
                                      (item.discount / 100) * item.mrp || 0
                                  )}
                                  *{item.quantity} ={" "}
                                  {Math.round(
                                    item.mrp -
                                      (item.discount / 100) * item.mrp || 0
                                  ) * item.quantity}
                                </TableCell>
                                <TableCell align="center">
                                  {item.productType != "Free" && (
                                    <DeleteOutlinedIcon
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => removeProduct(item, ind)}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow key={ind}>
                                <TableCell>
                                  <Typography>{item?.name}</Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ color: "red" }}
                                >
                                  Out of Stock
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ color: "red" }}
                                >
                                  {}
                                </TableCell>

                                <TableCell align="center">
                                  <DeleteOutlinedIcon
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeProduct(item, ind)}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography variant="h4" mt="10px">
                Cart is empty
              </Typography>
            )}
          </SubCard>
        </Grid>
      </Grid>
      <Typography style={{ margin: "10px 0px", paddingLeft: 10 }}>
        Step 4
      </Typography>
      <Grid container xs={12} md={12} spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <SubCard
            title="Address"
            sx={{ position: "relative" }}
            secondary={
              <Button
                variant="contained"
                onClick={() => {
                  setOpenAddressModal(true);
                  setAddressId(null);
                  setAddressIdForBill(null);
                  setIsBillingModal(false);
                  setAddAddress({
                    name: "",
                    phoneNumber: "",
                    landmark: "",
                    address1: "",
                    address2: "",
                    alternatePhoneNumber: "",
                    postalCode: "",
                    city: "",
                    state: "",
                  });
                }}
              >
                Add Address
              </Button>
            }
          >
            <div style={{ maxHeight: 220, overflow: "scroll" }}>
              {sellerAddress &&
                sellerAddress
                  .filter((add) => add.isActive)
                  .map((address, ind) => (
                    <div
                      style={{
                        margin: "10px auto",
                        border: `2px solid ${
                          address?._id == selectAddressId?._id
                            ? "green"
                            : "#999"
                        }`,
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      key={ind}
                    >
                      <Typography>Name : {address?.name}</Typography>
                      <Typography>
                        phone Number : {address?.phoneNumber}
                      </Typography>
                      <Typography>Address 1 : {address?.address1}</Typography>
                      <Typography>Address 2 : {address?.address2}</Typography>
                      <Typography>Pincode : {address?.postalCode}</Typography>
                      <Button
                        disabled={orderID == null ? true : false}
                        color={
                          address?._id == selectAddressId?._id
                            ? "primary"
                            : "inherit"
                        }
                        variant="contained"
                        onClick={() => verifiPincode(address)}
                      >
                        Ship To This Address
                      </Button>
                      <Button
                        disabled={orderID == null ? true : false}
                        color={"secondary"}
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        onClick={() => {
                          setAddAddress({
                            name: address?.name,
                            phoneNumber: address?.phoneNumber,
                            landmark: address?.landmark,
                            address1: address?.address1,
                            address2: address?.address2,
                            alternatePhoneNumber: "",
                            postalCode: address?.postalCode,
                            city: address?.city,
                            state: address?.state,
                          });
                          setAddressId(address._id);
                          setOpenAddressModal(true);
                          setIsBillingModal(false);
                        }}
                      >
                        Edit Addresss
                      </Button>
                    </div>
                  ))}
            </div>
            <Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBillingAddress}
                      onChange={(e) => {
                        setShowBillingAddress(e.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Show Billing Address"
                />
              </FormGroup>
            </Grid>
          </SubCard>
        </Grid>
        {showBillingAddress && (
          <Grid item xs={12} md={6} lg={6}>
            <SubCard
              title="Billing Address"
              sx={{ position: "relative", height: "100%" }}
              secondary={
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenAddressModal(true);
                    setAddressId(null);
                    setAddressIdForBill(null);
                    setIsBillingModal(true);
                    setBillingAddAddress({
                      name: "",
                      phoneNumber: "",
                      landmark: "",
                      address1: "",
                      address2: "",
                      alternatePhoneNumber: "",
                      postalCode: "",
                      city: "",
                      state: "",
                    });
                  }}
                >
                  Add Billing Address
                </Button>
              }
            >
              <div style={{ maxHeight: 220, overflow: "scroll" }}>
                {sellerBillingAddress &&
                  sellerBillingAddress
                    .filter((add) => add.isActive)
                    .map((address, ind) => (
                      <div
                        style={{
                          margin: "10px auto",
                          border: `2px solid ${
                            address?._id == selectBillingAddressId?._id
                              ? "green"
                              : "#999"
                          }`,
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        key={ind}
                      >
                        <Typography>Name : {address?.name}</Typography>
                        <Typography>
                          phone Number : {address?.phoneNumber}
                        </Typography>
                        <Typography>Address 1 : {address?.address1}</Typography>
                        <Typography>Address 2 : {address?.address2}</Typography>
                        <Typography>Pincode : {address?.postalCode}</Typography>
                        <Button
                          disabled={orderID == null ? true : false}
                          color={
                            address?._id == selectBillingAddressId?._id
                              ? "primary"
                              : "inherit"
                          }
                          variant="contained"
                          onClick={() => verifiPincodeForBilling(address)}
                        >
                          Bill On This Address
                        </Button>
                        <Button
                          disabled={orderID == null ? true : false}
                          color={"secondary"}
                          style={{ marginLeft: 10 }}
                          variant="contained"
                          onClick={() => {
                            setBillingAddAddress({
                              name: address?.name,
                              phoneNumber: address?.phoneNumber,
                              landmark: address?.landmark,
                              address1: address?.address1,
                              address2: address?.address2,
                              alternatePhoneNumber: "",
                              postalCode: address?.postalCode,
                              city: address?.city,
                              state: address?.state,
                            });
                            setAddressIdForBill(address._id);
                            setOpenAddressModal(true);
                            setIsBillingModal(true);
                          }}
                        >
                          Edit Addresss
                        </Button>
                      </div>
                    ))}
              </div>
            </SubCard>
          </Grid>
        )}

        <Grid item xs={12} md={3} lg={3}>
          <SubCard
            title="Seller Details"
            sx={{ position: "relative", height: "100%" }}
          >
            <Grid style={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
              >
                <Grid style={{ margin: "10px 0px" }}>
                  <TextField
                    value={gstNumber}
                    onChange={(event) =>
                      setGstNumber(event.target.value.trim())
                    }
                    placeholder="Enter GST Number"
                    label="Enter GST Number"
                    id="outlined-required"
                    defaultValue="Enter GST Number"
                    fullWidth
                  />
                </Grid>
                <Grid style={{ margin: "10px 0px" }}>
                  <TextField
                    value={traderName}
                    onChange={(event) => setTraderName(event.target.value)}
                    placeholder="Enter Trader Name"
                    label="Enter Trader Name"
                    defaultValue="Enter Trader Name"
                    fullWidth
                    disabled={
                      isGstNumberExist && agentPhoneNumber != "9719836187"
                    }
                  />
                </Grid>
                <Grid style={{ margin: "10px 0px" }}>
                  <TextField
                    value={sellerName}
                    onChange={(event) => setSellerName(event.target.value)}
                    placeholder="Enter Name"
                    label="Enter Name"
                    defaultValue="Enter Name"
                    fullWidth
                    disabled={
                      isGstNumberExist && agentPhoneNumber != "9719836187"
                    }
                  />
                </Grid>
              </Box>
              <Grid>
                <Button
                  variant="contained"
                  onClick={updateSeller}
                  disabled={sellerId == null}
                >
                  Update Seller
                </Button>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={3}
          md={3}
          justifyContent={"space-between"}
        >
          <SubCard
            title="Select Delivery Partner"
            sx={{ position: "relative", height: 150, width: "100%" }}
          >
            <Select
              value={selectDeliveryPartner}
              onChange={(e) => setSelectDeliveryPartner(e.target.value)}
              fullWidth
            >
              <MenuItem value={"ROCKETBOX"}>ROCKET BOX</MenuItem>
              <MenuItem value={"SHIPROCKET"}>SHIP ROCKET</MenuItem>
              <MenuItem value={"PORTER"}>PORTER</MenuItem>
            </Select>
          </SubCard>
          <SubCard
            title="Select Shipping Type"
            sx={{ position: "relative", width: "100%", marginTop: 1 }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={shippingCostType}
                onChange={(e) => setShippingCostType(+e.target.value)}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={`Shipping Charge Percentage (${
                    shippingData?.codChargePercentage || 0
                  }%)`}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={`Shipping Charge Truck Value (${
                    shippingData?.codChargeTruckValue || 0
                  })`}
                />
              </RadioGroup>
            </FormControl>
          </SubCard>
        </Grid>
        <Grid container item xs={12} md={6} lg={6} spacing={2}>
          <Grid item xs={12} lg={6} height={"100%"}>
            <SubCard
              title="Payment Method"
              sx={{ position: "relative", height: "100%" }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  {!isPrepaid && (
                    <FormControlLabel
                      value={"1"}
                      control={<Radio />}
                      label="Cash On Delievery (COD)"
                      sx={{ marginTop: 2 }}
                    />
                  )}

                  <FormControlLabel
                    value={"2"}
                    control={<Radio />}
                    label="Online Payment"
                    sx={{ marginTop: 2 }}
                  />
                  <FormControlLabel
                    value={"6"}
                    control={<Radio />}
                    label="Partial Pay"
                    sx={{ marginTop: 2 }}
                  />
                </RadioGroup>
              </FormControl>
              {codError && codError.length > 0 && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <ErrorIcon color={"error"} style={{ marginRight: 10 }} />
                  <Typography variant="body1" color={"red"}>
                    {codError}
                  </Typography>
                </Grid>
              )}
            </SubCard>
          </Grid>

          {/* {paymentMethod == 2 && ( */}
          <Grid
            item
            xs={12}
            lg={6}
            container
            justifyContent={"space-between"}
            flexDirection={"column"}
            spacing={1}
            gap={1}
          >
            <Grid item flex={1}>
              <SubCard
                title="Origination"
                sx={{ position: "relative", height: "100%" }}
              >
                <Select
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={1}>Emergent</MenuItem>
                  <MenuItem value={2}>Florida</MenuItem>
                  <MenuItem value={3}>Crescent</MenuItem>
                </Select>
              </SubCard>
            </Grid>
            <Grid item flex={1}>
              <SubCard
                title="Order Type"
                sx={{ position: "relative", height: "100%" }}
              >
                <Select
                  value={orderType}
                  onChange={(e) => setOrderType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={1}>Bulk Order</MenuItem>
                  <MenuItem value={2}>GT Order</MenuItem>
                  <MenuItem value={3}>Blinkit Order</MenuItem>
                </Select>
              </SubCard>
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={"flex-end"}
        justifyContent={"flex-end"}
        mt={1}
      >
        <Grid item xs={12} md={6} lg={6}>
          <SubCard title="Total Amount Breakup" sx={{ position: "relative" }}>
            <Grid item lg={12} md={6}>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      lg={10}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Typography align="right" variant="subtitle1">
                        {" "}
                        Total Mrp :{" "}
                      </Typography>
                      <Typography
                        align="right"
                        variant="body2"
                        style={{ paddingLeft: 10, paddingTop: 2 }}
                      >
                        &#8377; {totalCartValue?.orderValue}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      lg={10}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Typography align="right" variant="subtitle1">
                        {" "}
                        Total Quantity :{" "}
                      </Typography>
                      <Typography
                        align="right"
                        variant="body2"
                        style={{ paddingLeft: 10, paddingTop: 2 }}
                      >
                        {totalCartValue.products?.reduce(
                          (acc, cur) => acc + +cur.quantity,
                          0
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Typography align="right" variant="subtitle1">
                        {" "}
                        Delivery Charge :
                      </Typography>
                      <Typography
                        align="right"
                        variant="body2"
                        style={{ paddingLeft: 10, paddingTop: 2 }}
                      >
                        &#8377;{" "}
                        {totalCartValue.deliveryCharge ||
                          totalCartValue.codCharge ||
                          0}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Typography align="right" variant="subtitle1">
                        {" "}
                        Shipping Charge :
                      </Typography>
                      <Typography
                        align="right"
                        variant="body2"
                        style={{ paddingLeft: 10, paddingTop: 2 }}
                      >
                        &#8377; {totalCartValue.shippingCost}
                      </Typography>
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid lg={8} md={12} sm={12} xs={12} item>
                        {totalCartValue?.discountBreakupInfo1
                          ? totalCartValue?.discountBreakupInfo1.map(
                              (disObj, i) => {
                                return (
                                  <Grid
                                    key={i}
                                    container
                                    justifyContent={"space-between"}
                                  >
                                    <Typography>
                                      {Object.keys(disObj)}
                                    </Typography>
                                    <Typography variant="h5">
                                      {" "}
                                      - ₹{Math.floor(
                                        Object.values(disObj)
                                      )}{" "}
                                    </Typography>
                                  </Grid>
                                );
                              }
                            )
                          : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ bgcolor: "dark.main" }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        align="left"
                        color="primary"
                        variant="subtitle1"
                      >
                        {" "}
                        Grand Total :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        align="left"
                        color="primary"
                        variant="subtitle1"
                      >
                        {" "}
                        &#8377; {totalCartValue?.totalPayableAmount}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  {totalCartValue?.isMandatoryPrepaid &&
                    (paymentMethod == "6" || paymentMethod == 6) && (
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography
                              align="left"
                              color="primary"
                              variant="subtitle1"
                            >
                              {" "}
                              Pay on delivery :
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              align="left"
                              color="primary"
                              variant="subtitle1"
                            >
                              &#8377; {totalCartValue?.remainingAmountForCod}{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              align="left"
                              color="primary"
                              variant="subtitle1"
                            >
                              {" "}
                              Pay Now :
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              align="left"
                              color="primary"
                              variant="subtitle1"
                            >
                              {" "}
                              &#8377; {
                                totalCartValue?.prepaidCollectedAmount
                              }{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  {/* {onlinePurchaseLimit < +totalCartValue?.totalPayableAmount &&
                    paymentMethod == 2 && (
                      <Typography style={{ color: "red", margin: "10px 0px" }}>
                        Online Purchase Limit Exceeded, Amount should be less
                        than {onlinePurchaseLimit}
                      </Typography>
                    )} */}
                  <LoadingButton
                    disabled={
                      orderID == null ||
                      selectAddressId == null ||
                      loadingOfPlaceOrder ||
                      selectedAgents == null ||
                      sellerType !== "BULK"||
                      isPaymentGenerate||
                      ((paymentMethod==2||paymentMethod==6)&& razorpayOrderId=='' )
                      //  ||
                      // (onlinePurchaseLimit <
                      //   +totalCartValue?.totalPayableAmount &&
                      //   paymentMethod == 2) ||
                      // buttonDisable
                    }
                    variant="contained"
                    loadingIndicator="Loading…"
                    onClick={placeOrder}
                    loading={loadingOfPlaceOrder}
                  >
                    Create order
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
      <Modal
        open={showPackModal}
        onClose={() => setShowPackModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#fff",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {product && (
            <div style={{ padding: "5px 10px", margin: "10px auto" }}>
              <div
                style={{
                  border: "1px solid #999",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                    {product?.name}
                  </Typography>
                  <Typography> MRP ₹{product?.menuPrice}</Typography>
                  <Typography>
                    ₹{" "}
                    {Math.ceil(
                      product?.menuPrice *
                        (1 - calculateDiscount(product, isMemberShip) / 100) *
                        productQuantity
                    )}
                  </Typography>
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={() =>
                      AddProductToFavourites(product, productQuantity)
                    }
                    disabled={
                      totalProductView.findIndex(
                        (item) =>
                          item.productId == product?.productId &&
                          item.comboId == undefined
                      ) > -1
                    }
                  >
                    {" "}
                    Add to cart
                  </Button>
                </div>
              </div>
            </div>
          )}

          <Grid>
            {product &&
              product?.combos?.length > 0 &&
              product.combos.map((combo) => (
                <div style={{ padding: "5px 10px", margin: "10px auto" }}>
                  <div
                    style={{
                      border: "1px solid #999",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                      >
                        {combo.name}
                      </Typography>
                      <Typography>
                        {" "}
                        ₹
                        {Math.ceil(
                          (product.menuPrice *
                            (1 -
                              (calculateDiscount(product, isMemberShip) +
                                combo.discounts) /
                                100) *
                            combo.products[0].quantity) /
                            combo.products[0].quantity
                        )}{" "}
                        per pc
                      </Typography>
                      <Typography>
                        {" "}
                        Total ₹
                        {Math.ceil(
                          product.menuPrice *
                            (1 -
                              (calculateDiscount(product, isMemberShip) +
                                combo.discounts) /
                                100) *
                            combo.products[0].quantity
                        )}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        disabled={packProductIds.includes(combo._id)}
                        variant="contained"
                        onClick={() =>
                          addPackProduct(
                            product,
                            combo._id,
                            combo.products[0].quantity
                          )
                        }
                      >
                        {packProductIds.includes(combo._id)
                          ? "Already In cart"
                          : "Add to cart"}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openAddressModal}
        onClose={() => {
          setOpenAddressModal(false);
          setIsBillingModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#fff",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography mt={2} textAlign={"center"}>
            {isBillingModal ? "Billing Address" : "Seller Address"}
          </Typography>
          <Grid container spacing={2} justifyContent="center" p={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="name"
                value={
                  isBillingModal
                    ? addBillingAddress.name
                    : addAddress?.name || ""
                }
                onChange={(e) => handelAddressChange(e, isBillingModal)}
                type="text"
                size="small"
                label="Name"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="phoneNumber"
                label="Phone Number"
                value={
                  isBillingModal
                    ? addBillingAddress.phoneNumber
                    : addAddress?.phoneNumber || ""
                }
                onChange={(e) => handelAddressChange(e, isBillingModal)}
                error={phoneNumberError ? true : false}
                helperText={phoneNumberError}
                type="text"
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="landmark"
                label="Landmark"
                value={
                  isBillingModal
                    ? addBillingAddress.landmark
                    : addAddress?.landmark || ""
                }
                onChange={(e) => handelAddressChange(e, isBillingModal)}
                type="text"
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="address1"
                label="Address1"
                value={
                  isBillingModal
                    ? addBillingAddress.address1
                    : addAddress?.address1 || ""
                }
                onChange={(e) => handelAddressChange(e, isBillingModal)}
                type="text"
                size="small"
                inputProps={{
                  maxLength: 50,
                }}
                helperText={`${
                  isBillingModal
                    ? addBillingAddress.address1.length
                    : addAddress?.address1.length
                }/50`}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="address2"
                label="Address2"
                value={
                  isBillingModal
                    ? addBillingAddress.address2
                    : addAddress?.address2 || ""
                }
                onChange={(e) => handelAddressChange(e, isBillingModal)}
                type="text"
                size="small"
                inputProps={{
                  maxLength: 50,
                }}
                helperText={`${
                  isBillingModal
                    ? addBillingAddress.address2.length
                    : addAddress?.address2.length
                }/50`}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name="alternatePhoneNumber"
                    label="Alternate PhoneNumber"
                    value={isBillingModal?addBillingAddress.alternatePhoneNumber:addAddress?.alternatePhoneNumber || ""}
                    onChange={(e)=>handelAddressChange(e,isBillingModal)}
                    type="number"
                    size="small"
                  />
                </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="postalCode"
                label="Pin Code"
                value={
                  isBillingModal
                    ? addBillingAddress.postalCode
                    : addAddress?.postalCode || ""
                }
                onChange={handlePinCode}
                type="number"
                error={
                  isBillingModal
                    ? pinCodeErrorForBill
                    : pinCodeError
                    ? true
                    : false
                }
                helperText={isBillingModal ? pinCodeErrorForBill : pinCodeError}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="city"
                label="City"
                value={
                  isBillingModal
                    ? addBillingAddress.city
                    : addAddress?.city || ""
                }
                onChange={(e) => handelAddressChange(e, isBillingModal)}
                type="text"
                disabled
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="state"
                label="State"
                size="small"
                value={
                  isBillingModal
                    ? addBillingAddress.state
                    : addAddress?.state || ""
                }
                onChange={(e) => handelAddressChange(e, isBillingModal)}
                type="text"
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent={"center"}
            >
              <Button
                variant="contained"
                size="small"
                type="submit"
                color="secondary"
                onClick={
                  (isBillingModal ? addressIdForBill : addressId)
                    ? editAddressInCrm
                    : saveAddess
                }
                disabled={
                  !isBillingModal
                    ? phoneNumberError != "" || pinCodeError != ""
                    : phoneNumberErrorForBill != "" || pinCodeErrorForBill != ""
                }
              >
                {(isBillingModal ? addressIdForBill : addressId)
                  ? "Edit Address"
                  : "Add Address"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={cloneOrderModal}
        onClose={() => setCloneOrderModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#fff",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 10,
          }}
        >
          <Grid spacing={4} justifyContent="center" p={5}>
            <Grid>
              <TextField
                name="Order Id"
                label="Order Id"
                value={cloneOrderId}
                onChange={(e) => setCloneOrderId(e.target.value)}
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Button
              variant="contained"
              type="submit"
              onClick={() => cloneOrder(cloneOrderId)}
              fullWidth
              sx={{ marginTop: 5 }}
            >
              Clone Order
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default CreateOrderForBulk;
