import { Grid } from "@material-ui/core";
import React from "react";
import SubCard from "../../../ui-component/cards/SubCard";

const StageOrderStatus = ({ singleOrder, orderAwbNumber }) => {
  return (
    <>
      <Grid item xs={12} container>
        <Grid item xs={!orderAwbNumber ? 6 : 12}>
          {/* <SubCard > */}
            {/* Update Sinnle awb numbers */}

            {singleOrder.awbNumber &&
            singleOrder?.shippingMethod === "XPRESSBEES" ? (
              
                <Grid  style={{height:'80vh'}}>
                  <iframe
                    src={`https://www.xpressbees.com/track`}
                    width="100%"
                    height="100%"
                  />
                </Grid>
            ) : (
              <>
                {singleOrder.awbNumber &&
                singleOrder?.shippingMethod === "ROCKETBOX" ? (
                  <Grid style={{height:'80vh'}}>
                    <iframe
                      src={`https://app.rocketbox.in/p/track-shipment?waybillno=${orderAwbNumber}&dp_name=undefined`}
                      width="100%"
                      height="100%"
                    />
                  </Grid>
                ) : (
                    <Grid  style={{height:'80vh'}}>
                      <iframe
                        src={`https://shiprocket.co/tracking/${orderAwbNumber}`}
                        width="100%"
                        height="100%"
                      />
                    </Grid>
                )}
              </>
            )}
          {/* </SubCard> */}
        </Grid>
      </Grid>
    </>
  );
};

export default StageOrderStatus;
