import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { MapPin, Phone, Mail, Clock, Calendar, FileText } from "lucide-react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import axios from "axios";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { CheckCircle, ContentCopy, Payment } from "@material-ui/icons";
import moment from "moment";

export default function SellerDetails() {
  const { _id, sellerId } = useParams();
  const [sellerProfile, setSellerProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [LoadingPyament, setLoadingPyament] = useState(false);
  const GOOGLE_MAPS_API_KEY = "AIzaSyDZUXfv2E1crCqQLWkM0LEeRztM3d8g2Sg";

  const [openDialog, setOpenDialog] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  // const center =

  const formatTime = (time) => {
    if (!time) return "";
    const hours = time.slice(0, 2);
    const minutes = time.slice(2);
    return `${hours}:${minutes}`;
  };

  const formatDays = (days) => {
    if (!days) return "";
    const daysMap = {
      1: "Mon",
      2: "Tue",
      3: "Wed",
      4: "Thu",
      5: "Fri",
      6: "Sat",
      7: "Sun",
    };
    return days
      .split(",")
      .map((day) => daysMap[day])
      .join(", ");
  };

  const getAllSellers = () => {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_PATH +
          "/ondc/crm/seller/detailsFromCrm?sellerId=" +
          sellerId
      )
      .then((res) => {
        console.log("response of seller list", res.data);
        if (res.data.success) {
          setSellerProfile(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error of seller list", error);
      });
  };

  const handleGeneratePaymentLink = () => {
    setLoadingPyament(true);
    axios
      .post(process.env.REACT_APP_PATH + "/ondc/crm/seller/createPaymentLink", {
        amount: "100",
        phoneNumber: sellerProfile?.phoneNumber,
        sellerId: sellerId, //mandatory
      })
      .then((res) => {
        if (res.data.success) {
          setPaymentLink(res?.data?.message||'');
          setOpenDialog(true);
          setLoadingPyament(false);
        } else {
          alert(res.data?.message || "Someting went wrong");
          setLoadingPyament(false);
        }
      })
      .catch((error) => {
        alert("Someting went wrong");
        setLoadingPyament(false);
      });
  };

  const handleCopyLink =async () => {
    try {
      await navigator.clipboard.writeText(paymentLink);
      setSnackbar({
        open: true,
        message: "Payment link copied to clipboard!",
        severity: "success",
      });
    } catch (error) {
      console.log('error',error)
    }
   
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  useEffect(() => {
    getAllSellers();
  }, []);

  return (
    <Paper elevation={0}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Card sx={{ margin: "auto", mt: 4 }}>
          {/* <Box sx={{ position: "relative", height: 200, overflow: "hidden" }}>
            <img
              src={sellerProfile.symbol}
              alt={sellerProfile.name}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box> */}
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              {sellerProfile?.name}
            </Typography>
            <Chip
              label={sellerProfile?.isActive ? "Active" : "Inactive"}
              color={sellerProfile?.isActive ? "success" : "error"}
              sx={{ mb: 2 }}
            />
            <Typography variant="body2" color="text.secondary" paragraph>
              {sellerProfile?.shortDescription}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <MapPin size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Address"
                      secondary={`${sellerProfile?.address.street}, ${sellerProfile?.address.locality}, ${sellerProfile?.address.city}, ${sellerProfile?.address.state} - ${sellerProfile?.address.pincode}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Phone size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Phone"
                      secondary={sellerProfile?.phoneNumber}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Mail size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Email"
                      secondary={sellerProfile?.emailId}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <Clock size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Working Hours"
                      secondary={`${formatTime(
                        sellerProfile?.fulfillment.start
                      )} - ${formatTime(sellerProfile?.fulfillment.end)}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Calendar size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Working Days"
                      secondary={formatDays(sellerProfile?.fulfillment.days)}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FileText size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Description"
                      secondary={sellerProfile?.longDescription}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>

            {/* ONDC Payment Section */}
            <Box sx={{ mt: 4, width: "50%" }}>
              <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ mb: 2, display: "flex", alignItems: "center" }}
                >
                  <Payment sx={{ mr: 1 }} /> ONDC Subscription
                </Typography>

                {sellerProfile.subscribeToOndc ? (
                  <Card variant="outlined" sx={{ mb: 2, bgcolor: "#f0f7ff" }}>
                    <CardContent>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <CheckCircle color="success" sx={{ mr: 1 }} />
                        <Typography variant="subtitle1" color="success.main">
                          Already Paid
                        </Typography>
                      </Box>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Amount:</strong> ₹
                        {sellerProfile?.ondcSubscription?.razorPayOrderAmount}
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Payment Date:</strong>{" "}
                        {moment.unix(sellerProfile?.ondcSubscription?.razorPayObj?.created_at).format('Do MMMM YYYY, h:mm A')}
                      </Typography>

                      <Typography variant="body2">
                        <strong>Transaction ID:</strong>{" "}
                        {sellerProfile?.ondcSubscription?.razorPayOrderId}
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <Box>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      A one-time payment of ₹100 is required to subscribe to
                      ONDC services.
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGeneratePaymentLink}
                      disabled={LoadingPyament}
                      startIcon={<Payment />}
                    >
                      {LoadingPyament
                        ? "Generating..."
                        : "Generate Payment Link"}
                    </Button>
                  </Box>
                )}
              </Paper>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Holidays:
              </Typography>
              {sellerProfile?.fulfillment.holidays.map((holiday, index) => (
                <Chip key={index} label={holiday} sx={{ mr: 1, mb: 1 }} />
              ))}
            </Box>
            <Box sx={{ mt: 2, height: 400 }}>
              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: sellerProfile?.gps?.coordinates[0],
                    lng: sellerProfile?.gps?.coordinates[1],
                  }}
                  zoom={15}
                >
                  <Marker
                    position={{
                      lat: sellerProfile?.gps?.coordinates[0],
                      lng: sellerProfile?.gps?.coordinates[1],
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </Box>
          </CardContent>
        </Card>
      )}
      {/* Payment Link Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Payment Link Generated</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Share this payment link with the customer to complete the ₹100 ONDC
            subscription payment:
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            value={paymentLink}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  onClick={handleCopyLink}
                  startIcon={<ContentCopy />}
                  size="small"
                >
                  Copy
                </Button>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}
