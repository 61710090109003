import { LoadingButton } from "@mui/lab";
import { Dialog, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

function EditAddressInOrderModal({
  open,
  handleClose,
  oldAddress,
  selectedOrderId,
  dataLoad
}) {
  const [loading,setLoading] = useState(false) 
  const [address, setAddress] = useState({});
  const addressEdit = () => {
    setLoading(true);
    const allAddress = {
      orderId: selectedOrderId,
      address: address,
      isBulkOrder:true
    };

    axios
      .post(
        process.env.REACT_APP_PATH + `/products/editOrderAddress`,
        allAddress
      )
      .then((res) => {
        if(res.data.success){
            
            alert(res.data?.message||'Address succesfully updated in order')
            setLoading(false);
            dataLoad()
            handleClose(false);
        }else{
            alert(res.data?.message||'Something went wrong')
            setLoading(false);
        }
   
      })
      .catch((err) => {
        alert('Something went wrong')
        setLoading(false);
      });

  };
  useEffect(()=>{
    setAddress(oldAddress)
  },[oldAddress])
  const addressUpdate = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        xs={12}
        item
        style={{
          padding: "10px 20px",
          width: "420px",
        }}
      >
        {/* <Grid className={styles.input}> */}
        <Grid xs={12} item spacing={2}>
          <TextField
            required
            label="Your Name"
            variant="outlined"
            value={address?.name || ""}
            name="name"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid xs={12} item spacing={2}>
          <TextField
            required
            label="Your Mobile Number"
            variant="outlined"
            value={address?.phoneNumber || ""}
            name="phoneNumber"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid>
          <TextField
            required
            label="Address Line 1"
            variant="outlined"
            value={address?.address1 || ""}
            name="address1"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid>
          <TextField
            required
            label="Address Line 2"
            variant="outlined"
            value={address?.address2 || ""}
            name="address2"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid style={{ display: "flex" }}>
          <TextField
            required
            label="City"
            variant="outlined"
            value={address?.city || ""}
            name="city"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid>
          <TextField
            label="Landmark"
            variant="outlined"
            value={address?.landmark || ""}
            name="landmark"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid>
          <TextField
            label="State"
            variant="outlined"
            value={address?.state || ""}
            name="state"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid>
          <TextField
            label="Postal Code"
            variant="outlined"
            value={address?.postalCode || ""}
            name="postalCode"
            onChange={(e) => addressUpdate(e)}
            style={{ margin: "10px 0px", width: "100%" }}
          />
        </Grid>
        <Grid xs={12} item textAlign="center">
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => {
              addressEdit();
            }}
          >
            <span>Edit Address</span>
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default EditAddressInOrderModal;
