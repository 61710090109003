import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";

function StageBulkInvoice({singleOrder}) {
  const [allOnlineEANCodeQuantity,setAllOnlineEANCodeQuantity] = useState([])
  const [buttonDisable, setButtonDisable] = useState(true);
  const [filterProducts,setFilerProducts] = useState([])
  const [totalPayableAmount,setTotalPayableMount] = useState(0)
  const [loading,setLoading] = useState(true)
  const lorealOrMatrix =['60f8198155498632e803cefc','60f8198155498632e803ceec']
    const invoiceRef = useRef();

    // Function to generate PDF
    const downloadPDF = async () => {
      const element = invoiceRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");
  
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
      pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    };


    const getBulkUcInventory = async ()=>{
      try {
        let res = await axios
        .post(`${process.env.REACT_APP_PATH}/products/getAllUcInventoryV2`,{
          isBulkOrOnline:false
        })
        if(res.data.success){
          return res.data.data
        }else{
          return {}
        }
      } catch (error) {
        return {}
      }
    }

    const getOnLineUcInventory = async ()=>{
      try {
        let res = await axios
        .post(`${process.env.REACT_APP_PATH}/products/getAllUcInventoryV2`,{
          isBulkOrOnline:true
        })
        if(res.data.success){
          return res.data.data
        }else{
          return {}
        }
      } catch (error) {
        return {}
      }
    }



    const getProducts = async()=>{
      try {
        let bulkInv = await getBulkUcInventory()
        let UcInv = await getOnLineUcInventory()
        let temp = singleOrder?.products.filter((item)=>item.quantity<= ((lorealOrMatrix.includes(item.brandId)?0:bulkInv[item.EANCode]||0)+(UcInv[item?.EANCode]||0)))
        let totalPay = temp.reduce((acc,item)=>{
          let subTotal = item?.actualDiscount?((item.mrp*item.quantity*item.actualDiscount)/100):item.subtotal
          return acc+subTotal
        },0)
        setTotalPayableMount(totalPay)
        setFilerProducts(temp)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        alert('Someting went wrong')
      }

    }


    // const getOnLineUcInventory = async (EANCode) => {
    //   axios
    //     .post(`${process.env.REACT_APP_PATH}/products/getAllUcInventoryV2`,{
    //       isBulkOrOnline:true
    //     })
    //     .then((res) => {
    //       if (res.data.success) {
    //         if (res.data.data) {
    //           setAllOnlineEANCodeQuantity(res.data.data);
    //           setButtonDisable(false);
    //           let temp = singleOrder?.products.filter((item)=>item.quantity<= res.data.data[item.EANCode])
    //           let totalPay = temp.reduce((acc,item)=>{
    //             let subTotal = item?.actualDiscount?((item.mrp*item.quantity*item.actualDiscount)/100):item.subtotal
    //             return acc+subTotal
    //           },0)
    //           console.log('totalPay',totalPay)
    //           setTotalPayableMount(totalPay)
    //           setFilerProducts(temp)
    //           setLoading(false)
    //           console.log('temp',temp)
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       alert(err?.response?.data?.message || "something went wrong");
    //     });
    // };
  

    useEffect(()=>{
      getProducts()
    },[])

    if(loading){
      return (
        <div>
          <p>Loading</p>
        </div>
      )
    }

  return (
    <div>
      {/* Invoice Content */}
      <div ref={invoiceRef} style={{ padding: "20px", border: "1px solid #ddd", maxWidth: "800px", margin: "auto" }}>
        <h1 style={{ textAlign: "center" }}>Invoice</h1>
        <p>Invoice Number: <strong>#{singleOrder?.numericOrderId}</strong></p>
        <p>Date: <strong>{new Date(singleOrder?.createdAt).toLocaleDateString()}</strong></p>
        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Item</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Mrp</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Quantity</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Discount</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {
                filterProducts && filterProducts.length>0 && filterProducts.map((product)=>{
                    let subTotal = product?.actualDiscount?((product.mrp*product.quantity*product.actualDiscount)/100):product.subtotal
                  return(

                    <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.name}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{product.mrp}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.quantity}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.actualDiscount}%</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{subTotal}</td>
                  </tr>
                )})
            }
          </tbody>
          <tfoot>
          <tr>
              <td colSpan="4" style={{ borderLeft: "1px solid #ddd", padding: "8px", textAlign: "right", }}>Shipping Cost</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{singleOrder?.shippingCost||0}</td>
            </tr>
            <tr>
              <td colSpan="4" style={{ borderLeft: "1px solid #ddd",borderBottom:'1px solid #ddd', padding: "8px", textAlign: "right" }}>Total</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{(totalPayableAmount+(singleOrder?.shippingCost||0)).toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      {/* Download Button */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={downloadPDF} style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer" }}>
          Download PDF
        </button>
      </div>
    </div>
  )
}

export default StageBulkInvoice