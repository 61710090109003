import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// project imports
import MainLayout from "./../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
import AuthGuard from "./../utils/route-guard/AuthGuard";
import OnlineOrderAbove20K from "../menu-items/ordersCrm/OnlineOrderAbove20K";
import OfflineUnverifiedOrder from "../menu-items/ordersCrm/OfflineUnverifiedOrder";
import CrmOrders from "../menu-items/Dashboard/SaleDashboard/CrmOrders";
import OrganicVarifyOrders from "../menu-items/ordersCrm/OrganicVarifyOrders";
import BlockPincode from "../menu-items/Dashboard/SaleDashboard/BlockPincode";
import FcGrnPost from "../menu-items/FcAdmin/FcGrnPost";
import FcGrnPostVendor from "../menu-items/Vendor/FcGrnPostVendor";
import CreateOrderForBulk from "../menu-items/Bulk/CreateOrderForBulk";
import BulkOrderList from "../menu-items/Bulk/BulkOrderList";
import BulkOrderDetails from "../menu-items/Bulk/BulkOrderDetails";
import NewProductWiseReport from "../menu-items/FinancialReports/NewProductWiseReport";
import OnlineBulkUnverifiedOrder from "../menu-items/ordersCrm/OnlineBulkUnverifiedOrder";
import BulkShipStatus from "../menu-items/Bulk/BulkShipStatus";
import NonCrmPackingOrder from "../menu-items/PackingOrder/NonCrmPackingOrder";
import PackerNonCrmOrderList from "../menu-items/Vendor/FloridaDashboard/PackerNonCrmOrderList";
import FCOrderWiseSettlementReport from "../menu-items/Vendor/FCOrderWiseSettlementReport";
import MovedOrderFromBulk from "../menu-items/Bulk/MovedOrderFromBulk";
import SalonUserRegister from "../menu-items/Admin/SalonUserRegister";
import BulkOrderApprovalScm from "../menu-items/Bulk/BulkOrderApprovalScm";
import EditBulkOrderScm from "../menu-items/Bulk/EditBulkOrderScm";
import FloridaProductWithSeller from "../menu-items/Reports/FloridaProductWithSeller";
import AllDownload from "../menu-items/Dashboard/SaleDashboard/AllDownload";
import CreatePaymentLink from "../menu-items/Product/SupplyChain/CreatePaymentLink";
import UploadBulkMargin from "../menu-items/Bulk/UploadBulkMargin";
import BulkDownloadOrderData from "../menu-items/Bulk/BulkAnalysis/BulkDownloadOrderData";
import BulkDownloadProductData from "../menu-items/Bulk/BulkAnalysis/BulkDownloadProductData";
import UpdateZohoCustomerId from "../menu-items/Bulk/BulkAnalysis/UpdateZohoCustomerId";
import CreateOfflineCertificates from "../menu-items/Zoom/CreateOfflineCertificates";
import SearchBulkOrders from "../menu-items/AllBulkOrders/SearchBulkOrders";
import ReachedAtDestinationOrders from "../menu-items/AllBulkOrders/ReachedAtDestinationOrders";
import ProcessingOrder from "../menu-items/AllBulkOrders/ProcessingOrder";
import BulkBrandWisePurchaseReport from "../menu-items/AllBulkOrders/BulkBrandWisePurchaseReport";
import AppointmentList from "../menu-items/Zoom/AppointmentList";
import AppointmentDetails from "../menu-items/Zoom/AppointmentDetails";
import { getDecryptDataFromLocal } from "../utils/encrypt";
import UploadAgentTarget from "../menu-items/Dashboard/Leads/UploadAgentTarget";
import CashCreateOrderForBulk from "../menu-items/Bulk/CashCreateOrderForBulk";
import AddBulkParentSeller from "../menu-items/Bulk/AddBulkParentSeller";
import SellerDetails from "../menu-items/ONDC/Sellers/SellerDetails";

import OndcSellerDetails from "../menu-items/ONDC/Sellers/OndcSellerDetails";
import OndcOrderDetails from "../menu-items/ONDC/OndcOrders/OndcOrderDetails";
import FiveDaysOldOrderReassign from "../menu-items/ordersCrm/FiveDaysOldOrders";
import SellerCrmOrdersList from "../menu-items/ordersCrm/SellerCrmOrdersList";
import ProductForecast from "../menu-items/Product/ProductForecast";
import MoveOrderBulkToProductCollection from "../menu-items/Bulk/BulkAnalysis/MoveOrderBulkToProductCollection";
import MoveBulkStageOrder from "../menu-items/Bulk/BulkAnalysis/MoveBulkStageOrder";
import StageWiseOrders from "../menu-items/AllBulkOrders/StageWiseOrders";
import StageBulkOrderDetails from "../menu-items/AllBulkOrders/StafeBulkDetails/StageBulkOrderDetails";


// dashboard routing
/* const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
); */
const DashboardAnalytics = Loadable(
  lazy(() => import("../views/dashboard/Analytics"))
);

// widget routing
// const WidgetStatistics = Loadable(
//   lazy(() => import("../views/widget/Statistics"))
// );
// const WidgetData = Loadable(lazy(() => import("../views/widget/Data")));
// const WidgetChart = Loadable(lazy(() => import("../views/widget/Chart")));

// // application - user social & account profile routing
// const AppUserSocialProfile = Loadable(
//   lazy(() => import("../views/application/users/social-profile"))
// );
// const AppUserAccountProfile1 = Loadable(
//   lazy(() => import("../views/application/users/account-profile/Profile1"))
// );
// const AppUserAccountProfile2 = Loadable(
//   lazy(() => import("../views/application/users/account-profile/Profile2"))
// );
// const AppUserAccountProfile3 = Loadable(
//   lazy(() => import("../views/application/users/account-profile/Profile3"))
// );

// // application - user cards & list variant routing
// const AppProfileCardStyle1 = Loadable(
//   lazy(() => import("../views/application/users/card/CardStyle1"))
// );
// const AppProfileCardStyle2 = Loadable(
//   lazy(() => import("../views/application/users/card/CardStyle2"))
// );
// const AppProfileCardStyle3 = Loadable(
//   lazy(() => import("../views/application/users/card/CardStyle3"))
// );
// const AppProfileListStyle1 = Loadable(
//   lazy(() => import("../views/application/users/list/Style1"))
// );
// const AppProfileListStyle2 = Loadable(
//   lazy(() => import("../views/application/users/list/Style2"))
// );

// // application - customer routing
// const AppCustomerList = Loadable(
//   lazy(() => import("../views/application/customer/CustomerList"))
// );
// const AppCustomerOrderList = Loadable(
//   lazy(() => import("../views/application/customer/OrderList"))
// );
// const AppCustomerOrderDetails = Loadable(
//   lazy(() => import("../views/application/customer/OrderDetails"))
// );
// const AppCustomerProduct = Loadable(
//   lazy(() => import("../views/application/customer/Product"))
// );
// const AppCustomerProductReview = Loadable(
//   lazy(() => import("../views/application/customer/ProductReview"))
// );

// // application routing
// const AppChat = Loadable(lazy(() => import("../views/application/chat")));
// const AppMail = Loadable(lazy(() => import("../views/application/mail")));
// const AppCalendar = Loadable(
//   lazy(() => import("../views/application/calendar"))
// );
// const AppContactCard = Loadable(
//   lazy(() => import("../views/application/contact/Card"))
// );
// const AppContactList = Loadable(
//   lazy(() => import("../views/application/contact/List"))
// );

// // forms component routing
// const FrmComponentsTextfield = Loadable(
//   lazy(() => import("../views/forms/components/TextField"))
// );
// const FrmComponentsButton = Loadable(
//   lazy(() => import("../views/forms/components/Button"))
// );
// const FrmComponentsCheckbox = Loadable(
//   lazy(() => import("../views/forms/components/Checkbox"))
// );
// const FrmComponentsRadio = Loadable(
//   lazy(() => import("../views/forms/components/Radio"))
// );
// const FrmComponentsSwitch = Loadable(
//   lazy(() => import("../views/forms/components/Switch"))
// );
// const FrmComponentsAutoComplete = Loadable(
//   lazy(() => import("../views/forms/components/AutoComplete"))
// );
// const FrmComponentsSlider = Loadable(
//   lazy(() => import("../views/forms/components/Slider"))
// );
// const FrmComponentsDateTime = Loadable(
//   lazy(() => import("../views/forms/components/DateTime"))
// );

// // forms plugins layout
// const FrmLayoutLayout = Loadable(
//   lazy(() => import("../views/forms/layouts/Layouts"))
// );
// const FrmLayoutMultiColumnForms = Loadable(
//   lazy(() => import("../views/forms/layouts/MultiColumnForms"))
// );
// const FrmLayoutActionBar = Loadable(
//   lazy(() => import("../views/forms/layouts/ActionBar"))
// );
// const FrmLayoutStickyActionBar = Loadable(
//   lazy(() => import("../views/forms/layouts/StickyActionBar"))
// );

// // forms plugins routing
// const FrmAutocomplete = Loadable(
//   lazy(() => import("../views/forms/plugins/AutoComplete"))
// );
// const FrmMask = Loadable(lazy(() => import("../views/forms/plugins/Mask")));
// const FrmClipboard = Loadable(
//   lazy(() => import("../views/forms/plugins/Clipboard"))
// );
// const FrmRecaptcha = Loadable(
//   lazy(() => import("../views/forms/plugins/Recaptcha"))
// );
// const FrmWysiwugEditor = Loadable(
//   lazy(() => import("../views/forms/plugins/WysiwugEditor"))
// );
// const FrmModal = Loadable(lazy(() => import("../views/forms/plugins/Modal")));
// const FrmTooltip = Loadable(
//   lazy(() => import("../views/forms/plugins/Tooltip"))
// );

// // table routing
// const TableBasic = Loadable(
//   lazy(() => import("../views/forms/tables/TableBasic"))
// );
// const TableDense = Loadable(
//   lazy(() => import("../views/forms/tables/TableDense"))
// );
// const TableEnhanced = Loadable(
//   lazy(() => import("../views/forms/tables/TableEnhanced"))
// );
// const TableData = Loadable(
//   lazy(() => import("../views/forms/tables/TableData"))
// );
// const TableCustomized = Loadable(
//   lazy(() => import("../views/forms/tables/TablesCustomized"))
// );
// const TableStickyHead = Loadable(
//   lazy(() => import("../views/forms/tables/TableStickyHead"))
// );
const OrderList = Loadable(
  lazy(() => import("../menu-items/ordersCrm/SellerOrderlist"))
);

const AuditOrders = Loadable(
  lazy(() => import("../menu-items/ordersCrm/AuditOrders"))
);

// const TableCollapsible = Loadable(
//   lazy(() => import("../views/forms/tables/TableCollapsible"))
// );

const OfflineSellerOrderList = Loadable(
  lazy(() => import("../menu-items/ordersCrm/OfflineSellerOrderReport"))
);
const GroupOrders = Loadable(
  lazy(() => import("../menu-items/ordersCrm/GroupOrders"))
);

const RupifiOrderReport = Loadable(
  lazy(() => import("../menu-items/ordersCrm/RupifyOrdersReport"))
);


// forms validation
// const FrmFormsValidation = Loadable(
//   lazy(() => import("../views/forms/forms-validation"))
// );
// const FrmFormsWizard = Loadable(
//   lazy(() => import("../views/forms/forms-wizard"))
// );

// chart routing
// const ChartApexchart = Loadable(
//   lazy(() => import("../views/forms/chart/Apexchart"))
// );

// basic ui-elements routing
// const BasicUIAccordion = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UIAccordion"))
// );
// const BasicUIAvatar = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UIAvatar"))
// );
// const BasicUIBadges = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UIBadges"))
// );
// const BasicUIBreadcrumb = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UIBreadcrumb"))
// );
// const BasicUICards = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UICards"))
// );
// const BasicUIChip = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UIChip"))
// );
// const BasicUIList = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UIList"))
// );
// const BasicUITabs = Loadable(
//   lazy(() => import("../views/ui-elements/basic/UITabs"))
// );

// // advance ui-elements routing
// const AdvanceUIAlert = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UIAlert"))
// );
// const AdvanceUIDialog = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UIDialog"))
// );
// const AdvanceUIPagination = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UIPagination"))
// );
// const AdvanceUIProgress = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UIProgress"))
// );
// const AdvanceUIRating = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UIRating"))
// );
// const AdvanceUISnackbar = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UISnackbar"))
// );
// const AdvanceUISkeleton = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UISkeleton"))
// );
// const AdvanceUISpeeddial = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UISpeeddial"))
// );
// const AdvanceUITimeline = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UITimeline"))
// );
// const AdvanceUIToggleButton = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UIToggleButton"))
// );
// const AdvanceUITreeview = Loadable(
//   lazy(() => import("../views/ui-elements/advance/UITreeview"))
// );

// // pricing page routing
// const PagesPrice1 = Loadable(
//   lazy(() => import("../views/pages/pricing/Price1"))
// );
// const PagesPrice2 = Loadable(
//   lazy(() => import("../views/pages/pricing/Price2"))
// );

// // utilities routing
// const UtilsTypography = Loadable(
//   lazy(() => import("../views/utilities/Typography"))
// );
// const UtilsColor = Loadable(lazy(() => import("../views/utilities/Color")));
// const UtilsShadow = Loadable(lazy(() => import("../views/utilities/Shadow")));
// const UtilsMaterialIcons = Loadable(
//   lazy(() => import("../views/utilities/MaterialIcons"))
// );
// const UtilsTablerIcons = Loadable(
//   lazy(() => import("../views/utilities/TablerIcons"))
// );
// const UtilsAnimation = Loadable(
//   lazy(() => import("../views/utilities/Animation"))
// );
// const UtilsGrid = Loadable(lazy(() => import("../views/utilities/Grid")));

// // sample page routing
// const SamplePage = Loadable(lazy(() => import("../views/sample-page")));
// //crm route

const orderDetails = Loadable(
  lazy(() => import("../menu-items/ordersCrm/OrderDetails"))
);

const AddressProblem = Loadable(
  lazy(() => import("../menu-items/ordersCrm/AddressProblem"))
);
const PriorityOrders = Loadable(
  lazy(() => import("../menu-items/ordersCrm/PriorityOrders"))
);

const PrepaidCancelledOrders = Loadable(lazy(() => import("../menu-items/ordersCrm/PrepaidCancelledOrders")))
const ReturnPickupOrders = Loadable(lazy(() => import("../menu-items/ordersCrm/ReturnPickupOrders")))
const ReturnPickupOrdersStatus = Loadable(lazy(() => import("../menu-items/ordersCrm/Orderstatus")))
const ReDispatchOrders = Loadable(lazy(() => import("../menu-items/ordersCrm/ReDispatchOrders")))
const AutoRefundedPrepaidOrders = Loadable(lazy(() => import("../menu-items/ordersCrm/AutoRefundedPrepaidOrders")))
const OnHoldOrders = Loadable(lazy(() => import("../menu-items/ordersCrm/OnHoldOrders")))


const StatusOneOrders = Loadable(
  lazy(() => import("../menu-items/ordersCrm/StatusOneOrders"))
);
const SellerList = Loadable(
  lazy(() => import("../menu-items/Seller/SellerList"))
);
const GiftSellerCoupon = Loadable(
  lazy(() => import("../menu-items/Seller/GiftSellerCoupon"))
);
const SellerAddressList = Loadable(
  lazy(() => import("../menu-items/Seller/SellerAddressList"))
);
// const SellerApproveList = Loadable(
//   lazy(() => import("../menu-items/Seller/SellerApproveList"))
// );
const SellerBillApproveList = Loadable(
  lazy(() => import("../menu-items/Seller/SellerBillApproveList"))
);
const BillApprovePage = Loadable(
  lazy(() => import("../menu-items/Seller/BillApprovePage"))
);
const SellerProfile = Loadable(
  lazy(() => import("../menu-items/Seller/SellerProfile"))
);
const CustomerList = Loadable(
  lazy(() => import("../menu-items/Customer/CustomerList"))
);

const OfflineSeller = Loadable(
  lazy(() => import("../menu-items/Seller/OfflineSellers"))
);
const OrganicSellers = Loadable(
  lazy(() => import("../menu-items/Seller/OrganicSellers"))
);


const SellerMemberShip = Loadable(lazy(() => import("../menu-items/Seller/SellerMemberShip")))

/* Default Dashboard */

const MainDashboard = Loadable(
  lazy(() => import("../menu-items/MainDashboard/Default/Default"))
);

const SellerProductList = Loadable(
  lazy(() => import("../menu-items/Product/SellerProductList"))
);

const MultiplyOrder = Loadable(
  lazy(() => import("../menu-items/Product/MultiplyOrder"))
);

const productProfile = Loadable(
  lazy(() => import("../menu-items/Product/productProfile/Profile"))
);

const allComboPack = Loadable(
  lazy(() => import("../menu-items/Product/AllComboPacks"))
);

const comboPack = Loadable(
  lazy(() => import("../menu-items/Product/ComboPacks"))
);

const AddShadesAndSize = Loadable(
  lazy(() => import("../menu-items/Product/AddShadesAndSize"))
);

const CreateReplacementOrder = Loadable(
  lazy(() => import("../menu-items/Product/CreateReplacementOrder"))
);

const CustomerOrderList = Loadable(
  lazy(() => import("../menu-items/ordersCrm/CustomerOrderList"))
);
const DamageOrderList = Loadable(
  lazy(() => import("../menu-items/ordersCrm/DamageProducts"))
);
const RocketBoxOrders = Loadable(
  lazy(() => import("../menu-items/ordersCrm/RocketBoxOrders"))
);
// Admin
const Admin = Loadable(lazy(() => import("../menu-items/Admin/Admin")));
const productConcern = Loadable(
  lazy(() => import("../menu-items/Admin/productConcern"))
);
const EditCategory = Loadable(
  lazy(() => import("../menu-items/Admin/EditCategory"))
);
const EditLeafCategory = Loadable(
  lazy(() => import("../menu-items/Admin/EditLeafCategory"))
);
const CreateCoupon = Loadable(
  lazy(() => import("../menu-items/Admin/CreateCoupons"))
);
const Notification = Loadable(
  lazy(() => import("../menu-items/Admin/Notification"))
);
const CreateSalon = Loadable(
  lazy(() => import("../menu-items/Admin/CreateSalon"))
);
const DevImgUpload = Loadable(
  lazy(() => import("../menu-items/Admin/DevImgUpload"))
);
const SalonsWhatsappConfig = Loadable(
  lazy(() => import("../menu-items/Admin/SalonsWhatsappConfig"))
);



const AddSurveyQuestions = Loadable(
  lazy(() => import("../menu-items/Admin/AddSurveyQuestions"))
);
const AgentTikonaConfig = Loadable(
  lazy(() => import("../menu-items/Admin/AgentTikonaConfig"))
);


// reports
const MapWiseReport = Loadable(lazy(() => import("../menu-items/Reports/MapWiseReport")));
const SellerWithFloridaOrders = Loadable(lazy(() => import("../menu-items/Reports/SellerWithFloridaOrder")));
const BuyumeDetails = Loadable(lazy(() => import("../menu-items/Reports/BuyumeDetails")));
const MostSellingProduct = Loadable(lazy(() => import("../menu-items/Reports/MostSellingProduct")));
const MostSellingBrands = Loadable(lazy(() => import("../menu-items/Reports/MostSellingBrands")));
const BrandWiseRetailReport = Loadable(lazy(() => import("../menu-items/Reports/BrandWiseRetailReport")));
const ComboReport = Loadable(lazy(() => import("../menu-items/Reports/ComboReport")));
const ProductOrderDetails = Loadable(lazy(() => import("../menu-items/Reports/ProductOrderDetails")));
const ReferReport = Loadable(lazy(() => import("../menu-items/Reports/ReferReport")));
const DownloadReports = Loadable(lazy(() => import("../menu-items/Reports/DownloadReports")));
const NotAvailableProductsReport = Loadable(lazy(() => import("../menu-items/Reports/NotAvailableProductsReport")));
const MembershipReport = Loadable(lazy(() => import("../menu-items/Reports/MembershipReport")));
const ExpiringRefsReport = Loadable(lazy(() => import("../menu-items/Reports/ExpiringRefsReport")));
const OrderDateCohort = Loadable(lazy(() => import("../menu-items/Reports/OrderDateCohort")))
const OrderDateCohortRto = Loadable(lazy(() => import("../menu-items/Reports/OrderDateCohortOnlineRto")))
const OrderDateCohortSouth = Loadable(lazy(() => import("../menu-items/Reports/OrderDateCohortSouth")))
const OrderDateCohortRtoSouth = Loadable(lazy(() => import("../menu-items/Reports/OrderDateCohortOnlineRtoSouth")))
const DownloadDateCohort = Loadable(lazy(() => import("../menu-items/Reports/DownloadDateCohort")))
const CacReport = Loadable(lazy(() => import("../menu-items/Reports/CacReport")))
const ProductDiscountWiseReport = Loadable(lazy(() => import("../menu-items/Reports/ProductDiscountWiseReport")))
const MonthRTOReport = Loadable(lazy(() => import("../menu-items/Reports/MonthRTOReport")));
const BrandOrderPerPerson = Loadable(lazy(() => import("../menu-items/Reports/BrandOrderPerPerson")));
// const organicInorganicReport = Loadable(lazy(() => import("../menu-items/Reports/OrganicInOrganicOrder")));
const organicInorganicRTOReport = Loadable(lazy(() => import("../menu-items/Reports/OrganicInorganicRTOReport")));
// const AgentOrderReport = Loadable(lazy(() => import("../menu-items/Reports/AgentOrderReport")));
const AgentDealOrders = Loadable(lazy(() => import("../menu-items/Reports/AgentDealOrders")));
const SellerWiseRTOReport = Loadable(lazy(() => import("../menu-items/Reports/SellerWiseRTOReport")));
const DistributorReport = Loadable(lazy(() => import("../menu-items/Reports/DistributorReport")));
const LeadDistribution = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/LeadDistribution")))
const OfflineSellers = Loadable(lazy(() => import("../menu-items/Reports/OfflineSellers")))
const OfflineSellerDetailsReport = Loadable(lazy(() => import("../menu-items/Reports/OfflineSellerDetails")))
const BuyumeReport = Loadable(lazy(() => import("../menu-items/Reports/BuyumeOrderWiseReport")))
const OrderDateOffline = Loadable(lazy(() => import("../menu-items/Reports/OrderDateOfflineCohort")))
const OrderDateOfflineRto = Loadable(lazy(() => import("../menu-items/Reports/OrderDateOfflineCohortRto")))
const OrderDateBulk = Loadable(lazy(() => import("../menu-items/Reports/OrderDateBulkCohort")))
const SplitOrderReport = Loadable(lazy(() => import("../menu-items/Reports/SplitOrderReport")))
const ForcastInventoryReport = Loadable(lazy(() => import("../menu-items/Reports/ForcastInventoryReport")))
const ProductWiseAvailabilityReport = Loadable(lazy(() => import("../menu-items/Reports/ProductWiseAvailabilityReport")))
const ProductAvailabilityTime = Loadable(lazy(() => import("../menu-items/Reports/ProductAvailabilityTime")))
//supply chain
const SupplyDashboard = Loadable(lazy(() => import("../menu-items/Product/SupplyChain/dashboard")))
const OrdersWithIssues = Loadable(lazy(() => import("../menu-items/Product/SupplyChain/OrdersWithIssues")))
const RtoWithLessAttempts = Loadable(lazy(() => import("../menu-items/Product/SupplyChain/RtoWithLessAttempts")))
const InTransitGtTenDays = Loadable(lazy(() => import("../menu-items/Product/SupplyChain/InTransitGtTenDays")))
const DeliveredOrderFeedback = Loadable(lazy(() => import("../menu-items/Product/SupplyChain/DeliveredOrderFeedback")))
const ShipOrderThroughExcel = Loadable(lazy(() => import("../menu-items/Product/SupplyChain/ShipOrderThroughExcel")))

// priortize orders
const PriortizeFloridaOrder = Loadable(lazy(() => import("../menu-items/Product/PriortizeOrder/PriortizeFloridaOrder")))

//Admin reports
const adminBuyumeDetails = Loadable(
  lazy(() => import("../menu-items/AdminReports/BuyuMe"))
);
const BuyumeDetailsForRole12 = Loadable(
  lazy(() => import("../menu-items/AdminReports/BuyumeDetailsForRole12"))
);
const adminMostSellingProduct = Loadable(
  lazy(() => import("../menu-items/AdminReports/MostSellingProduct"))
);
const adminMostSellingBrands = Loadable(
  lazy(() => import("../menu-items/AdminReports/MostSellingBrands"))
);
const adminProductOrderDetails = Loadable(
  lazy(() => import("../menu-items/AdminReports/ProductOrderDetails"))
);
const adminReferReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/ReferReport"))
);
const adminDownloadReports = Loadable(
  lazy(() => import("../menu-items/AdminReports/DownloadReports"))
);
const adminNotAvailableProductsReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/NotAvailableProductsReport"))
);
const adminDownloadCohortReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/DownloadCohortReport"))
);
const adminCohortReportRto = Loadable(
  lazy(() => import("../menu-items/AdminReports/OrderDateCohortRto"))
);
const adminCohortReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/CohortReport"))
);
const SellerWiseRevenu = Loadable(
  lazy(() => import("../menu-items/AdminReports/SellerWiseRevenu"))
);
const CategoryWiseReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/CategoryWiseReport"))
);
const StateWiseSalesReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/StateWiseSalesReport"))
);
const BrandCohortReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/BrandCohortReport"))
);
const SellerRtoReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/SellerRtoReport"))
);
// const CallerwiseSalesReport = Loadable(
//   lazy(() => import("../menu-items/Reports/CallerwiseSalesReport"))
// );



const SellerCoinReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/SellerCoinReport"))
);
const ReferalAmount = Loadable(
  lazy(() => import("../menu-items/AdminReports/ReferalMonthWiseReport"))
);
const BuyumeReportOrderWise = Loadable(
  lazy(() => import("../menu-items/AdminReports/BuyumeReportOrderWise"))
);
const ReconcileReport = Loadable(
  lazy(() => import("../menu-items/FinancialReports/ReconcileReport"))
);
const SellerGST = Loadable(
  lazy(() => import("../menu-items/Reports/SellerGSTReport"))
);

const DownloadOrdersData = Loadable(
  lazy(() => import("../menu-items/Product/DownloadOrdersData"))
);

const Payouts = Loadable(lazy(() => import("../menu-items/Seller/Payout")));
const Zoom = Loadable(lazy(() => import("../menu-items/Zoom/ZoomTraining")));
const TrainingDetails = Loadable(
  lazy(() => import("../menu-items/Zoom/TrainingDetails"))
);
const SlotBooking = Loadable(lazy(() => import("../menu-items/Zoom/SlotBooking")))
const DetailedSlotData = Loadable(lazy(() => import("../menu-items/Zoom/DetailedSlotData")))
const AllMeetings = Loadable(lazy(() => import("../menu-items/Zoom/AllMeetings")))
const ShortsUploading = Loadable(lazy(() => import("../menu-items/Zoom/ShortsUploading")))
const ShortsReports = Loadable(lazy(() => import("../menu-items/Zoom/ShortsReports")))
const LiveTrainingRecordings = Loadable(lazy(() => import("../menu-items/Zoom/LiveTrainingRecordings")))
const LivePageBanner = Loadable(lazy(() => import("../menu-items/Zoom/LivePageBanner")))
const ReadymadeServicesList = Loadable(lazy(() => import("../menu-items/Zoom/ReadymadeServicesList")))
const AnalysisReport = Loadable(lazy(() => import("../menu-items/Zoom/AnalysisReport")))
const MelaCreation = Loadable(lazy(() => import("../menu-items/Zoom/MelaCreation")))
const CreateCourseLink = Loadable(lazy(() => import("../menu-items/Zoom/CreateCourseLink")))
const SuperstarReport = Loadable(lazy(() => import("../menu-items/Zoom/SuperstarReport")))
const WatchtimeReport = Loadable(lazy(() => import("../menu-items/Zoom/WatchTimeReport")))

const AddTrainer = Loadable(
  lazy(() => import("../menu-items/Zoom/AddTrainer"))
);
const Purchase = Loadable(
  lazy(() => import("../menu-items/PurchaseOrder/Purchase"))
);
const SellerCheckOutProduct = Loadable(
  lazy(() => import("../menu-items/Seller/SellerCheckOutProduct"))
);

const ShippedOrderReport = Loadable(
  lazy(() => import("../menu-items/AdminReports/ShippedOrderReport"))
);

/* Inventory */
const UploadInventory = Loadable(
  lazy(() => import("../menu-items/Product/UploadInventory"))
);

const UploadForcastInventory = Loadable(
  lazy(() => import("../menu-items/Product/UploadForcastInventory"))
);


const UploadRTOReport = Loadable(
  lazy(() => import("../menu-items/Product/UploadRTORepot"))
);
const MyInventory = Loadable(
  lazy(() => import("../menu-items/Inventory/MyInventory"))
);
const PreOrderList = Loadable(
  lazy(() => import("../menu-items/Inventory/PreOrderList"))
);
const Brand = Loadable(lazy(() => import("../menu-items/Product/Brand")));
const Fulfilled = Loadable(
  lazy(() => import("../menu-items/Orders/Fulfilled"))
);

const AgentWiseOrder = Loadable(lazy(() => import("../menu-items/Inventory/AgentWiseOrderReport")));


const BulkOrder = Loadable(
  lazy(() => import("../menu-items/Product/BulkOrderDetail"))
);
const VendorCreation = Loadable(
  lazy(() => import("../menu-items/Product/VendorCreation"))
);
const PurchaseOrderProcess = Loadable(
  lazy(() => import("../menu-items/Product/PurchaseOrderProcess"))
);
const POReport = Loadable(
  lazy(() => import("../menu-items/Product/POReport"))
);

const POSkuList = Loadable(
  lazy(() => import("../menu-items/Product/PoSkuList"))
);

const POHistoryBrandWise = Loadable(
  lazy(() => import("../menu-items/Product/POHistoryBrandWise"))
);
const PurchaseOrderDetail = Loadable(
  lazy(() => import("../menu-items/Product/PurchaseOrderDetail"))
);

const InventoryLedger = Loadable(
  lazy(() => import("../menu-items/Product/InventoryLedger"))
);
const SlowMovingQuantity = Loadable(
  lazy(() => import("../menu-items/Product/SlowMovingQuantity"))
);

const AddReward = Loadable(lazy(() => import("../menu-items/Product/AddReward")));
const CoinsReport = Loadable(lazy(() => import("../menu-items/Product/CoinsReport")));
const SplitOrders = Loadable(lazy(() => import("../menu-items/Product/SplitOrders")));
const BrandwiseProductData = Loadable(lazy(() => import("../menu-items/Product/BrandwiseProductData")));
const OutOfStockProducts = Loadable(lazy(() => import("../menu-items/Product/OutOfStockProducts")));
const PaymentMethodChangesHistory = Loadable(lazy(() => import("../menu-items/Product/PaymentMethodChangesHistory")));
const RtoOrdersAfterPaymentChange = Loadable(lazy(() => import("../menu-items/Product/RtoOrdersAfterPaymentChange")));
const ReturnPickupOrder = Loadable(lazy(() => import("../menu-items/Product/ReturnPickupOrder")));
const MarkBulkOrder = Loadable(lazy(() => import("../menu-items/Product/MarkBulkOrder")));
//inventory dialer

const InventoryDialer = Loadable(lazy(() => import('../menu-items/Inventory/Dialer')));
const InventoryAgentCallReport = Loadable(lazy(() => import('../menu-items/Inventory/AgentWiseCallReport')))
const AllTicket = Loadable(lazy(() => import('../menu-items/Inventory/AllTickets')))
const UploadNDR = Loadable(lazy(() => import('../menu-items/Inventory/UploadNDR')))
const MyTickets = Loadable(lazy(() => import('../menu-items/Inventory/MyTickets')))
const AuditTicketClearedByAgents = Loadable(lazy(() => import('../menu-items/Inventory/AuditTicketClearedByAgents')))
const CancelledOrderTickets = Loadable(lazy(() => import('../menu-items/Inventory/CancelledOrderTickets')))
const PreAuditTicketReport = Loadable(lazy(() => import('../menu-items/Inventory/PreAuditTicketReport')))
const TicketDetailedReport = Loadable(lazy(() => import('../menu-items/Inventory/TicketDetailedReport')))
const AuditAgentReport = Loadable(lazy(() => import('../menu-items/Inventory/AuditAgentReport')))
const MelaOrders = Loadable(lazy(() => import('../menu-items/Inventory/MelaOrders')))
const CreateMela = Loadable(lazy(() => import('../menu-items/Inventory/CreateMela')))
const MelaEntryExit = Loadable(lazy(() => import('../menu-items/Inventory/MelaEntryExit')))
const AllMela = Loadable(lazy(() => import('../menu-items/Inventory/AllMela')))
const MelaBanners = Loadable(lazy(() => import('../menu-items/Inventory/MelaBanners')))
const MelaEntryExitReport = Loadable(lazy(() => import('../menu-items/Inventory/MelaEntryExitReport')))
const MelaReport = Loadable(lazy(() => import('../menu-items/Inventory/MelaReport')))
const FreeTrialOrders = Loadable(lazy(() => import('../menu-items/Inventory/AuditOrders/FreeTrialOrders')))
const AllOrdersWithConcern = Loadable(lazy(() => import('../menu-items/Inventory/AuditOrders/AllOrdersWithConcern')))
const BrandsLimitAndDiscount = Loadable(lazy(() => import('../menu-items/Inventory/AuditOrders/BrandsLimitAndDiscount')))
const AutoCancelledOrders = Loadable(lazy(() => import('../menu-items/Inventory/AuditOrders/AutoCancelledOrders')))
const OrdersWithoutAddress = Loadable(lazy(() => import('../menu-items/Inventory/AuditOrders/OrdersWithoutAddress')))
const BlockedAgents = Loadable(lazy(() => import('../menu-items/Inventory/AuditOrders/BlockedAgents')))
//ops

const Campaign = Loadable(lazy(() => import("../menu-items/ops/CampaignCreation")));
const CreateMonthlyIncentive = Loadable(lazy(() => import("../menu-items/ops/CreateMonthlyIncentive")));
const AssignLead = Loadable(lazy(() => import("../menu-items/ops/AssignLead")));
const ReturnInvoice = Loadable(lazy(() => import("../menu-items/ops/ReturnInvoice")));


const downloadOrder = Loadable(
  lazy(() => import("../menu-items/FinancialReports/DownloadOrder"))
);
const productWiseReport = Loadable(
  lazy(() => import("../menu-items/FinancialReports/ProductWiseReport"))
);
const productWiseReportV2 = Loadable(
  lazy(() => import("../menu-items/FinancialReports/ProductWiseReportV2"))
);
const BrandWiseReport = Loadable(
  lazy(() => import("../menu-items/FinancialReports/BrandWiseReport"))
);
const OrderDivisionReport = Loadable(
  lazy(() => import("../menu-items/FinancialReports/OrderDivisionReport"))
);
const OrderByCashback = Loadable(
  lazy(() => import("../menu-items/FinancialReports/OrderByCashback"))
);
const ReturnPickupReport = Loadable(
  lazy(() => import("../menu-items/FinancialReports/ReturnPickupReport"))
);
// sales
const SaleDashboard = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/SaleDashboard")))
const PerformanceManagement = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/PerformanceManagement")))
const SaleRefrealReport = Loadable(lazy(() => import("../menu-items/Dashboard/SaleRefrealReport")))
const SewiseBrandPurchaseReport = Loadable(lazy(() => import("../menu-items/Dashboard/SewiseBrandPurchaseReport")))
const AddSalesLeads = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/AddSalesLeads")))
const LeadsList = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/LeadsList")))
const LeadsDialer = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/Dialer")))
const LeadsBrodcast = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/LeadsBrodcast")))
const TagOrderToAgent = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/TagOrderToAgent")))
//const UploadLeads = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/UploadLeads")))
const AllLeads = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/AllLeads")))
const UploadLeadsTemp = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/UploadLeadsTemp")))
const DialerDashboard = Loadable(lazy(() => import("../menu-items/Dashboard/AutoDailer/DialerDashboard")))
const EligibleOrderSR = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/EligibleOrderSR")))
const EligibleOrderSplit = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/EligibleOrderSplit")))
const GetOtp = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/getOtp")))
const SplitDetails = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/SplitDetails")))
const Incentive = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/Incentive")))
const OrdersToBeCancelled = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/OrdersToBeCancelled")))
const SalesCallReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/SalesCallReport")))
const AgentCallReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentCallReport")))
const AgentPerformanceReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentLeadPerformance")))
const LeadEntry = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/LeadEntry")))
const LeadEntryReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/LeadEntryReport")))
const TicketReportSales = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentWiseTicketReport")))
const CallerSalesReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/CallerSalesReport")))
const OfflineSellerReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/OfflineAgentReport")))
const OfflineSellerReportNew = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/OfflineAgentReportNew")))
const OfflineAgentWise = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/OfflineAgentWise")))
const OfflineAgentWiseSellers = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/OfflineAgentWiseSellers")))
const AgentMappingReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentMapping")))
const AgentBrandReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/BrandWiseRevenueReport")))
const AgentSalesReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentWiseSalesReport")))
const AgentWiseDownloads = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentWiseDownloads")))
const AgentWiseRtoReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentWiseRtoReport")))
const AgentwiseKeyWestCocoWalkReport = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/AgentwiseKeyWestCocoWalkReport")))

const AgentSalesDetailReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentSalesDetails")))
const AgentTicketDetailReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentTicketDetails")))
const CityStateWiseSalesReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/StateWiseSalesReport")))

const DistributorEligibleOrders = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/DistributorEligibleOrders")))
const SalesStateWiseDetails = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/StateWiseDetails")))
const SalesMonthlyIncentive = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/MonthlyIncentive")))
const MonthWisePerformence = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/MonthWisePerformence")))
const DistrictWisePerformence = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/DistrictWisePerformence")))
const AgentWiseMostSoldPincodes = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentWiseMostSoldPincodes")))
const SellerTeamLeadConverstion = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/SellerTeamLeadConverstion")))
const AgentWisePerformence = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/AgentWisePerformence")))
const SellerWisePerformence = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/SellerWisePerformence")))
const BulkAgentReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/BulkAgentReport")))
const CampaignDetail = Loadable(lazy(() => import("../menu-items/Dashboard/SaleDashboard/CampaignDetails")))
const SalesBreakupReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/SalesBreakup")))
const AgentAttendance = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentAttendance")))
const PerformanceManagementReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/PerformanceManagementReport")))
const SalesProbability = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/SalesProbability")))
const SellerWeeklyCohort = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/SellerWeeklyCohort")))
const SellerWiseSales = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/SellerWiseSales")))
const AgentWiseMonthlyIncentiveReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentWiseMonthlyIncentiveReport")))
const SpotIncentiveReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/SpotIncentiveReport")))
const CallHistoryByAgent = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/CallHistoryByAgent")))
const AgentWiseCoinAndCoupon = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/AgentWiseCoinAndCoupon")))
const FieldAgentReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/FieldAgentReport")))
const StateWiseLeadsReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/StateWiseLeadsReport")))
const ClusterWiseLeadsReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/ClusterWiseLeadsReport")))
const DistrictWiseLeadsReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/DistrictWiseLeadsReport")))
const TehsilWiseLeadsReport = Loadable(lazy(() => import("../menu-items/Dashboard/Sales Reports/TehsilWiseLeadsReport")))
const CreateOrder = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/CreateOrder")))
const PreOrder = Loadable(lazy(() => import("../menu-items/Dashboard/Leads/PreOrder")))

// coupon related routes
//const CouponReport = Loadable(lazy(() => import("../menu-items/Coupon/CouponReport")))
const FreeProductPendingApproval = Loadable(lazy(() => import("../menu-items/Coupon/FreeProductPendingApproval")))
const GiftReport = Loadable(lazy(() => import("../menu-items/Coupon/DownloadGiftReport")))
// const DealReport = Loadable(lazy(() => import("../menu-items/Coupon/AddDeal")));
// const DealProduct = Loadable(lazy(() => import("../menu-items/Coupon/DealProducts")));
const HomeBanner = Loadable(lazy(() => import("../menu-items/Marketing Reports/HomeBanners")));
const CreateDeals = Loadable(lazy(() => import("../menu-items/Marketing Reports/CreateDeals")));
const AllDeals = Loadable(lazy(() => import("../menu-items/Marketing Reports/AllDeals")));
//Marketing Reports routes
const CouponReport = Loadable(lazy(() => import("../menu-items/Marketing Reports/CouponReport")))
const organicInorganicReport = Loadable(lazy(() => import("../menu-items/Marketing Reports/OrganicInOrganicOrder")));
const MembershipReportMarketing = Loadable(lazy(() => import("../menu-items/Marketing Reports/MembershipReport")));
const DealsReport = Loadable(lazy(() => import("../menu-items/Marketing Reports/DealsReport")));
const FreeTrialReport = Loadable(lazy(() => import("../menu-items/Marketing Reports/FreeTrialReport")));
const ColorNotification = Loadable(lazy(() => import("../menu-items/Marketing Reports/ColorNotification")));
const TestimonialsBanners = Loadable(lazy(() => import("../menu-items/Marketing Reports/TestimonialsBanners")));
const WhatsappBanners = Loadable(lazy(() => import("../menu-items/Marketing Reports/WhatsappBanners")));
const SpecialDealSection = Loadable(lazy(() => import("../menu-items/Marketing Reports/SpecialDealSection")));
const OrganicCohortReport = Loadable(lazy(() => import("../menu-items/Marketing Reports/OrganicCohortReport")));
const LightningSaleProducts = Loadable(lazy(() => import("../menu-items/Marketing Reports/LightningSaleProducts")));
const SurveyDashboard = Loadable(lazy(() => import("../menu-items/Marketing Reports/SurveyDashboard")));
const TopThreeProducts = Loadable(lazy(() => import("../menu-items/Marketing Reports/TopThreeProducts")));
const FreeTrailProducts = Loadable(lazy(() => import("../menu-items/Marketing Reports/FreeTrailProducts")));
const SubscriptionReport = Loadable(lazy(() => import("../menu-items/Marketing Reports/SubscriptionReport")));
const WhatsappMarketing = Loadable(lazy(() => import("../menu-items/Marketing Reports/WhatsappMarketing")));
const SmsTemplates = Loadable(lazy(() => import("../menu-items/Marketing Reports/SmsTemplates")));
const SmsTemplatesByCategory = Loadable(lazy(() => import("../menu-items/Marketing Reports/SmsTemplatesByCategory")));
const TemplatesImages = Loadable(lazy(() => import("../menu-items/Marketing Reports/TemplatesImages")));
// warehouse
const PendingOrders = Loadable(
  lazy(() => import("../menu-items/Vendor/PendingOrders"))
);
const TodaysProductWiseOrder = Loadable(
  lazy(() => import("../menu-items/Vendor/TodayProductWiseOrder"))
);
const AssignFcCenter = Loadable(
  lazy(() => import("../menu-items/Vendor/AssignFcCenter"))
);
const AssignOrderFromFlorida = Loadable(
  lazy(() => import("../menu-items/Vendor/AssignOrderFromFlorida"))
);
const RemoveOrderOfFC = Loadable(
  lazy(() => import("../menu-items/Vendor/RemoveOrders"))
);
const UploadBulkOrder = Loadable(
  lazy(() => import("../menu-items/Product/SupplyChain/UploadBulkOrder"))
);
const DispatchOrderOfFC = Loadable(
  lazy(() => import("../menu-items/Vendor/DispatchOrder"))
);
const VendorInventory = Loadable(
  lazy(() => import("../menu-items/Vendor/Inventory"))
);
const InventoryDetails = Loadable(
  lazy(() => import("../menu-items/Vendor/InventoryDetails"))
);
const VendorComplaints = Loadable(
  lazy(() => import("../menu-items/Vendor/Complaints"))
);
const UsersComplaints = Loadable(
  lazy(() => import("../menu-items/Vendor/UsersComplaints"))
);
const AllRiders = Loadable(
  lazy(() => import("../menu-items/Vendor/AllRiders"))
);
const CashCollection = Loadable(
  lazy(() => import("../menu-items/Vendor/CashCollection"))
);
const RidersSettlement = Loadable(
  lazy(() => import("../menu-items/Vendor/RidersSettlement"))
);
const PlaceOrder = Loadable(
  lazy(() => import("../menu-items/Vendor/PlaceOrder"))
);
const AddIncomingOrders = Loadable(
  lazy(() => import("../menu-items/Vendor/AddIncomingOrders"))
);
const PurchaseOrders = Loadable(
  lazy(() => import("../menu-items/Vendor/PurchaseOrders"))
);
const Settlement = Loadable(
  lazy(() => import("../menu-items/Vendor/Settlement"))
);

const SettlementReport = Loadable(
  lazy(() => import("../menu-items/Vendor/SettlementReport"))
);
const ProductWiseReportForFc = Loadable(
  lazy(() => import("../menu-items/Vendor/ProductWiseReportForFc"))
);

const SettlementDetails = Loadable(
  lazy(() => import("../menu-items/Vendor/SettlementDetails"))
);
const VendorCart = Loadable(
  lazy(() => import("../menu-items/Vendor/VendorCart"))
);
const AssignOrders = Loadable(
  lazy(() => import("../menu-items/Vendor/AssignOrders"))
);
const CancelledOrders = Loadable(
  lazy(() => import("../menu-items/Vendor/CancelledOrders"))
);
const RtoOrders = Loadable(
  lazy(() => import("../menu-items/Vendor/RtoOrders"))
);
const InTransitOrders = Loadable(
  lazy(() => import("../menu-items/Vendor/InTransitOrders"))
);
const VendorOrderDetails = Loadable(
  lazy(() => import("../menu-items/Vendor/VendorOrderDetails"))
);

//fcAdmin
const FcComplaints = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcComplaints"))
);

const FcUsersComplaints = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcUsersComplaints"))
);

const FcPurchaseOrders = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcPurchaseOrders"))
);

const FcWiseOrdersList = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcWiseOrdersList"))
);
const FloridaOrdersList = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FloridaOrderReport"))
);

const FcWiseSettlements = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcWiseSettlements"))
);

const FcWiseInventory = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcWiseInventory"))
);

const PlaceOrderForFc = Loadable(
  lazy(() => import("../menu-items/FcAdmin/PlaceOrderForFc"))
);

const CreateNewFc = Loadable(
  lazy(() => import("../menu-items/FcAdmin/CreateNewFc"))
);

const FcList = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcList"))
);
const AmountCollectionfc = Loadable(
  lazy(() => import("../menu-items/FcAdmin/AmountCollectionFC"))
);
const OrderForecastFc = Loadable(
  lazy(() => import("../menu-items/FcAdmin/OrderForecast"))
);
const DispatchOrdersFc = Loadable(
  lazy(() => import("../menu-items/FcAdmin/DispatchFcOrders"))
);
const FCGroupOrdersList = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FCGroupOrdersList"))
);

const FcSettlementReport = Loadable(
  lazy(() => import("../menu-items/FcAdmin/FcSettlementReport"))
);
const TotalOrdersAmount = Loadable(
  lazy(() => import("../menu-items/FcAdmin/TotalOrdersAmount"))
);

const OrderWiseSettlementReport = Loadable(
  lazy(() => import("../menu-items/FcAdmin/OrderWiseSettlementReport"))
);

/* Flordia Dashboard */

const FloridaDashboard = Loadable(
  lazy(() => import('../menu-items/Vendor/FloridaDashboard/Dashboard'))
);
const CreateQr = Loadable(
  lazy(() => import('../menu-items/Vendor/FloridaDashboard/CreateQRCode'))
);

const PackerList = Loadable(
  lazy(() => import('../menu-items/Vendor/FloridaDashboard/PackerList'))
);

const PackerOrderList = Loadable(
  lazy(() => import('../menu-items/Vendor/FloridaDashboard/PackerOrderList'))
);
const OrderPack = Loadable(
  lazy(() => import('../menu-items/PackingOrder/Packing'))
);

// livetrenings

const LiveVideoTreningsScreen = Loadable(
  lazy(() => import("../menu-items/Zoom/LiveVideoTreningsScreen"))
);
const AddCourse = Loadable(lazy(() => import("../menu-items/Zoom/AddCourse")));
const TrainerReport = Loadable(lazy(() => import("../menu-items/Zoom/TrainerReport")));
const TrainerReportClassWise = Loadable(lazy(() => import("../menu-items/Zoom/ClassesCountTrainerWise")));

const Chapters = Loadable(lazy(() => import("../menu-items/Zoom/Chapters")));
const Classes = Loadable(lazy(() => import("../menu-items/Zoom/Classes")));
const Quiz = Loadable(lazy(() => import("../menu-items/Zoom/Quiz")));
const SearchBusinessName = Loadable(lazy(() => import("../menu-items/Seller/SearchBusinessName")));
const SellerProfileReport = Loadable(lazy(() => import("../menu-items/Seller/SellerProfileReport")));
const ManageTVBanners = Loadable(lazy(() => import("../menu-items/Marketing Reports/ManageTVBanners")));

// ONDC IMPORTS

const OndcSellerList = Loadable(lazy(() => import("../menu-items/ONDC/Sellers/index")));

//-----------------------|| MAIN ROUTING ||-----------------------//
var role = getDecryptDataFromLocal("setRole");
var phoneNumber = getDecryptDataFromLocal("phoneNumber");

const MainRoutes = () => {
  const location = useLocation();

  const getComponent = () => {
    if (role == 5) {
      return SaleDashboard;
    } else if (role == 4) {
      return SupplyDashboard
    } else if (role == 8) {
      return PendingOrders
    }
    else if (phoneNumber == "FLORIDA") {
      return FloridaDashboard
    } else if (role == 19) {
      return OrderPack
    }
    else {
      return MainDashboard
    }
  }

  return (
    <Route
      path={[
        "/dashboard/default",
        "/dashboard/analytics",
        //
        "/sample-page",
        "/orderDetails/:OrderId/:isReplacement",
        "/sellerlist",
        "/giftsellercoupon",
        "/seller-address-list",
        "/sellerprofile/:_id",
        "/statusoneorders",
        "/allcustomer",
        "/OrderList",
        '/audit-orders',
        "/sellerproductlist",
        "/productforcast",
        "/multiply-order",
        "/productprofile/:productID",
        "/customerorderlist",
        "/allseller/sellerpayouts",
        "/productconcern",
        "/livetraining",
        "/createCertificate",
        '/appointmentlist',
        "/appointmentdetails/:appointmentId",
        "/livetraining/trainingDetails/:meetingId",
        "/addproduct",
        "/all-combopacks",
        "/combopack",
        "/add-shades-and-size",
        "/create-replacement-order",
        "/editcombopack/:id",
        "/add-reward",
        "/coins-report",
        "/split-orders",
        "/brandwise-product-data",
        "/payment-method-changes-history",
        "/rto-orders-after-payment",
        "/return-pickup-order",
        "/mark-bulk-order",
        "/OfflineOrderList",
        '/group-orders',
        "/RupifiOrderList",
        "/AddressProblemList",
        "/PriorityOrders",
        "/PrepaidCancelledOrders",
        "/DamageProducts",
        "/rocketboxOrders",
        "/return-pickup-orders",
        "/return-pickup-orders-status/:id",
        "/redispatch-orders",
        "/auto-refunded-prepaid-orders",
        "/on-hold-orders",
        //"/allseller/sellerapprovelist",
        "/allseller/sellerbillapprovelist",
        "/allseller/sellerbillapprovelist/approvebill",
        "/purchaseorders",
        "/seller/customercartproduct",
        "/uploadinventory",
        "/upload-forcast-inventory",
        '/uploadrtofile',
        "/seller/OfflineSellers",
        "/seller/OrganicSellers",
        "/seller/sellerMembership",
        '/outofstockproducts',
        '/fivedaysoldOrders',
        '/sellercrmorders',
        //
        "/seller/billsupload",
        "/search-business-name",
        "/seller-profile-report",
        // admin
        "/admin/adminregister",
        "/admin/productconcern",
        "/admin/editcategory",
        "/admin/editleafcategory",
        "/admin/createcoupon",
        "/admin/notification",
        "/admin/create-new-salon",
        "/admin/dev-uploads",
        '/fcadmin/admin-fc-grn',
        '/admin/add-survey-questions',
        '/admin/salons-whatsapp-config',
        '/admin/agen-tikona-config',
        '/onlineorderabove20k',
        '/offlineUnverifiedOrder',
        '/onlinebulklineUnverifiedOrder',
        '/organicUnverifiedOrder',
        /* Main Dashboard */


        "/dashboard/default",

        // reports
        "/reports/downloadreports",
        "/reports/DownloadDateCohort",
        "/reports/OrderDateCohort",
        "/reports/OrderDateCohortRto",
        "/reports/OrderDateCohortSouth",
        "/reports/OrderDateCohortRtoSouth",
        "/reports/notavailableproductsreport",
        "/reports/sellerwithfloridaorders",
        "/reports/mapwisereport",
        "/reports/buyumedetails",
        "/reports/mostsellingproduct",
        "/reports/mostsellingbrands",
        "/reports/productorderdetails",
        "/reports/referreport",
        "/reports/BrandWiseRetailReport",
        "/reports/combo-report",
        "/reports/CacReport",
        "/reports/ProductDiscountWiseReport",
        "/reports/MembershipReport",
        "/reports/expiringRefsReport",
        "/bulkorderdetail",
        "/reports/OrderDateOfflineCohort",
        "/reports/OrderDateOfflineCohortRto",
        "/reports/OrderDateBulkCohort",
        // "/reports/CallerwiseSalesReport",
        "/reports/splitOrderReport",
        "/reports/forcast-inventoryreport",
        "/reports/ProductWiseAvailabilityReport",
        "/reports/product-availability-time",
        '/createvendor',
        '/poprocess',
        "/po-report",
        "/po-sku-list",
        "/po-history/:id/:brandName",
        '/purchase-order-detail/:OrderId',
        '/inventory-ledger',
        '/reports/sellergst',
        // '/reports/offlineAgentReport',
        '/reports/sales-call-report',
        '/reports/rto-report/:orders',
        '/reports/rto-report/:orders/:referId',
        '/slow-moving-quantity',
        '/reports/month-rto',
        '/reports/brandOrderPerPerson',
        // "/reports/organicInorganicReport",
        "/reports/organicInorganicRTOReport",
        // "/reports/agentOrderReport",
        '/reports/agentDealOrder',
        "/reports/sellerWiseRTOReport",
        "/offlineAgent/:referCode/:startDate/:endDate",
        "/reports/distributorReport",
        "/reports/BuyumeOrderWiseReport",
        "/reports/floridaproductpurchasebyseller",
        // "/reports/ticketReport",
        '/ops/return-invoice',

        //Supply Chain
        "/supplychain/dashboard",
        "/supplychain/orders-with-issues",
        "/supplychain/rto-with-less-attempts",
        "/supplychain/in-transit-gt10-days",
        "/supplychain/delivered-order-feedback",
        "/supplychain/ship-order-through-excel",
        "/supplychain/upload-bulk-order",
        "/supplychain/createpaymentlink",

        //prioritze order
        '/priortize-order/priortize-florida-order',
        // admin reports
        "/admin/reports/downloadreports",
        "/admin/reports/OrderDateCohort",
        "/admin/reports/order-date-cohort-rto",
        "/admin/reports/cohortreport",
        "/admin/reports/notavailableproductsreport",
        "/admin/reports/buyumedetails",
        "/admin/reports/mostsellingproduct",
        "/admin/reports/mostsellingbrands",
        "/admin/reports/productorderdetails",
        "/admin/reports/referreport",
        "/admin/reports/SellerWiseRevenu",
        "/admin/reports/CategoryWiseReport",
        "/admin/reports/StateWiseSalesReport",
        "/admin/reports/BrandCohortReport",
        "/admin/reports/SellerRtoReports",
        '/admin/reports/buyumedetails2',
        '/financialreport/ReconcileReport',
        '/admin/reports/referalAmount',
        '/admin/reports/SellerCoinReport',
        '/admin/reports/BuyumeReportOrderWise',
        "/admin/reports/shippedOrders",
        "/admin/sellerRegister",

        //ops

        '/ops/create-campaign',
        '/ops/create-monthly-incentive',
        '/ops/uploadnewleads',

        // inventory
        "/inventory/dailer",
        "/preOrderList",
        "/downloadorderdata",
        "/inventory/agentWiseCallReport",
        "/inventory/allTickets",
        "/inventory/uploadNDR",
        "/inventory/myTickets",
        "/inventory/audit-ticket-cleared-by-agents",
        "/inventory/cancelledOrderTickets",
        "/inventory/preAuditTickets",
        "/inventory/TicketDetailedReport",
        "/inventory/audit-agent-report",
        "/inventory/mela-orders",
        "/inventory/create-mela/:id",
        "/inventory/create-mela/",
        "/inventory/mela-entry-exit",
        "/inventory/all-mela",
        "/inventory/mela-banners",
        "/inventory/mela-scans-report",
        "/inventory/mela-report",
        "/inventory/agentWiseOrderReport",
        "/inventory/FreeTrialOrders",
        "/inventory/all-orders-with-concern",
        "/inventory/brands-limit-and-discount",
        "/inventory/auto-cancelled-orders",
        "/inventory/orders-without-address",
        "/inventory/blocked-agents",
        // '/fulfilledorders',
        "/fulfilledorders",
        "/addbrand",
        // finiancial report
        "/financialreport/downloadorders",
        "/financialreport/productWiseReport",
        "/financialreport/productWiseReportV2",
        "/financialreport/brandWiseReport",
        "/financialreport/cash-intransit",
        "/financialreport/order-by-cashback-created",
        "/financialreport/return-pickup-report",
        "/financialreport/newproductWiseReport",

        // sales dashbord
        "/sales/orderTagToAgent",
        "/sales/dashboard",
        "/sales/performanceManagement",
        "/sales/sereferencereport",
        "/sales/sewiseBrandPurchaseReport",
        "/sales/addsaleslead",
        "/sales/leadEntry",
        "/sales/leadDistribution",
        "/sales/leadslist",
        "/sales/dialer",
        "/sales/CreateOrder",
        "/sales/PreOrder",
        "/sales/crmorders",
        "/sales/blockpincode",
        "/sales/leads-brodcast",
        '/sales/updatelead/:leadId',
        '/sales/uploadAgentTarget',
        // "/sales/uploadlead",
        "/sales/allleads",
        "/sales/eligible-orders-sr",
        "/sales/eligible-orders-split",
        "/sales/get-otp",
        "/splitDetails/:OrderId",
        '/sales/UploadLeadsTemp',
        '/dialer/dashboard',
        "/sales/incentive",
        "/salesReport/orders-to-be-cancelled",
        "/salesReport/salescallreport",
        "/salesReport/agentcallreport",
        "/salesReport/agentperformancereport",
        "/salesReport/leadDataEntryreport",
        "/salesReport/TicketReport",
        "/salesReport/CallerSalesReport",
        "/salesReport/offlineAgentReport",
        "/salesReport/offlineAgentReportNew",
        "/salesReport/offlineAgentWise/:state/:city/:date",
        "/salesReport/offlineAgentWiseSellers/:refferalCode",
        "/salesReport/AgentMapping",
        "/salesReport/BrandWiseRevenueReport",
        "/salesReport/AgentSalesReport",
        "/salesReport/AgentDownloadReport/:referralCode/:startDate/:endDate",
        "/salesReport/AgentRtoReport/:agentId/:startDate/:endDate",
        "/agentDetails/:referralCode/:start/:end",
        "/agentTicket/:agentId/:startDate/:endDate",
        "/sales/distributorEligibleOrders",
        "/salesReport/stateWiseSalesReport",
        "/statewiseSales/:state/:startDate/:endDate",
        "/sales/monthlyIncentive",
        "/sales/month-wise-performence",
        "/sales/district-wise-performence",
        "/sales/agent-wise-most-sold-pincodes",
        "/sales/seller-team-lead-converstion",
        "/sales/agent-wise-performence/:brandId/:district/:refferdByAgenttype/:month/:year",
        "/sales/seller-wise-performence/:brandId/:district/:agentId/:refferdByAgenttype/:month/:year",
        "/sales/bulk-agent-report",
        "/salesReport/breakUpSalesReport",
        "/salesReport/agentAttendance",
        "/salesReport/performanceManagementReport",
        "/salesReport/salesProbablity",
        "/seller-weekly-cohort",
        "/seller-wise-sales",
        "/agent-wise-monthly-incentive-report",
        "/spot-incentive-report",
        "/callhistorybyagent",
        "/agent-wise-coin-coupon-report",
        "/field-agent-report",
        "/statewise-leads-report",
        "/clusterwise-leads-report/:state",
        "/districtwise-leads-report/:state/:cluster",
        "/tehsil-leads-report/:state/:cluster/:district",
        '/agent-wise-keywest-cocowalk-report',
        '/sales/alldownloads',
        // vendor
        "/vendor/pendingorders",
        "/vendor/ship-product-detail",
        "/vendor/assignfc",
        "/vendor/assignorders",
        "/vendor/assign-order-from-florida",
        "/vendor/remove-order-of-fc",
        "/vendor/dispatch-order-of-fc",
        "/vendor/cancelled-orders",
        "/vendor/rto-orders",
        "/vendor/intransit-orders",
        "/vendororderdetails/:OrderId",
        "/vendor/complaints",
        "/vendor/users-complaints",

        "/vendor/inventory",
        "/vendor/inventorydetails/:productId/:startDate/:endDate",
        "/vendor/inventorydetails/:productId/:startDate/:endDate/:fcIdFromAdmin",
        "/vendor/fc-grn",

        //florida dashboard

        "/florida/dashboard",
        "/florida/create-qr",
        "/florida/packer-list",
        "/florida/packer-order-list",
        "/florida/packer-non-crm-order-list",

        // packing order

        "/packing/order",
        "/packing/nonCrmorder",

        //training
        "/vendor/all-riders",
        "/vendor/cash-collection",
        "/vendor/riders-settlement",
        "/vendor/place-order",
        "/vendor/add-incoming-orders",
        "/vendor/purchase-orders",
        "/vendor/settlement",
        "/vendor/settlement-report",
        "/vendor/fcProductWiseReport",
        "/vendor/settlement-details/:id",
        "/vendor/cart",
        "/vendor/order-wise-settlement-report",
        

        "/fcadmin/fc-complaints",
        "/fcadmin/fc-users-complaints",
        "/fcadmin/fc-purchase-orders",
        "/fcadmin/fc-wise-orders-list",
        "/fcadmin/florida-orders-list",
        "/fcadmin/fc-group-orders-list",
        "/fcadmin/fc-wise-settlements",
        "/fcadmin/fc-wise-Inventory",
        "/fcadmin/place-order-for-fc",
        "/fcadmin/create-new-fc",
        "/fcadmin/fc-list",
        "/fcadmin/amount-collection-fc",
        "/fcadmin/dispatch-orders",
        "/fcadmin/settlement-report",
        "/fcadmin/total-orders-amount",
        "/fcadmin/order-wise-settlement-report",
        "/fcadmin/order-forecast",
        "/addtrainer",
        "/addcourse",
        "/trainerReport",
        "/trainerreportclasswise",
        "/slot-booking",
        "/detailed-slot-data",
        "/all-meetings",
        "/shorts-uploading",
        "/shorts-reports",
        "/live-training-recordings",
        "/live-page-banner",
        "/readymade-services-list",
        "/hair-scalp-skin-analysis-report",
        "/mela-creation",
        "/superstar-report",
        "/watch-time-report",



        "/create-course-link",
        "/chapters/:courseId",
        "/classes/:chapterId",
        "/chapter/quiz/:chapterId",
        '/reports/offlineAgentReport',
        "/livevideoscreen/:roomId/:isQuiz/:msRoomId/:isSlotMeeting",

        "/campaignDetails/:_id",

        // coupon related routes
        // '/coupon/coupon-report',
        "/coupon/free-product-pending-approval",
        '/coupon/gift-report',
        // '/coupon/deal-report',
        // '/deals/dealProducts/:id',

        //Marketing Reports
        "/marketing/coupons-report",
        "/marketing/organic-inorganic-report",
        "/marketing/membership-report",
        "/marketing/deals-report",
        "/marketing/free-trial-report",
        "/marketing/color-notification",
        '/coupon/updateBanners',
        "/coupon/create-deals",
        "/coupon/create-deals/:id",
        "/coupon/all-deals",
        "/marketing/testimonials-banners/",
        "/marketing/whatsapp-banners/",
        "/marketing/special-deal-section",
        "/marketing/organic-cohort-report",
        "/marketing/lightning-sale-products",
        "/marketing/survey-dashboard",
        "/marketing/top-three-products",
        "/marketing/free-trail-products",
        "/marketing/subscription-report",
        "/marketing/whatsapp-marketing",
        "/marketing/sms-templates",
        "/marketing/sms-templates/:catId",
        "/marketing/templates-images/:id",
        "/marketing/manage-tv-banners",
        // Bulk
        "/bulk/ordercreate",
        "/bulk/addbulkparent",
        '/bulk/cashordercreate',
        '/bulk/bulkorderlist',
        "/bulkorderdetails/:OrderId",
        '/bulk/bulkshipstatus',
        '/bulk/moveBulkOrder',
        '/bulk/bulkorderapprovalforscm',
        '/editBulkOrderScm/:OrderId/:canApprove/:canDispatch',
        '/bulk/uploadbulkmargin',
        '/bulk/bulkorderdownload',
        '/bulk/bulkproductdownload',
        '/bulk/updatezohocustomerid',
        "/bulk/ordermovebulktoproduct",

        // 
        '/bulk/searchorders',
        '/bulk/reachedatdestinationorder',
        '/bulk/processingorders',
        '/bulk/bulkbrandwisepurchasereport',
        '/bulk/moveorderstage',
        '/bulk/stagewisebulkorders',
        "/stagebulkorderdetails/:OrderId",
        
        
        
        //ONDC
        '/ondc/sellerlist',
        '/ondc/sellerdetails/:_id/:sellerId',
        '/ondc/orderdetails/:orderId/'
      ]}

    >
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            {/* {
              checkRouteRole()
            } */}

            <Route path="/dashboard/analytics" component={role != 5 ? DashboardAnalytics : SaleDashboard} />
            {/* Main Dashboard */}

            <Route exact path="/dashboard/default" component={getComponent()} />
            {/* Crm route */}
            {/* orders */}
            <Route exact path="/OrderList" component={OrderList} />
            <Route exact path='/audit-orders' component={AuditOrders} />
            <Route exact path="/orderDetails/:OrderId/:isReplacement" component={orderDetails} />
            <Route exact path="/statusoneorders" component={StatusOneOrders} />
            <Route exact path="/OfflineOrderList" component={OfflineSellerOrderList} />
            <Route exact path="/group-orders" component={GroupOrders} />
            <Route exact path="/RupifiOrderList" component={RupifiOrderReport} />

            <Route exact path="/AddressProblemList" component={AddressProblem} />
            <Route exact path="/PriorityOrders" component={PriorityOrders} />
            <Route exact path="/PrepaidCancelledOrders" component={PrepaidCancelledOrders} />
            <Route exact path="/DamageProducts" component={DamageOrderList} />
            <Route exact path="/rocketboxOrders" component={RocketBoxOrders} />
            <Route exact path="/return-pickup-orders" component={ReturnPickupOrders} />
            <Route exact path="/return-pickup-orders-status/:id" component={ReturnPickupOrdersStatus} />
            <Route exact path="/redispatch-orders" component={ReDispatchOrders} />
            <Route exact path="/auto-refunded-prepaid-orders" component={AutoRefundedPrepaidOrders} />
            <Route exact path="/on-hold-orders" component={OnHoldOrders} />
            <Route exact path="/onlineorderabove20k" component={OnlineOrderAbove20K} />
            <Route exact path="/offlineUnverifiedOrder" component={OfflineUnverifiedOrder} />
            <Route exact path="/onlinebulklineUnverifiedOrder" component={OnlineBulkUnverifiedOrder} />
            <Route exact path="/organicUnverifiedOrder" component={OrganicVarifyOrders} />
            <Route exact path="/fivedaysoldOrders" component={FiveDaysOldOrderReassign} />
            <Route exact path="/sellercrmorders" component={SellerCrmOrdersList} />

            {/* <Route exact path="/allseller/sellerapprovelist" component={SellerApproveList} /> */}
            <Route exact path="/allseller/sellerbillapprovelist" component={SellerBillApproveList} />
            <Route exact path="/allseller/sellerbillapprovelist/approvebill/:id" component={BillApprovePage} />
            <Route exact path="/sellerlist" component={SellerList} />
            <Route exact path="/giftsellercoupon" component={GiftSellerCoupon} />
            <Route exact path="/seller-address-list" component={SellerAddressList} />
            <Route exact path="/sellerprofile/:_id" component={SellerProfile} />
            <Route exact path="/allcustomer" component={CustomerList} />
            <Route exact path="/sellerproductlist" component={SellerProductList} />
            <Route exact path="/productforcast" component={ProductForecast} />
            <Route exact path="/multiply-order" component={MultiplyOrder} />
            <Route exact path="/productprofile/:productID" component={productProfile} />
            <Route exact path="/addproduct" component={productProfile} />
            <Route exact path="/all-combopacks" component={allComboPack} />
            <Route exact path="/combopack" component={comboPack} />
            <Route exact path="/editcombopack/:id" component={comboPack} />
            <Route exact path="/add-shades-and-size" component={AddShadesAndSize} />
            <Route exact path="/create-replacement-order" component={CreateReplacementOrder} />
            <Route exact path="/uploadinventory" component={UploadInventory} />
            <Route exact path="/upload-forcast-inventory" component={UploadForcastInventory} />
            <Route exact path="/customerorderlist" component={CustomerOrderList} />
            <Route exact path="/allseller/sellerpayouts" component={Payouts} />
            <Route exact path="/uploadrtofile" component={UploadRTOReport} />
            <Route exact path="/seller/OfflineSellers" component={OfflineSeller} />
            <Route exact path="/seller/OrganicSellers" component={OrganicSellers} />
            <Route exact path='/seller/sellerMembership' component={SellerMemberShip} />
            <Route exact path='/search-business-name' component={SearchBusinessName} />
            <Route exact path="/seller-profile-report" component={SellerProfileReport} />



            {/* Admin */}
            <Route exact path="/admin/adminregister" component={Admin} />
            <Route exact path="/admin/productconcern" component={productConcern} />
            <Route exact path="/admin/editcategory" component={EditCategory} />
            <Route exact path="/admin/editleafcategory" component={EditLeafCategory} />
            <Route exact path="/admin/notification" component={Notification} />
            <Route exact path="/admin/create-new-salon" component={CreateSalon} />
            <Route exact path='/admin/add-survey-questions' component={AddSurveyQuestions} />
            <Route exact path='/admin/agen-tikona-config' component={AgentTikonaConfig} />
            <Route exact path='/admin/salons-whatsapp-config' component={SalonsWhatsappConfig} />
            <Route exact path="/admin/dev-uploads" component={DevImgUpload} />
            <Route exact path="/admin/sellerRegister" component={SalonUserRegister} />


            <Route exact path="/purchaseorders" component={Purchase} />
            <Route exact path="/seller/customercartproduct" component={SellerCheckOutProduct} />
            <Route exact path="/admin/createcoupon" component={CreateCoupon} />
            {/* zoom  meetings */}
            <Route exact path="/livetraining" component={Zoom} />
            <Route exact path="/livetraining/trainingDetails/:meetingId" component={TrainingDetails} />
            <Route exact path="/chapters/:courseId" component={Chapters} />
            <Route exact path="/classes/:chapterId" component={Classes} />
            <Route exact path="/chapter/quiz/:chapterId" component={Quiz} />
            <Route exact path="/slot-booking" component={SlotBooking} />
            <Route exact path="/detailed-slot-data" component={DetailedSlotData} />
            <Route exact path="/all-meetings" component={AllMeetings} />
            <Route exact path="/shorts-uploading" component={ShortsUploading} />
            <Route exact path="/shorts-reports" component={ShortsReports} />
            <Route exact path="/live-training-recordings" component={LiveTrainingRecordings} />
            <Route exact path="/live-page-banner" component={LivePageBanner} />
            <Route exact path="/readymade-services-list" component={ReadymadeServicesList} />
            <Route exact path="/hair-scalp-skin-analysis-report" component={AnalysisReport} />
            <Route exact path="/mela-creation" component={MelaCreation} />
            <Route exact path="/create-course-link" component={CreateCourseLink} />
            <Route exact path="/addtrainer" component={AddTrainer} />
            <Route exact path="/addcourse" component={AddCourse} />
            <Route exact path="/trainerReport" component={TrainerReport} />
            <Route exact path="/createCertificate" component={CreateOfflineCertificates} />
            <Route exact path="/appointmentlist" component={AppointmentList} />
            <Route exact path="/appointmentdetails/:appointmentId" component={AppointmentDetails} />

            <Route exact path="/superstar-report" component={SuperstarReport} />
            <Route exact path="/watch-time-report" component={WatchtimeReport} />

            <Route exact path="/trainerReportclasswise" component={TrainerReportClassWise} />
            <Route exact path="/livevideoscreen/:roomId/:isQuiz/:msRoomId/:isSlotMeeting" component={LiveVideoTreningsScreen} />
            {/* reports  */}
            <Route exact path="/reports/mapwisereport" component={MapWiseReport} />
            <Route exact path="/reports/sellerwithfloridaorders" component={SellerWithFloridaOrders} />
            <Route exact path="/reports/buyumedetails" component={BuyumeDetails} />
            <Route exact path="/reports/mostsellingproduct" component={MostSellingProduct} />
            <Route exact path="/reports/mostsellingbrands" component={MostSellingBrands} />
            <Route exact path="/reports/productorderdetails" component={ProductOrderDetails} />
            <Route exact path="/reports/referreport" component={ReferReport} />
            <Route exact path="/reports/notavailableproductsreport" component={NotAvailableProductsReport} />
            <Route exact path="/reports/downloadreports" component={DownloadReports} />
            <Route exact path="/reports/OrderDateCohort" component={OrderDateCohort} />
            <Route exact path="/reports/OrderDateCohortRto" component={OrderDateCohortRto} />
            <Route exact path="/reports/OrderDateCohortSouth" component={OrderDateCohortSouth} />
            <Route exact path="/reports/OrderDateCohortRtoSouth" component={OrderDateCohortRtoSouth} />
            <Route exact path="/reports/DownloadDateCohort" component={DownloadDateCohort} />
            <Route exact path="/reports/BrandWiseRetailReport" component={BrandWiseRetailReport} />
            <Route exact path="/reports/combo-report" component={ComboReport} />
            <Route exact path="/reports/CacReport" component={CacReport} />
            <Route exact path="/reports/ProductDiscountWiseReport" component={ProductDiscountWiseReport} />
            <Route exact path="/reports/MembershipReport" component={MembershipReport} />
            <Route exact path="/reports/expiringRefsReport" component={ExpiringRefsReport} />
            {/* <Route exact path="/reports/CallerwiseSalesReport" component={CallerwiseSalesReport} /> */}
            <Route exact path="/reports/distributorReport" component={DistributorReport} />
            <Route exact path="/reports/offlineAgentReport" component={OfflineSellers} />
            <Route exact path="/offlineAgent/:referCode/:startDate/:endDate" component={OfflineSellerDetailsReport} />
            <Route exact path="/reports/BuyumeOrderWiseReport" component={BuyumeReport} />
            <Route exact path="/reports/floridaproductpurchasebyseller" component={FloridaProductWithSeller} />



            <Route exact path="/reports/OrderDateOfflineCohort" component={OrderDateOffline} />
            <Route exact path="/reports/OrderDateOfflineCohortRto" component={OrderDateOfflineRto} />
            <Route exact path="/reports/OrderDateBulkCohort" component={OrderDateBulk} />


            <Route exact path="/reports/rto-report/:orders" component={MonthRTOReport} />
            <Route exact path="/reports/rto-report/:orders/:referId" component={MonthRTOReport} />
            <Route exact path="/reports/brandOrderPerPerson" component={BrandOrderPerPerson} />
            {/* <Route exact path="/reports/organicInorganicReport" component={organicInorganicReport} /> */}
            <Route exact path="/reports/organicInorganicRTOReport" component={organicInorganicRTOReport} />
            {/* <Route exact path="/reports/agentOrderReport" component={AgentOrderReport} /> */}
            <Route exact path="/reports/agentDealOrder" component={AgentDealOrders} />
            <Route exact path="/reports/sellerWiseRTOReport" component={SellerWiseRTOReport} />
            <Route exact path="/reports/splitOrderReport" component={SplitOrderReport} />
            <Route exact path="/reports/forcast-inventoryreport" component={ForcastInventoryReport} />
            <Route exact path="/reports/ProductWiseAvailabilityReport" component={ProductWiseAvailabilityReport} />
            <Route exact path="/reports/product-availability-time" component={ProductAvailabilityTime} />
            {/* <Route exact path="/reports/ticketReport" component={TicketReport} /> */}
            {/* Admin reports  */}
            <Route exact path="/admin/reports/buyumedetails" component={adminBuyumeDetails} />
            <Route exact path="/admin/reports/buyumedetails2" component={BuyumeDetailsForRole12} />
            <Route exact path="/admin/reports/mostsellingproduct" component={adminMostSellingProduct} />
            <Route exact path="/admin/reports/mostsellingbrands" component={adminMostSellingBrands} />
            <Route exact path="/admin/reports/productorderdetails" component={adminProductOrderDetails} />
            <Route exact path="/admin/reports/referreport" component={adminReferReport} />
            <Route exact path="/admin/reports/notavailableproductsreport" component={adminNotAvailableProductsReport} />
            <Route exact path="/admin/reports/downloadreports" component={adminDownloadReports} />
            <Route exact path="/admin/reports/OrderDateCohort" component={adminDownloadCohortReport} />
            <Route exact path="/admin/reports/order-date-cohort-rto" component={adminCohortReportRto} />
            <Route exact path="/admin/reports/cohortreport" component={adminCohortReport} />
            <Route exact path="/admin/reports/SellerWiseRevenu" component={SellerWiseRevenu} />
            <Route exact path="/admin/reports/CategoryWiseReport" component={CategoryWiseReport} />
            <Route exact path="/admin/reports/StateWiseSalesReport" component={StateWiseSalesReport} />
            <Route exact path="/admin/reports/BrandCohortReport" component={BrandCohortReport} />
            <Route exact path="/admin/reports/SellerRtoReports" component={SellerRtoReport} />
            <Route exact path="/admin/reports/referalAmount" component={ReferalAmount} />
            <Route exact path="/admin/reports/SellerCoinReport" component={SellerCoinReport} />
            <Route exact path="/financialreport/ReconcileReport" component={ReconcileReport} />
            <Route exact path="/reports/sellergst" component={SellerGST} />
            <Route exact path="/admin/reports/BuyumeReportOrderWise" component={BuyumeReportOrderWise} />
            <Route exact path="/admin/reports/shippedOrders" component={ShippedOrderReport} />
            {/* inventory */}
            <Route exact path="/myinventory" component={MyInventory} />
            <Route exact path="/preOrderList" component={PreOrderList} />
            <Route exact path="/addbrand" component={Brand} />
            <Route exact path="/add-reward" component={AddReward} />
            <Route exact path="/coins-report" component={CoinsReport} />
            <Route exact path="/split-orders" component={SplitOrders} />
            <Route exact path="/brandwise-product-data" component={BrandwiseProductData} />
            <Route exact path="/payment-method-changes-history" component={PaymentMethodChangesHistory} />
            <Route exact path="/rto-orders-after-payment" component={RtoOrdersAfterPaymentChange} />
            <Route exact path="/return-pickup-order" component={ReturnPickupOrder} />
            <Route exact path="/mark-bulk-order" component={MarkBulkOrder} />
            <Route exact path="/downloadorderdata" component={DownloadOrdersData} />
            <Route exact path="/bulkorderdetail" component={BulkOrder} />
            <Route exact path="/createvendor" component={VendorCreation} />
            <Route exact path="/poprocess" component={PurchaseOrderProcess} />
            <Route exact path="/po-report" component={POReport} />
            <Route exact path="/po-sku-list" component={POSkuList} />
            <Route exact path="/po-history/:id/:brandName" component={POHistoryBrandWise} />
            <Route exact path="/purchase-order-detail/:OrderId" component={PurchaseOrderDetail} />
            <Route exact path="/inventory-ledger" component={InventoryLedger} />
            <Route exact path="/slow-moving-quantity" component={SlowMovingQuantity} />
            <Route exact path="/inventory/agentWiseOrderReport" component={AgentWiseOrder} />
            <Route exact path="/outofstockproducts" component={OutOfStockProducts} />



            {/* supplyChain */}
            <Route exact path="/supplychain/dashboard" component={SupplyDashboard} />
            <Route exact path="/supplychain/orders-with-issues" component={OrdersWithIssues} />
            <Route exact path="/supplychain/rto-with-less-attempts" component={RtoWithLessAttempts} />
            <Route exact path="/supplychain/in-transit-gt10-days" component={InTransitGtTenDays} />
            <Route exact path="/supplychain/delivered-order-feedback" component={DeliveredOrderFeedback} />
            <Route exact path="/supplychain/ship-order-through-excel" component={ShipOrderThroughExcel} />
            <Route exact path="/supplychain/createpaymentlink" component={CreatePaymentLink} />
            {/* Prioritze Order */}

            <Route exact path="/priortize-order/priortize-florida-order" component={PriortizeFloridaOrder} />

            {/* Inventory Dialer */}

            <Route exact path="/inventory/dailer" component={InventoryDialer} />
            <Route exact path="/inventory/agentWiseCallReport" component={InventoryAgentCallReport} />
            <Route exact path="/inventory/allTickets" component={AllTicket} />
            <Route exact path="/inventory/uploadNDR" component={UploadNDR} />
            <Route exact path="/inventory/myTickets" component={MyTickets} />
            <Route exact path="/inventory/audit-ticket-cleared-by-agents" component={AuditTicketClearedByAgents} />
            <Route exact path="/inventory/cancelledOrderTickets" component={CancelledOrderTickets} />
            <Route exact path="/inventory/preAuditTickets" component={PreAuditTicketReport} />
            <Route exact path="/inventory/TicketDetailedReport" component={TicketDetailedReport} />
            <Route exact path="/inventory/audit-agent-report" component={AuditAgentReport} />
            <Route exact path="/inventory/mela-orders" component={MelaOrders} />
            <Route exact path="/inventory/create-mela/:id" component={CreateMela} />
            <Route exact path="/inventory/create-mela/" component={CreateMela} />
            <Route exact path="/inventory/mela-entry-exit" component={MelaEntryExit} />
            <Route exact path="/inventory/all-mela" component={AllMela} />
            <Route exact path="/inventory/mela-banners" component={MelaBanners} />
            <Route exact path="/inventory/mela-scans-report" component={MelaEntryExitReport} />
            <Route exact path="/inventory/mela-report" component={MelaReport} />
            <Route exact path="/inventory/FreeTrialOrders" component={FreeTrialOrders} />
            <Route exact path="/inventory/all-orders-with-concern" component={AllOrdersWithConcern} />
            <Route exact path="/inventory/auto-cancelled-orders" component={AutoCancelledOrders} />
            <Route exact path="/inventory/orders-without-address" component={OrdersWithoutAddress} />
            <Route exact path="/inventory/blocked-agents" component={BlockedAgents} />
            <Route exact path="/inventory/brands-limit-and-discount" component={BrandsLimitAndDiscount} />
            <Route exact path="/inventory/PriorityOrders" component={PriorityOrders} />


            {/* Fulfilled */}
            <Route exact path="/fulfilledorders" component={Fulfilled} />
            {/* Finanicial report */}
            <Route exact path="/financialreport/downloadorders" component={downloadOrder} />
            <Route exact path="/financialreport/productWiseReport" component={productWiseReport} />
            <Route exact path="/financialreport/newproductWiseReport" component={NewProductWiseReport} />
            <Route exact path="/financialreport/productWiseReportV2" component={productWiseReportV2} />
            <Route exact path="/financialreport/BrandWiseReport" component={BrandWiseReport} />
            <Route exact path="/financialreport/cash-intransit" component={OrderDivisionReport} />
            <Route exact path="/financialreport/order-by-cashback-created" component={OrderByCashback} />
            <Route exact path="/financialreport/return-pickup-report" component={ReturnPickupReport} />


            {/* Sales */}
            <Route exact path="/sales/orderTagToAgent" component={TagOrderToAgent} />
            <Route exact path="/sales/dashboard" component={SaleDashboard} />
            <Route exact path="/sales/performanceManagement" component={PerformanceManagement} />
            <Route exact path="/sales/CreateOrder" component={CreateOrder} />
            <Route exact path="/sales/PreOrder" component={PreOrder} />
            <Route exact path="/sales/crmorders" component={CrmOrders} />
            <Route exact path="/sales/blockpincode" component={BlockPincode} />
            <Route exact path="/sales/sereferencereport" component={SaleRefrealReport} />
            <Route exact path="/sales/sewiseBrandPurchaseReport" component={SewiseBrandPurchaseReport} />
            <Route exact path="/sales/addsaleslead" component={AddSalesLeads} />
            <Route exact path="/sales/leadslist" component={LeadsList} />
            <Route exact path="/sales/dialer" component={LeadsDialer} />
            <Route exact path="/sales/leads-brodcast" component={LeadsBrodcast} />
            <Route exact path="/sales/updatelead/:leadId" component={AddSalesLeads} />
            <Route exact path="/sales/uploadAgentTarget" component={UploadAgentTarget} />
            {/* <Route exact path="/sales/uploadlead" component={UploadLeads} /> */}
            <Route exact path="/sales/allleads" component={AllLeads} />
            <Route exact path="/sales/eligible-orders-sr" component={EligibleOrderSR} />
            <Route exact path="/sales/eligible-orders-split" component={EligibleOrderSplit} />
            <Route exact path="/sales/get-otp" component={GetOtp} />
            <Route exact path="/splitDetails/:OrderId" component={SplitDetails} />
            <Route exact path="/sales/incentive" component={Incentive} />
            <Route exact path="/salesReport/orders-to-be-cancelled" component={OrdersToBeCancelled} />
            <Route exact path="/salesReport/salescallreport" component={SalesCallReport} />
            <Route exact path="/salesReport/agentcallreport" component={AgentCallReport} />
            <Route exact path="/salesReport/agentperformancereport" component={AgentPerformanceReport} />
            <Route exact path="/sales/UploadLeadsTemp" component={UploadLeadsTemp} />
            <Route exact path="/sales/leadEntry" component={LeadEntry} />
            <Route exact path="/salesReport/leadDataEntryreport" component={LeadEntryReport} />
            <Route exact path="/sales/leadDistribution" component={LeadDistribution} />
            <Route exact path="/salesReport/TicketReport" component={TicketReportSales} />
            <Route exact path="/salesReport/CallerSalesReport" component={CallerSalesReport} />
            <Route exact path="/salesReport/offlineAgentReport" component={OfflineSellerReport} />
            <Route exact path="/salesReport/offlineAgentReportNew" component={OfflineSellerReportNew} />
            <Route exact path="/salesReport/offlineAgentWise/:state/:city/:date" component={OfflineAgentWise} />
            <Route exact path="/salesReport/offlineAgentWiseSellers/:refferalCode" component={OfflineAgentWiseSellers} />
            <Route exact path="/salesReport/AgentMapping" component={AgentMappingReport} />
            <Route exact path="/salesReport/BrandWiseRevenueReport" component={AgentBrandReport} />
            <Route exact path="/salesReport/AgentSalesReport" component={AgentSalesReport} />
            <Route exact path="/salesReport/AgentDownloadReport/:referralCode/:startDate/:endDate" component={AgentWiseDownloads} />
            <Route exact path="/salesReport/AgentRtoReport/:agentId/:startDate/:endDate" component={AgentWiseRtoReport} />
            <Route exact path="/agentDetails/:referralCode/:start/:end" component={AgentSalesDetailReport} />
            <Route exact path="/agentTicket/:agentId/:startDate/:endDate" component={AgentTicketDetailReport} />

            <Route exact path="/sales/distributorEligibleOrders" component={DistributorEligibleOrders} />
            <Route exact path="/salesReport/stateWiseSalesReport" component={CityStateWiseSalesReport} />

            <Route exact path="/statewiseSales/:state/:startDate/:endDate" component={SalesStateWiseDetails} />

            <Route exact path="/sales/monthlyIncentive" component={SalesMonthlyIncentive} />
            <Route exact path="/sales/month-wise-performence" component={MonthWisePerformence} />
            <Route exact path="/sales/district-wise-performence" component={DistrictWisePerformence} />
            <Route exact path="/sales/agent-wise-most-sold-pincodes" component={AgentWiseMostSoldPincodes} />
            <Route exact path="/sales/seller-team-lead-converstion" component={SellerTeamLeadConverstion} />
            <Route exact path="/sales/agent-wise-performence/:brandId/:district/:refferdByAgenttype/:month/:year" component={AgentWisePerformence} />
            <Route exact path="/sales/seller-wise-performence/:brandId/:district/:agentId/:refferdByAgenttype/:month/:year" component={SellerWisePerformence} />
            <Route exact path="/sales/bulk-agent-report" component={BulkAgentReport} />
            <Route exact path="/campaignDetails/:_id" component={CampaignDetail} />
            <Route exact path="/salesReport/breakUpSalesReport" component={SalesBreakupReport} />
            <Route exact path="/salesReport/agentAttendance" component={AgentAttendance} />
            <Route exact path="/salesReport/performanceManagementReport" component={PerformanceManagementReport} />

            <Route exact path="/salesReport/salesProbablity" component={SalesProbability} />
            <Route exact path="/seller-weekly-cohort" component={SellerWeeklyCohort} />
            <Route exact path="/seller-wise-sales" component={SellerWiseSales} />
            <Route exact path="/agent-wise-monthly-incentive-report" component={AgentWiseMonthlyIncentiveReport} />
            <Route exact path="/agent-wise-keywest-cocowalk-report" component={AgentwiseKeyWestCocoWalkReport} />
            <Route exact path="/spot-incentive-report" component={SpotIncentiveReport} />
            <Route exact path="/callhistorybyagent" component={CallHistoryByAgent} />
            <Route exact path="/agent-wise-coin-coupon-report" component={AgentWiseCoinAndCoupon} />
            <Route exact path="/field-agent-report" component={FieldAgentReport} />
            <Route exact path="/statewise-leads-report" component={StateWiseLeadsReport} />
            <Route exact path="/clusterwise-leads-report/:state" component={ClusterWiseLeadsReport} />
            <Route exact path="/districtwise-leads-report/:state/:cluster" component={DistrictWiseLeadsReport} />
            <Route exact path="/tehsil-leads-report/:state/:cluster/:district" component={TehsilWiseLeadsReport} />
            <Route exact path='/sales/alldownloads' component={AllDownload} />






            {/* Ops */}
            <Route exact path="/ops/create-campaign" component={Campaign} />
            <Route exact path="/ops/create-monthly-incentive" component={CreateMonthlyIncentive} />
            <Route exact path="/ops/uploadnewleads" component={AssignLead} />
            <Route exact path="/ops/return-invoice" component={ReturnInvoice} />

            {/* <Route exact path="/sales/UploadLeadsTemp" component={UploadLeadsTemp} /> */}
            <Route exact path="/dialer/dashboard" component={DialerDashboard} />

            {/* wareHouse */}
            <Route exact path="/vendor/pendingorders" component={PendingOrders} />
            <Route exact path="/vendor/ship-product-detail" component={TodaysProductWiseOrder} />
            <Route exact path="/vendor/assignorders" component={AssignOrders} />
            <Route exact path="/vendor/cancelled-orders" component={CancelledOrders} />
            <Route exact path="/vendor/rto-orders" component={RtoOrders} />
            <Route exact path="/vendor/intransit-orders" component={InTransitOrders} />
            <Route exact path="/vendor/assignfc" component={AssignFcCenter} />
            <Route exact path="/vendor/assign-order-from-florida" component={AssignOrderFromFlorida} />
            <Route exact path="/vendor/remove-order-of-fc" component={RemoveOrderOfFC} />
            <Route exact path="/supplychain/upload-bulk-order" component={UploadBulkOrder} />
            <Route exact path="/vendor/dispatch-order-of-fc" component={DispatchOrderOfFC} />
            <Route exact path="/vendororderdetails/:OrderId" component={VendorOrderDetails} />
            <Route exact path="/vendor/inventory" component={VendorInventory} />
            <Route exact path="/vendor/inventorydetails/:productId/:startDate/:endDate" component={InventoryDetails} />
            <Route exact path="/vendor/inventorydetails/:productId/:startDate/:endDate/:fcIdFromAdmin" component={InventoryDetails} />
            <Route exact path="/vendor/complaints" component={VendorComplaints} />
            <Route exact path="/vendor/users-complaints" component={UsersComplaints} />
            <Route exact path="/vendor/all-riders" component={AllRiders} />
            <Route exact path="/vendor/cash-collection" component={CashCollection} />
            <Route exact path="/vendor/riders-settlement" component={RidersSettlement} />
            <Route exact path="/vendor/place-order" component={PlaceOrder} />
            <Route exact path="/vendor/add-incoming-orders" component={AddIncomingOrders} />
            <Route exact path="/vendor/purchase-orders" component={PurchaseOrders} />
            <Route exact path="/vendor/settlement" component={Settlement} />
            <Route exact path="/vendor/settlement-report" component={SettlementReport} />
            <Route exact path="/vendor/fcProductWiseReport" component={ProductWiseReportForFc} />
            <Route exact path="/vendor/settlement-details/:id" component={SettlementDetails} />
            <Route exact path="/vendor/fc-grn" component={FcGrnPostVendor} />
            <Route exact path="/vendor/order-wise-settlement-report" component={FCOrderWiseSettlementReport} />

            <Route exact path="/vendor/cart" component={VendorCart} />
            <Route exact path="/fcadmin/fc-complaints" component={FcComplaints} />
            <Route exact path="/fcadmin/fc-users-complaints" component={FcUsersComplaints} />
            <Route exact path="/fcadmin/fc-purchase-orders" component={FcPurchaseOrders} />
            <Route exact path="/fcadmin/fc-wise-orders-list" component={FcWiseOrdersList} />
            <Route exact path="/fcadmin/florida-orders-list" component={FloridaOrdersList} />
            <Route exact path="/fcadmin/fc-group-orders-list" component={FCGroupOrdersList} />
            <Route exact path="/fcadmin/fc-wise-settlements" component={FcWiseSettlements} />
            <Route exact path="/fcadmin/fc-wise-Inventory" component={FcWiseInventory} />
            <Route exact path="/fcadmin/place-order-for-fc" component={PlaceOrderForFc} />
            <Route exact path="/fcadmin/create-new-fc" component={CreateNewFc} />
            <Route exact path="/fcadmin/fc-list" component={FcList} />
            <Route exact path="/fcadmin/admin-fc-grn" component={FcGrnPost} />
            <Route exact path="/fcadmin/amount-collection-fc" component={AmountCollectionfc} />
            <Route exact path="/fcadmin/order-forecast" component={OrderForecastFc} />
            <Route exact path="/fcadmin/dispatch-orders" component={DispatchOrdersFc} />

            <Route exact path="/fcadmin/settlement-report" component={FcSettlementReport} />
            <Route exact path="/fcadmin/total-orders-amount" component={TotalOrdersAmount} />
            <Route exact path="/fcadmin/order-wise-settlement-report" component={OrderWiseSettlementReport} />

            {/* Florida Dashboard */}
            <Route exact path="/florida/dashboard" component={FloridaDashboard} />
            <Route exact path="/florida/create-qr" component={CreateQr} />
            <Route exact path="/florida/packer-list" component={PackerList} />
            <Route exact path="/florida/packer-order-list" component={PackerOrderList} />
            <Route exact path="/florida/packer-non-crm-order-list" component={PackerNonCrmOrderList} />

            {/* Order Packing */}

            <Route exact path="/packing/order" component={OrderPack} />
            <Route exact path="/packing/nonCrmorder" component={NonCrmPackingOrder} />

            {/* coupon related routes */}
            {/* <Route exact path="/coupon/coupon-report" component={CouponReport} /> */}
            <Route exact path="/coupon/free-product-pending-approval" component={FreeProductPendingApproval} />
            <Route exact path='/coupon/gift-report' component={GiftReport} />
            {/* <Route exact path='/coupon/deal-report' component={DealReport} /> */}
            {/* <Route exact path='/deals/dealProducts/:id' component={DealProduct} /> */}

            <Route exact path='/coupon/updateBanners' component={HomeBanner} />
            <Route exact path="/coupon/create-deals" component={CreateDeals} />
            <Route exact path="/coupon/create-deals/:id" component={CreateDeals} />
            <Route exact path="/coupon/all-deals" component={AllDeals} />
            {/* Marketing Reports */}
            <Route exact path="/marketing/coupons-report" component={CouponReport} />
            <Route exact path="/marketing/organic-inorganic-report" component={organicInorganicReport} />
            <Route exact path="/marketing/membership-report" component={MembershipReportMarketing} />
            <Route exact path="/marketing/deals-report" component={DealsReport} />
            <Route exact path="/marketing/free-trial-report" component={FreeTrialReport} />
            <Route exact path="/marketing/testimonials-banners/" component={TestimonialsBanners} />
            <Route exact path="/marketing/whatsapp-banners/" component={WhatsappBanners} />
            <Route exact path="/marketing/special-deal-section" component={SpecialDealSection} />
            <Route exact path="/marketing/organic-cohort-report" component={OrganicCohortReport} />
            <Route exact path="/marketing/lightning-sale-products" component={LightningSaleProducts} />
            <Route exact path="/marketing/survey-dashboard" component={SurveyDashboard} />
            <Route exact path="/marketing/top-three-products" component={TopThreeProducts} />
            <Route exact path="/marketing/free-trail-products" component={FreeTrailProducts} />
            <Route exact path="/marketing/subscription-report" component={SubscriptionReport} />
            <Route exact path="/marketing/whatsapp-marketing" component={WhatsappMarketing} />
            <Route exact path="/marketing/sms-templates" component={SmsTemplates} />
            <Route exact path="/marketing/sms-templates/:catId" component={SmsTemplatesByCategory} />
            <Route exact path="/marketing/templates-images/:id" component={TemplatesImages} />
            <Route exact path="/marketing/color-notification" component={ColorNotification} />
            <Route exact path="/marketing/manage-tv-banners" component={ManageTVBanners} />
            {/* Bulk Routes */}
            <Route exact path="/bulk/ordercreate"  component={CreateOrderForBulk} />
            <Route exact path="/bulk/cashordercreate"  component={CashCreateOrderForBulk} />
            <Route exact path="/bulk/addbulkparent"  component={AddBulkParentSeller} />
            <Route exact path="/bulk/bulkorderlist"  component={BulkOrderList} />
            <Route exact path="/bulk/bulkorderapprovalforscm"  component={BulkOrderApprovalScm} />
            <Route exact path="/bulk/bulkshipstatus"  component={BulkShipStatus} />
            <Route exact path="/bulk/moveBulkOrder"  component={MovedOrderFromBulk} />
            <Route exact path="/bulk/uploadbulkmargin"  component={UploadBulkMargin} />
            <Route exact path="/bulk/bulkorderdownload"  component={BulkDownloadOrderData} />
            <Route exact path="/bulk/bulkproductdownload"  component={BulkDownloadProductData} />
            <Route exact path="/bulkorderdetails/:OrderId" component={BulkOrderDetails} />
            <Route exact path="/bulk/updatezohocustomerid" component={UpdateZohoCustomerId} />
            <Route exact path="/bulk/ordermovebulktoproduct" component={MoveOrderBulkToProductCollection} />
            <Route exact path= '/editBulkOrderScm/:OrderId/:canApprove/:canDispatch' component  = {EditBulkOrderScm} />


            {/* bulk orders */}
            <Route exact path= '/bulk/searchorders' component={SearchBulkOrders} />
            <Route exact path= '/bulk/reachedatdestinationorder' component={ReachedAtDestinationOrders} />
            <Route exact path= '/bulk/processingorders' component={ProcessingOrder} />
            <Route exact path= '/bulk/bulkbrandwisepurchasereport' component={BulkBrandWisePurchaseReport} />
            <Route exact path= '/bulk/moveorderstage' component={MoveBulkStageOrder} />
            <Route exact path= '/bulk/stagewisebulkorders' component={StageWiseOrders} />
            <Route exact path="/stagebulkorderdetails/:OrderId" component={StageBulkOrderDetails} />

            {/* ONDC Routes */}
            <Route exact path= '/ondc/sellerlist' component={OndcSellerList} />
            <Route exact path= '/ondc/sellerdetails/:_id/:sellerId' component={OndcSellerDetails} />
            <Route exact path= '/ondc/orderdetails/:orderId/' component={OndcOrderDetails} />
          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
