import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  CardContent,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Select,
  FormControl,
  Button,
  Paper,
  Collapse,
  Box,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Alert,
  DialogActions,
  Divider,
  MenuItem,
  Switch,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Chip from "../../ui-component/extended/Chip";
// project imports
import MainCard from "../../ui-component/cards/MainCard";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import axios from "axios";
import ApiInstance from "../../Services/ApiInstance";
import moment from "moment";
import Ordershippopup from "../Vendor/Ordershippopup";
import AlertModal from "./AlertModal";
import { LoadingButton } from "@material-ui/lab";
import SubCard from "../../ui-component/cards/SubCard";
import { CheckBox } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import { numericCommaSeparation } from "../../ui-component/NumericSeperation";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const getQueryParams = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

//-----------------------|| TABLE - STICKY HEADER ||-----------------------//

export default function BulkOrderApprovalScm() {
  const classes = useStyles();
  const router = useHistory();
  let paramsData = getQueryParams("orderId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalpage, setTotalPage] = useState(0);
  const [isPendingOrder, setIsPendingOrder] = useState(false);
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const agentRole = getDecryptDataFromLocal("setRole");
  const agentPhoneNumber = getDecryptDataFromLocal("phoneNumber");
  const agentAccessRole = ["4", "5"];
  const [loading, setLoading] = useState(false);
  const [orderdata, setOrderData] = useState([]);
  const [pageLoad, togglePageLoad] = useState(false);
  const [pendingProductData, setPendingProductData] = useState([]);
  const [pendingDownloadData, setPendingDownloadData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [downloadAllData, setDownloadAllData] = useState([]);
  const [numericOrderId, setNumericOrderId] = React.useState(paramsData || "");
  const headers = [
    { key: "createdAt", label: "CreatedAt" },
    { key: "EANCode", label: "EANCode" },
    { key: "sku", label: "SKU Name" },
    { key: "numericOrderId", label: "OrderId" },
    { key: "agentName", label: "Agent Name" },
    { key: "quantity", label: "Quantity" },
    { key: "name", label: "Buyer Name" },
    { key: "brand", label: "Brand" },
  ];
  const AllDownloadHeaders = [
    { key: "numericOrderId", label: "OrderId" },
    { key: "createdAt", label: "CreatedAt" },
    { key: "name", label: "Name" },
    { key: "phoneNumber", label: "PhoneNumber" },
    { key: "agentName", label: "Agent Name" },
    { key: "totalPayableAmount", label: "Total Payable Amount" },
    { key: "totalDispatchAmount", label: "Total Dispatch Amount" },
    { key: "pendingTotalPayble", label: "Total Pending Amount" },
    { key: "cancleAmount", label: "Total Cancle Amount" },
    { key: "dispatchOrderId", label: "Dispatch OrderId" },
    { key: "pendingOrderId", label: "Pending OrderId" },
    { key: "type", label: "Type" },
  ];

  const getOrderWithoutCancel = (order) => {
    let filterOrder = order.filter((order) => {
      if (order.childBulkOrders.length == 0) return true;
      return order.childBulkOrders.some(
        (childOrder) => childOrder.status !== 2
      );
    });
    return filterOrder;
  };

  const getTotalDispatchedAmount = (order) => {
    if (order.childSpliitedOrders.length > 0) {
      let filterChild =
        order?.childSpliitedOrders.filter(
          (item) => item?.zohoInvoice?.zohoInvoiceNumber
        ) || [];
      if (filterChild.length > 0) {
        let total = filterChild.reduce((acc, item) => {
          return acc + item.totalPayableAmount;
        }, 0);
        return total;
      } else return 0;
    }
  };

  const getTotalPaybleOrNumericOrderId = (order, stage) => {
    if (order.length == 0)
      return {
        orderId: [],
        totalPay: 0,
      };
    if (stage == 1) {
      let pendingOrder = order.filter((item) => item.orderStage == 1);
      if (pendingOrder.length > 0) {
        let id = pendingOrder.map((_) => _.numericOrderId);
        let sum = pendingOrder.reduce((acc, it) => {
          return acc + it.totalPayableAmount;
        }, 0);
        return {
          orderId: id,
          totalPay: sum,
        };
      }
    } else {
      let dispatchOrder = order.filter(
        (item) => item.orderStage != 1 && item.status != 2
      );
      if (dispatchOrder.length > 0) {
        let id = dispatchOrder.map((_) => _.numericOrderId);
        let sum = dispatchOrder.reduce((acc, it) => {
          return acc + it.totalPayableAmount;
        }, 0);
        return {
          orderId: id,
          totalPay: sum,
        };
      }
    }
    return {
      orderId: [],
      totalPay: 0,
    };
  };
  const getOrderCancleAmountOrId = (order, stage) => {
    if (order.length == 0)
      return {
        orderId: [],
        totalPay: 0,
      };

    let cancelOrders = order.filter((item) => item.status == 2);
    if (cancelOrders.length > 0) {
      let id = cancelOrders.map((_) => _.numericOrderId);
      let sum = cancelOrders.reduce((acc, it) => {
        return acc + it.totalPayableAmount;
      }, 0);
      return {
        orderId: id,
        totalPay: sum,
      };
    }

    return {
      orderId: [],
      totalPay: 0,
    };
  };

  const getCancleTotal = (order) => {
    if (order.length == 0) return 0;
    let cancleAmount = 0;
    let cancleOrderId = [];
    order.forEach((item) => {
      if (item.status == 2) {
        (cancleAmount += item.totalPayableAmount),
          cancleOrderId.push(item.numericOrderId);
      }
    });

    return { cancleAmount, cancleOrderId };
  };

  const setAllDataForDownload = (order) => {
    let temp = [];
    order.forEach((item) => {
      temp.push({
        ...item,
        totalDispatchAmount: getTotalDispatchedAmount(item),
        pendingTotalPayble: getTotalPaybleOrNumericOrderId(
          item.childBulkOrders,
          1
        ).totalPay,
        cancleAmount: getCancleTotal(item.childBulkOrders).cancleAmount,
        cancleOrderId: getCancleTotal(item.childBulkOrders).cancleOrderId,
        dispatchOrderId: getTotalPaybleOrNumericOrderId(
          item.childBulkOrders,
          2
        ).orderId.toString(),
        pendingOrderId: getTotalPaybleOrNumericOrderId(
          item.childBulkOrders,
          1
        ).orderId.toString(),
        type: item.status == 8 ? "Splited" : "New",
      });
    });
    setDownloadAllData(temp);
  };

  const dataLoad = async (currentPage = page, limit = rowsPerPage) => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/sales/getBulkOrderList", {
        status: 1,
        startDate: date.startDate,
        endDate: date.endDate,
        stage: agentRole == 5 ? 2 : 1,
        numericOrderId: numericOrderId,
        page: currentPage + 1,
        pageLimit: limit,
      })
      .then((res) => {
        if (res.data.success) {
          let filter = getOrderWithoutCancel(res.data.data[0].data || []);
          setAllDataForDownload(res.data.data[0].data);
          setFilterData(filter);
          setTotalPage(res.data.data[0]?.metadata[0]?.totalDocuments);
          setOrderData(res.data.data[0].data || []);
          togglePageLoad(true);
          setLoading(false);
        } else {
          alert(res.data?.message || "Someting went wrong");
        }
      })
      .catch((error) => {
        console.log("error of get data", error);
        setLoading(false);
      });
  };

  const getPendingOrder = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/sales/getBulkPendingOrders", {
        startDate: date.startDate,
        endDate: date.endDate,
      })
      .then((res) => {
        if (res.data.success) {
          setPendingProductData(res.data?.data || []);
          setPendingDownloadData(res.data?.data || []);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error of get data", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isPendingOrder) {
      getPendingOrder();
    }
  }, [isPendingOrder]);

  useEffect(() => {
    if(paramsData){
      dataLoad();
    }
  }, [paramsData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dataLoad(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dataLoad(0, event.target.value);
    setPage(0);
  };

  // if (!pageLoad) {
  //   return (
  //     <Paper align="center">
  //       <img
  //         alt="Example Alt"
  //         style={{ width: "200px", height: "130px" }}
  //         src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
  //         align="center"
  //       />
  //     </Paper>
  //   );
  // }

  const CollapesRowForChild = ({ order, index }) => {
    let orignalOrder = { ...order };
    const [open, setOpen] = useState(false);
    const [childOrders, setChildOrders] = useState([]);
    const [isOrderComplete, setIsOrderComplete] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [totalPay, setTotalPay] = useState({
      unapproveTotal: 0,
      approveTotal: 0,
      approveOrderId: null,
      unApproveOrderId: null,
      approveOrderCancel: false,
      unApproveOrderCancel: false,
    });

    // console.log('prderrrr',order)
    let obj = {
      approve: 0,
      unApprove: 0,
    };

    const checkQuantityFunction = () => {
      try {
        if (orignalOrder?.products.length > 0 && !open) {
          let temp = [];
          orignalOrder.products.forEach((pro) => {
            if (orignalOrder.childBulkOrders.length > 0) {
              orignalOrder.childBulkOrders.forEach((childProduct, index) => {
                if (childProduct.status == 2) {
                  setIsCancel(true);
                }
                if (
                  childProduct.orderStage == 2 ||
                  childProduct.orderStage == 3
                ) {
                  setTotalPay((pre) => ({
                    ...pre,
                    approveOrderCancel: childProduct.status == 2,
                  }));
                  childProduct.products.forEach((approveOrder) => {
                    if (pro.productId == approveOrder.productId) {
                      pro.dispatchQuantity =
                        index == 0 ? 0 : pro?.dispatchQuantity || 0;
                      pro.dispatchQuantity =
                        approveOrder?.quantity + (pro?.dispatchQuantity || 0);
                      pro.pendingQuantity = 0;
                    }
                  });
                }

                if (childProduct.orderStage == 1) {
                  childProduct.products.forEach((unapproveOrder) => {
                    if (pro.productId == unapproveOrder.productId) {
                      pro.pendingQuantity =
                        index == 0 ? 0 : pro?.pendingQuantity || 0;
                      pro.pendingQuantity += unapproveOrder.quantity;
                    }
                  });
                  setTotalPay((pre) => ({
                    ...pre,
                    unApproveOrderCancel: childProduct.status == 2,
                  }));
                }
                if (childProduct.orderStage == 3) {
                  setIsOrderComplete(true);
                }
              });
            }
          });

          if (
            orignalOrder.childBulkOrders &&
            orignalOrder.childBulkOrders.length > 0
          ) {
            orignalOrder.childBulkOrders.forEach((childProduct, index) => {
              if (
                childProduct.orderStage == 2 ||
                childProduct.orderStage == 3
              ) {
                setTotalPay((pre) => ({
                  ...pre,
                  approveTotal:
                    pre.approveTotal + childProduct?.totalPayableAmount || 0,
                  approveOrderId: childProduct._id,
                }));
              }
              if (childProduct.orderStage == 1) {
                setTotalPay((pre) => ({
                  ...pre,
                  unapproveTotal:
                    pre.unapproveTotal + childProduct?.totalPayableAmount || 0,
                  unApproveOrderId: childProduct._id,
                }));
              }
            });
          }
          // console.log("oriiiii", orignalOrder.products);
          // setTotalPay({
          //   approveTotal:obj.approve,
          //   unapproveTotal:obj.unApprove
          // })
          setChildOrders(orignalOrder.products);
          setOpen(true);
        } else {
          setOpen(false);
          setTimeout(() => {
            setTotalPay({
              unapproveTotal: 0,
              approveTotal: 0,
              approveOrderId: null,
              unApproveOrderId: null,
            });
          }, 400);
        }
      } catch (error) {
        console.log("error ", error);
      }
    };

    const approveOrder = (orderId) => {
      let isConfirm = window.confirm(
        "Are you sure you want to approve this order !"
      );
      if (isConfirm) {
        axios
          .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
            orderId: orderId,
            action: "approved", // notApproved
          })
          .then((res) => {
            console.log("res of approve ", res.data);
            if (res.data.success) {
              alert("Order split successfully");
              window.location.reload();
            } else {
              alert(res.data?.message || "someting went wrong");
            }
          })
          .catch((error) => {
            console.log("error of approve", error);
            alert("someting went wrong");
          });
      }
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            {order.status == 8 ? (
              <IconButton
                aria-label="expand row"
                size="small"
                // onClick={() => {
                //   setOpen(!open);
                // }}
                onClick={() => {
                  checkQuantityFunction();
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : null}
          </TableCell>
          <TableCell>{order.numericOrderId}</TableCell>
          {/* <TableCell>{order.parentBulkNumericOrderId}</TableCell> */}
          <TableCell>{order?.name}</TableCell>
          <TableCell>{order?.phoneNumber}</TableCell>
          <TableCell>{order?.agentName}</TableCell>
          <TableCell>{getTotalDispatchedAmount(order)}</TableCell>
          <TableCell>
            {moment(order.createdAt).format("HH:mm A - DD/MM/YYYY")}
          </TableCell>
          <TableCell>
            {" "}
            {Math.round(
              getTotalPaybleOrNumericOrderId(order.childBulkOrders, 2)?.totalPay
            )}
            (
            {getTotalPaybleOrNumericOrderId(
              order.childBulkOrders,
              2
            )?.orderId.toString()}
            ){" "}
          </TableCell>
          <TableCell>
            {" "}
            {Math.round(
              getTotalPaybleOrNumericOrderId(order.childBulkOrders, 1)?.totalPay
            )}{" "}
            (
            {getTotalPaybleOrNumericOrderId(
              order.childBulkOrders,
              1
            )?.orderId.toString()}
            )
          </TableCell>
          <TableCell>
            {" "}
            {Math.round(
              getOrderCancleAmountOrId(order.childBulkOrders, 1)?.totalPay
            )}{" "}
            (
            {getOrderCancleAmountOrId(
              order.childBulkOrders,
              1
            )?.orderId.toString()}
            )
          </TableCell>
          <TableCell>
            {numericCommaSeparation(order?.totalPayableAmount)}
          </TableCell>
          <TableCell>{order?.shippingCost}</TableCell>
          <TableCell>
            {order?.status == 8 ? (
              <Chip label="Splited" chipcolor="primary" />
            ) : (
              <Chip label="New" chipcolor="secondary" />
            )}
          </TableCell>
          <TableCell>
            <Button
              onClick={() => {
                agentRole == 4 ||
                [
                  "9719836187",
                  "8448300516",
                  "7052950504",
                  "8178277221",
                ].includes(agentPhoneNumber)
                  ? // router.push(
                    //     `/editBulkOrderScm/${order._id}/${
                    //       order.status == 8 ? false : true
                    //     }/true`
                    //   )
                    window.open(
                      `/editBulkOrderScm/${order._id}/${
                        order.status == 8 ? false : true
                      }/true`,
                      "_blank"
                    )
                  : // router.push(`/editBulkOrderScm/${order._id}/false/true`);
                    window.open(
                      `/editBulkOrderScm/${order._id}/false/true`,
                      "_blank"
                    );
              }}
              target="_blank"
              variant="contained"
            >
              {agentRole == 5 || agentRole == 14 || agentRole == 13
                ? "Order Details"
                : order.status == 8
                ? "Order Details"
                : "Approve Order"}{" "}
            </Button>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      {orignalOrder.childBulkOrders.length > 0 &&
                        orignalOrder.childBulkOrders.map((childOrder) => (
                          <TableCell>
                            {childOrder.orderStage == 3
                              ? "Order Approved By Agent "
                              : childOrder.orderStage == 2
                              ? "Dispatch Quanity"
                              : "pending Quanity"}{" "}
                            (
                            {childOrder.orderStage == 3
                              ? "Stage 3"
                              : childOrder.orderStage == 2
                              ? "Stage 2"
                              : "Stage 1"}
                            )
                            {/* {
                              childOrder.orderStage == 3 && childOrder?.awbNumber ?'Order Shiped':childOrder.orderStage == 3 && !childOrder?.awbNumber&&childOrder?.zohoInvoice?.zohoInvoiceNumber?'Ready to ship':''
                            } */}
                          </TableCell>
                        ))}
                      {/* <TableCell>
                        {" "}
                        {isOrderComplete
                          ? "Order Approved By Agent "
                          : "Dispatch Quanity"}{" "}
                        ({isOrderComplete ? "Stage 3" : "Stage 2"})
                      </TableCell>
                      {totalPay.unapproveTotal > 0 && (
                        <TableCell>Pending Quanity</TableCell>
                      )} */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {childOrders &&
                      childOrders.length > 0 &&
                      childOrders.map((chOrder) => (
                        <TableRow>
                          <TableCell>{chOrder?.name}</TableCell>
                          <TableCell>
                            {chOrder?.dispatchQuantity || 0}
                          </TableCell>
                          {totalPay.unapproveTotal>0 && <TableCell>{chOrder?.pendingQuantity || 0}</TableCell>}
                          
                        </TableRow>
                      ))} */}

                    {childOrders &&
                      orignalOrder.products.length > 0 &&
                      orignalOrder.products.map((chOrder) => (
                        <TableRow>
                          <TableCell>{chOrder?.name}</TableCell>
                          {orignalOrder.childBulkOrders.length > 0 &&
                            orignalOrder.childBulkOrders.map((childOrder) => (
                              <TableCell>
                                {childOrder.products?.find(
                                  (item) => item.productId == chOrder.productId
                                )?.quantity || 0}{" "}
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell variant="head">
                        Total Payable (shipping Charges)
                      </TableCell>
                      {orignalOrder.childBulkOrders.length > 0 &&
                        orignalOrder.childBulkOrders.map((childOrder) => (
                          <TableCell variant="head">
                            {numericCommaSeparation(
                              childOrder.totalPayableAmount
                            )}{" "}
                            ({childOrder?.shippingCost})
                          </TableCell>
                        ))}
                      {/* <TableCell variant="head">
                        {totalPay.approveTotal}
                      </TableCell>
                      <TableCell variant="head">
                        {totalPay.unapproveTotal}
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Action</TableCell>
                      {orignalOrder.childBulkOrders.length > 0 &&
                        orignalOrder.childBulkOrders.map((childOrder) => (
                          <TableCell>
                            {childOrder.status == 2 ? (
                              <Typography color={"red"}>
                                Canceled - (
                                {childOrder?.cancelledBy?.reasonText})
                              </Typography>
                            ) : childOrder.orderStage == 2 ? (
                              (agentRole == 5 ||
                                agentRole == 14 ||
                                agentRole == 13 ||
                                [
                                  "9719836187",
                                  "8448300516",
                                  "7532035127",
                                  "8826956978",
                                  "9911579990",
                                  "8178277221",
                                  "9891774071",
                                  '9873804039',
                                  '9693813591'
                                ].includes(agentPhoneNumber)) && (
                                <Button
                                  onClick={() => {
                                    // router.push(
                                    //   `/editBulkOrderScm/${childOrder._id}/true/false`
                                    // );
                                    window.open(
                                      `/editBulkOrderScm/${childOrder._id}/true/false`
                                    );
                                  }}
                                  variant="contained"
                                  target="_blank"
                                >
                                  {"Approve order"}{" "}
                                </Button>
                              )
                            ) : childOrder.orderStage == 1 ? (
                              (agentRole == 4 &&
                                childOrder.totalPayableAmount > 0) ||
                              ((agentPhoneNumber == "9719836187" ||
                                agentPhoneNumber == "8448300516" ||
                                agentPhoneNumber == "8178277221") &&
                                childOrder.totalPayableAmount > 0) ? (
                                <Button
                                  onClick={() => {
                                    // router.push(
                                    //   `/editBulkOrderScm/${childOrder._id}/true/true`
                                    // );
                                    window.open(
                                      `/editBulkOrderScm/${childOrder._id}/true/true`
                                    );
                                  }}
                                  target="_blank"
                                  variant="contained"
                                >
                                  {"Approve order"}{" "}
                                </Button>
                              ) : null
                            ) : null}
                          </TableCell>
                        ))}
                      {/* <TableCell variant="head">
                        {!totalPay.approveOrderCancel ? (
                          !isOrderComplete &&
                          (agentRole == 5 ||
                            agentRole == 14 ||
                            agentRole == 13 ||
                            agentPhoneNumber == "9719836187" ||
                            agentPhoneNumber == "8448300516") && (
                            <Button
                              onClick={() => {
                                router.push(
                                  `/editBulkOrderScm/${totalPay.approveOrderId}/true/false`
                                );
                              }}
                              variant="contained"
                              target="_blank"
                            >
                              {"Approve order"}{" "}
                            </Button>
                          )
                        ) : (
                          <Typography color={"red"}>Canceled</Typography>
                        )}
                      </TableCell>
                      <TableCell variant="head">
                        {!totalPay.unApproveOrderCancel ? (
                          (agentRole == 4 && totalPay.unapproveTotal > 0) ||
                          ((agentPhoneNumber == "9719836187" ||
                            agentPhoneNumber == "8448300516") &&
                            totalPay.unapproveTotal > 0) ? (
                            <Button
                              onClick={() => {
                                router.push(
                                  `/editBulkOrderScm/${totalPay.unApproveOrderId}/true/false`
                                );
                              }}
                              target="_blank"
                              variant="contained"
                            >
                              {"Approve order"}{" "}
                            </Button>
                          ) : null
                        ) : (
                          <Typography color={"red"}>Canceled</Typography>
                        )}
                      </TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  return (
    <>
      <SubCard
        content={false}
        title="Bulk Orders"
        secondary={
          <div>
            <Switch
              checked={isPendingOrder}
              onChange={(v) => {
                setIsPendingOrder(v.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />
            <Typography>Pending Orders</Typography>
          </div>
        }
      >
        <Grid container spacing={2} alignItems={"center"} mt={1}>
          <Grid xs={2} item>
            <TextField
              id="datetime-local"
              label="Start Date"
              type="date"
              defaultValue={date.startDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: "100%",
              }}
              onChange={(e) =>
                setDate({
                  ...date,
                  startDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="datetime-local"
              label="End Date"
              type="date"
              defaultValue={date.endDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: "100%",
              }}
              onChange={(e) =>
                setDate({
                  ...date,
                  endDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item lg={2} md={2}>
            <TextField
              label="Order Id"
              value={numericOrderId}
              onChange={(e) => setNumericOrderId(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={2}>
            <LoadingButton
              variant="contained"
              style={{
                width: "100%",
              }}
              loadingIndicator="loading..."
              onClick={() => (isPendingOrder ? getPendingOrder() : dataLoad())}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
          {isPendingOrder && pendingDownloadData.length > 0 ? (
            <Grid item xs={2}>
              <CSVLink
                style={{
                  textDecoration: "none",
                  border: "1px solid #999",
                  padding: "10px 20px",
                  backgroundColor: "#95c7f1",
                }}
                title="Download"
                data={pendingDownloadData}
                headers={headers}
                filename="Pending Products"
              >
                Download
              </CSVLink>
            </Grid>
          ) : (
            downloadAllData.length > 0 && (
              <Grid item xs={3}>
                <CSVLink
                  style={{
                    textDecoration: "none",
                    border: "1px solid #999",
                    padding: "10px 20px",
                    backgroundColor: "#95c7f1",
                  }}
                  title="Download"
                  data={downloadAllData}
                  headers={AllDownloadHeaders}
                  filename="Bulk Approval Orders"
                >
                  Download
                </CSVLink>
              </Grid>
            )
          )}
        </Grid>
        {!isPendingOrder && (
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid row container alignItems="center">
                <Grid item xs={12} lg={4} ml={2}>
                  <Button
                    onClick={() => dataLoad()}
                    variant="contained"
                    style={{ marginLeft: 10 }}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}

        {!isPendingOrder ? (
          <TableContainer style={{ overflowX: "auto" }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Order Id </TableCell>
                  {/* <TableCell>Parent Order Id </TableCell> */}
                  <TableCell>Name</TableCell>
                  <TableCell>PhoneNumber</TableCell>
                  <TableCell>Agent Name</TableCell>
                  <TableCell>Total Dispatch Amount</TableCell>
                  <TableCell>Order Date </TableCell>
                  <TableCell>
                    Total Payable Amount (Dispatch Order Id)
                  </TableCell>
                  <TableCell>Total Payable Amount (Pending Order Id)</TableCell>
                  <TableCell>Total Cancel Amount (Order Id)</TableCell>
                  <TableCell>Total Payabel</TableCell>
                  <TableCell>Shipping Cost</TableCell>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterData &&
                  filterData.length > 0 &&
                  filterData.map((order, index) => (
                    <CollapesRowForChild order={order} index={index} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer style={{ marginTop: 10 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell>EAN Code</TableCell>
                  <TableCell>Sku Name</TableCell>
                  <TableCell>OrderId</TableCell>
                  <TableCell>Agent Name</TableCell>
                  <TableCell>Quanitity</TableCell>
                  <TableCell>Buyer Name</TableCell>
                  <TableCell>Brand</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingProductData &&
                  pendingProductData.length > 0 &&
                  pendingProductData.map((pro) => (
                    <TableRow>
                      <TableCell>
                        {moment(pro?.createdAt).format("DD-MM-YYYY HH:mm  A")}
                      </TableCell>
                      <TableCell>{pro?.EANCode}</TableCell>
                      <TableCell>{pro?.sku}</TableCell>
                      <TableCell>{pro?.numericOrderId}</TableCell>
                      <TableCell>{pro?.agentName}</TableCell>
                      <TableCell>{pro?.quantity}</TableCell>
                      <TableCell>{pro?.name}</TableCell>
                      <TableCell>{pro.brand}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[25, 100, 200, 500]}
          component="div"
          count={totalpage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </SubCard>
    </>
  );
}
