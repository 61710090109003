import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";

import MainCard from "../../ui-component/cards/MainCard";
import SubCard from "../../ui-component/cards/SubCard";
import { gridSpacing } from "../../store/constant";
import CalendarTodayTwoToneIcon from "@material-ui/icons/CalendarTodayTwoTone";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";
import PhoneAndroidTwoToneIcon from "@material-ui/icons/PhoneAndroidTwoTone";
import { getDecryptDataFromLocal } from "../../utils/encrypt";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BulkInvoice from "./BulkInvoice";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import OrderStatus from "./OrderStatus";
import PerformaInvoice from "./PerformaInvoice";

const useStyles = makeStyles((theme) => ({
  accountTab: {
    "& a": {
      minHeight: "auto",
      minWidth: "10px",
      padding: "12px 8px",
      marginRight: "18px",
      color: theme.palette.grey[600],
    },
    "& a.Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& a > span": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    "& a > span > svg": {
      marginBottom: "0px !important",
      marginRight: "10px",
    },
    "& a > span > span + svg": {
      margin: "0px 0px 0px auto !important",
      width: "14px",
      height: "14px",
    },
  },

  customerHeadDetails: {
    "& > svg": {
      width: "15px",
      height: "15px",
      verticalAlign: "text-top",
      marginRight: "5px",
      marginTop: "2px",
    },
  },

  tableResponsive: {
    overflowX: "auto",
  },
  totalBillAmount: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.primary.light,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BulkOrderDetails = () => {
  const classes = useStyles();
  const { OrderId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const [singleOrder, setSingleOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const role = getDecryptDataFromLocal("setRole");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [totalCartValue, setTotalCartValue] = useState([]);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [seller, setSeller] = useState({});
  const [loadingOfPlaceOrder, setLoadingOfPlaceOrder] = useState(false);
  const getAmountOfPartialPayment = () => {
    axios
      .post(
        process.env.REACT_APP_PATH +
          "/sales/getNeededPrepaidAmountForBulkOrder",
        {
          orderId: OrderId,
        }
      )
      .then((res) => {
        if (res.data.success) {
          dataLoad();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const dataLoad = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_PATH}/role1/allCapturedOrders/${OrderId}`)
      .then((res) => {
        if (res?.data?.success) {
          let orderrr = res?.data?.data[0] || [];
          setSingleOrder(orderrr);
          if (
            orderrr.paymentMethod == 1 &&
            orderrr.status == 1 &&
            !orderrr?.collectedAmountDueToHighReachedAtDesti
          ) {
            getAmountOfPartialPayment();
          }
          setTotalPayableAmount(
            orderrr?.collectedAmountDueToHighReachedAtDesti || 0
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const createRazorPayId = () => {
    setLoadingOfPlaceOrder(true);
    axios
      .post(
        `${process.env.REACT_APP_PATH}/products/createRazorpayOrderForCrm`,
        {
          orderId: OrderId,
          sellerId: singleOrder?.sellerId,
        }
      )
      .then((res) => {
        if (res.data.success) {
          handlePaymemt(res.data.data.razorPayOrderId);
        } else {
          alert(res.data.message);
          setLoadingOfPlaceOrder(false);
        }
      })
      .catch((e) => {
        console.log("Error!", e);
        setLoadingOfPlaceOrder(false);
      });
  };

  const captureOrder = (razorPayId, razorpayOrderId) => {
    console.log("razor[a", razorPayId);
    setLoadingOfPlaceOrder(true);
    axios
      .post(
        process.env.REACT_APP_PATH +
          "/sales/captureRazorpayPaymentForBulkOrder",
        {
          orderId: OrderId,
          razorPayOrderId: razorpayOrderId,
          razorPayId: razorPayId,
        }
      )
      .then((res) => {
        if (res.data.success) {
          console.log("res of capture order", res.data);
          alert(res.data.message);
          setLoadingOfPlaceOrder(false);
          window.location.reload();
        } else {
          alert(res.data.message);
          setLoadingOfPlaceOrder(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert(error?.response?.data?.message || "something went wrong");
        setLoadingOfPlaceOrder(false);
      });
  };

  const handlePaymemt = (razorPayid) => {
    var options = {
      key: "rzp_live_E2nnls6jq3jBMt", // Enter the Key ID generated from the Dashboard
      // key: "rzp_test_Ax7N4MA8EfQkcO", // Enter the Key ID generated from the Dashboard
      amount: Math.round(totalPayableAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Buyume",
      description: `To Seller: ${singleOrder.name}`,
      image:
        "https://res.cloudinary.com/dyqcevdpm/image/upload/v1623494311/Buyum_Logo_mfb5xz.png",
      order_id: razorPayid, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: "",
      handler: function (response) {
        captureOrder(response.razorpay_payment_id, razorPayid);
      },
      prefill: {
        name: `{singleOrder.name}`,
        email: "test@buyume.com",
      },
      notes: {
        orderId: OrderId,
        orderType: "",
      },
      theme: {
        color: "#002A56",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.description);
    });
    rzp1.open();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dataLoad();
  }, []);

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <MainCard>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        className={classes.accountTab}
        aria-label="simple tabs example"
        sx={{ mb: 3 }}
      >
        <Tab
          icon={<DescriptionTwoToneIcon />}
          component={Link}
          to="#"
          label="Details"
          {...a11yProps(0)}
        />
        <Tab
          icon={<DescriptionTwoToneIcon />}
          component={Link}
          to="#"
          label="Invoice"
          {...a11yProps(1)}
        />
        <Tab
          icon={<DescriptionTwoToneIcon />}
          component={Link}
          to="#"
          label="Proforma invoice "
          {...a11yProps(2)}
        />
        <Tab
          icon={<LocalShippingTwoToneIcon />}
          component={Link}
          to="#"
          label="Status"
          {...a11yProps(3)}
          disabled={singleOrder?.awbNumber=="FLORIDA_BEAUTY"||singleOrder?.awbNumber=="EMERGENT"}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <SubCard
              title="Order Details"
              secondary={
                <Typography variant="subtitle1">
                  Place On : {new Date(singleOrder?.createdAt).toDateString()}{" "}
                  {new Date(singleOrder?.createdAt).toLocaleTimeString()}
                </Typography>
              }
            >
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.customerHeadDetails}
                      >
                        <CalendarTodayTwoToneIcon /> {singleOrder?.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.customerHeadDetails}
                      >
                        <PhoneAndroidTwoToneIcon /> {singleOrder?.phoneNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                    <Grid container spacing={gridSpacing}>
                      {singleOrder?.address ? (
                        <Grid item sm={10}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h4">
                                Shipping address
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={0}>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    First name : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.name}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Phone : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.phoneNumber}
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={0}>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Address Line 1: &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.address1}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Address Line 2 : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.address2}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Landmark : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.landmark}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Postal code : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.postalCode}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    City : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.city}
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item sm={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h4">
                                Address Not Found
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid spacing={gridSpacing}>
                      <Grid container position={'relative'}>
                        <Typography variant="h4">
                          {" "}
                          Payment method : &nbsp;
                        </Typography>
                        <Typography variant="body2">
                          {singleOrder?.paymentMethod === 1
                            ? " Cash Payment"
                            : " Online Payment"}
                        </Typography>
                        {totalPayableAmount &&
                      totalPayableAmount > 0 &&
                      singleOrder?.paymentMethod == 1 ? 
                        <Button variant='outlined' size='small' sx={{alignItems:'end',justifyItems:'flex-end',position:'absolute',top:'10px',right:'10px'}} onClick={getAmountOfPartialPayment}>Refresh Payment</Button>
                      :null}
                      </Grid>
                      <Typography variant="subtitle1">
                        Transaction ID : &nbsp;
                        <Typography component="span" variant="body2">
                          {singleOrder?.numericOrderId}
                        </Typography>
                      </Typography>
                      <Typography variant="subtitle1">
                        Total Payable : &nbsp;
                        <Typography component="span" variant="body2">
                          {singleOrder?.totalPayableAmount}
                        </Typography>
                      </Typography>
                      {totalPayableAmount &&
                      totalPayableAmount > 0 &&
                      singleOrder?.paymentMethod == 1 ? (
                        <Grid
                          item
                          xs={8}
                          container
                          style={{
                            border: "1px dashed #000",
                            padding: "5px 10px",
                            marginTop: 20,
                          }}
                        >
                          <Grid item xs={12} lg={6}>
                            <Typography variant="subtitle1">
                              Partial Payment :
                            </Typography>
                            <Typography>
                              Pay Now {totalPayableAmount}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={6}
                            justifyContent={"flex-end"}
                            container
                          >
                            <LoadingButton
                              loading={loadingOfPlaceOrder}
                              loadingIndicator="Wait..."
                              variant="contained"
                              onClick={createRazorPayId}
                              style={{ width: 160 }}
                            >
                              Pay
                            </LoadingButton>
                          </Grid>
                        
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
          {singleOrder?.rewardProducts?.length > 0 && (
            <Grid item xs={12}>
              <SubCard title="Reward Products" content={false}>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.tableResponsive}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {singleOrder?.rewardProducts?.map((row, index) => {
                          return (
                            <TableRow key={row?.rewardProductId}>
                              <TableCell>
                                {" "}
                                <Typography variant="subtitle1">
                                  {row?.rewardProductName}{" "}
                                </Typography>
                              </TableCell>
                              <TableCell> {row?.quantity} </TableCell>
                              <TableCell>{Math.round(row?.mrp)}</TableCell>
                              <TableCell>
                                {" "}
                                {Math.round(row?.mrp * row?.quantity)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </SubCard>
            </Grid>
          )}
          <Grid item xs={12}>
            <SubCard title="All Products" content={false}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.tableResponsive}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {singleOrder?.products?.map((row, index) => {
                        return (
                          <TableRow key={row?._id}>
                            <TableCell>
                              {" "}
                              <Typography variant="subtitle1">
                                {row?.name}{" "}
                              </Typography>
                            </TableCell>
                            <TableCell> {row?.quantity} </TableCell>
                            <TableCell>{Math.round(row?.mrp)}</TableCell>
                            <TableCell>
                              {" "}
                              {Math.round(row?.mrp * row?.quantity)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <SubCard
                    sx={{ mx: 3, mb: 3 }}
                    contentClass={classes.totalBillAmount}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      spacing={gridSpacing}
                    >
                      <Grid item sm={6} md={6}>
                        <Grid container item>
                          {role == 1 || role == 4 ? (
                            singleOrder?.couponCode == null ? null : (
                              <Typography variant="h2">
                                Coupon already applied
                              </Typography>
                            )
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid item sm={12} lg={4} md={4}>
                        <Grid
                          item
                          container
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography align="left" variant="subtitle1">
                            {" "}
                            Total Mrp :{" "}
                          </Typography>
                          <Typography align="right" variant="body2">
                            &#8377;{" "}
                            {singleOrder.products &&
                              singleOrder.products.length > 0 &&
                              singleOrder.products.reduce(
                                (acc, cur) => acc + cur.mrp * cur.quantity,
                                0
                              )}
                          </Typography>
                        </Grid>
                        {singleOrder?.codCharge ? (
                          <Grid
                            item
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography align="left" variant="subtitle1">
                              Cod Charge :{" "}
                            </Typography>
                            <Typography align="right" variant="body2">
                              &#8377; {singleOrder.codCharge}
                            </Typography>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          container
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography align="right" variant="subtitle1">
                            {" "}
                            Total Quantity :{" "}
                          </Typography>
                          <Typography align="right" variant="body2">
                            {singleOrder.products &&
                              singleOrder.products.reduce(
                                (acc, cur) => acc + cur.quantity,
                                0
                              )}
                          </Typography>
                        </Grid>
                        {singleOrder?.shippingCost ? (
                          <Grid
                            item
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography align="right" variant="subtitle1">
                              Shipping Charge :{" "}
                            </Typography>
                            <Typography align="right" variant="body2">
                              &#8377; {singleOrder.shippingCost}
                            </Typography>
                          </Grid>
                        ) : null}
                        <Grid>
                          {singleOrder?.discountBreakupInfo1
                            ? singleOrder?.discountBreakupInfo1.map(
                                (disObj, i) => {
                                  return (
                                    <Grid
                                      item
                                      container
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Typography>
                                        {Object.keys(disObj)}
                                      </Typography>
                                      <Typography variant="h5">
                                        {" "}
                                        - ₹{Math.round(
                                          Object.values(disObj)
                                        )}{" "}
                                      </Typography>
                                    </Grid>
                                  );
                                }
                              )
                            : null}
                        </Grid>
                        {singleOrder?.prepaidCollectedAmount ? (
                          <Grid
                            item
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography align="right" variant="subtitle1">
                              Partial Paid :{" "}
                            </Typography>
                            <Typography align="right" variant="body2">
                              &#8377; {singleOrder.prepaidCollectedAmount}
                            </Typography>
                          </Grid>
                        ) : null}

                        <Grid
                          item
                          container
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            align="right"
                            color="primary"
                            variant="subtitle1"
                          >
                            {" "}
                            Total :{" "}
                          </Typography>
                          <Typography
                            align="right"
                            color="primary"
                            variant="subtitle1"
                          >
                            {" "}
                            &#8377; {singleOrder.totalPayableAmount}{" "}
                          </Typography>
                        </Grid>
                        {singleOrder?.prepaidCollectedAmount ? (
                          <Grid
                            item
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography align="right" variant="subtitle1">
                              Remaining Amount :{" "}
                            </Typography>
                            <Typography align="right" variant="body2">
                              &#8377; {singleOrder.remainingAmountForCod}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </SubCard>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BulkInvoice singleOrder={singleOrder} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PerformaInvoice singleOrder={singleOrder} />
      </TabPanel>
      <TabPanel value={value} index={3}>
          <OrderStatus  singleOrder={singleOrder} orderAwbNumber={singleOrder?.awbNumber} />
      </TabPanel>
    </MainCard>
  );
};

export default BulkOrderDetails;
