import { Grid } from "@material-ui/core";
import React from "react";
import SubCard from "../../ui-component/cards/SubCard";

const OrderStatus = ({ singleOrder, orderAwbNumber }) => {
  return (
    <>
      <Grid item xs={12} container>
        <Grid item xs={!orderAwbNumber ? 6 : 12}>
          <SubCard>
            {/* Update Sinnle awb numbers */}

            {singleOrder.awbNumber &&
            singleOrder?.shippingMethod === "XPRESSBEES" ? (
              <>
                <Grid>
                  <iframe
                    src={`https://www.xpressbees.com/track`}
                    width="100%"
                    height="700"
                  />
                </Grid>
              </>
            ) : (
              <>
                {singleOrder.awbNumber &&
                singleOrder?.shippingMethod === "ROCKETBOX" ? (
                  <Grid>
                    <iframe
                      src={`https://app.rocketbox.in/p/track-shipment?waybillno=${orderAwbNumber}&dp_name=undefined`}
                      width="100%"
                      height="700"
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid>
                      <iframe
                        src={`https://shiprocket.co/tracking/${orderAwbNumber}`}
                        width="100%"
                        height="700"
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderStatus;
