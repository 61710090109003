import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import { CSVLink } from "react-csv";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  CardContent,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Select,
  FormControl,
  Button,
  Paper,
  Checkbox,
} from "@material-ui/core";
import Chip from "../../ui-component/extended/Chip";
import MainCard from "../../ui-component/cards/MainCard";
import Switch from "@mui/material/Switch";
import TicketAlert from "../Dashboard/Leads/TicketAlert";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import CancellationAlert from "./CancellationAlert";
import { getDecryptDataFromLocal } from "../../utils/encrypt";
import PaymentLinkModal from "./PaymentLinkModal";
import OrderCancelModal from "./OrderCancelModal";
const headers = [
  { label: "Order Id", key: "orderId" },
  { label: "Status", key: "status" },
  { label: "message", key: "message" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


{/* <TableCell align="left">Crm Aproved</TableCell>
<TableCell align="left">Ship Order Id</TableCell>
<TableCell align="left">Name</TableCell>
<TableCell align="left">Phone Number</TableCell>
<TableCell align="left">Total Amount</TableCell>
<TableCell align="left">Date</TableCell>
<TableCell align="left">Type</TableCell>
<TableCell align="left">Status</TableCell>
<TableCell align="left">Audit Verification</TableCell> */}
const Headers = [
  { label: "Created At", key: "createdAt" },
  { label: "Crm Aproved", key: "isCrmOrderApproved" },
  { label: "Ship Order Id", key: "shiprocketOrderId" },
  { label: "Name", key: "name" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Total Amount", key: "totalPayableAmount" },
  { label: "status", key: "status" },
]

//-----------------------|| TABLE - STICKY HEADER ||-----------------------//

export default function SellerCrmOrdersList() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [data,setData]  = useState([])
  const [orderdata, setOrderData] = useState([]);
  const [orderNotInInventory, setOrderNotInInventory] = useState([]);
  const [search, setSearch] = React.useState("");
  const [pageLoad, togglePageLoad] = useState(false);
  const [totalpage, setTotalPage] = useState();
  const role = getDecryptDataFromLocal("setRole");
  const [statusFilter, setStatusFilter] = useState("");
  const [recentDay, setRecentDay] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").subtract(4,'M').format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });

  const [shipSearch, setShipSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [awbNumber, setAwbNumber] = useState("");
  const [PaymentModalOpen, setPaymentModalOpen] = React.useState(false);
  const phoneNumber = getDecryptDataFromLocal("phoneNumber");
  const [selectOrder, setSelectOrder] = useState({});
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [orderCancelModalOpen, setOrderCancelModalOpen] = useState(false);
  const access = [
    "8383072979",
    "8178740401",
    "9719836187",
    "8882475639",
    "8851162831",
    '9891434580'
  ];
  const accessForCancelButton = [
    "8178740401",
    "9719836187",
    "8851162831",
    '9891434580'
  ]

  const ref = useRef(null);

  const dataLoad = async (
    search,
    page,
    rowsPerPage,
    statusFilter,
    awbNumber,
    uniqueSellerId
  ) => {
    setLoading2(true);
    try {
      var orderReq = {
        phoneNumber: search,
        page: page,
        pageLimit: rowsPerPage,
        status: statusFilter,
        startDate: moment(new Date(date.startDate)).format("YYYY-MM-DD"),
        endDate: moment(new Date(date.endDate)).format("YYYY-MM-DD"),
        isCrmOrder: true,
      };
      if (awbNumber) {
        orderReq.awbNumber = awbNumber;
      }
      if (uniqueSellerId) {
        orderReq.uniqueSellerId = uniqueSellerId;
      }
      const response = await axios
        .post(
          process.env.REACT_APP_PATH + "/role1/searchOrderByPhone",
          orderReq
        )
        .then(function (respo) {
          if (respo.data.success) {
            let tempData = respo.data.data[0].data;
            let dataWithInventory = tempData.filter(
              (item) => item.isInventoryAvailable == true
            );
            let dataWithOutInventory = tempData.filter(
              (item) => item.isInventoryAvailable == false
            );
            setData(tempData)
            setOrderData(dataWithInventory);
            setOrderNotInInventory(dataWithOutInventory);
            setTotalPage(respo.data.data[0].metaData[0].totalDocuments);
          } else {
            setOrderData(0);
          }
          togglePageLoad(true);
          setLoading2(false);
        })
        .catch(function (error) {
          console.log(error, "data not comeing");
          togglePageLoad(true);
          setLoading2(false);
        });
    } catch (error) {
      console.log(error, "api error");
      setLoading2(false);
    }
  };

  const handleSearch1 = (event) => {
    event.preventDefault();
    if (shipSearch != "") {
      setLoading(true);
      axios({
        method: "POST",
        url: process.env.REACT_APP_PATH + "/role1/searchOrderByPhone",
        data: {
          shiprocketOrderId: shipSearch.toUpperCase(),
          gloabalSearch: ["9719836187", "7503080335", "8700175494"].includes(
            phoneNumber
          )
            ? true
            : false,
          isCrmOrder: true,
        },
      })
        .then((res) => {
          if (res.data.success) {
            // console.log("res", res)
            setLoading(false);
            setOrderData(res.data.data[0].data);
            setTotalPage(res.data.data[0].metaData[0].totalDocuments);
            if (ref.current) {
              ref.current.scrollTop = 0;
            }
          } else {
            setLoading(false);

            setOrderData(0);
          }
          togglePageLoad(true);
        })
        .catch((err) => {
          console.log("err", err);
          togglePageLoad(true);
        });
    } else {
      dataLoad("", "", rowsPerPage, "", recentDay);
    }
  };

  const handleShipChange = (e) => {
    let searchString = e.target.value.trim();
    setShipSearch(searchString);
  };

  useEffect(() => {
    if (shipSearch == null || shipSearch == undefined) {
      dataLoad("", "", rowsPerPage, statusFilter, recentDay);
    }
    console.log("shipSearch", shipSearch);
  }, [shipSearch]);

  const handleSearch = (event) => {
    const newString = event.target.value;
    setSearch(newString);
    if (newString.length == 10) {
      dataLoad(event.target.value, "", rowsPerPage, statusFilter, recentDay);
    } else if (newString.length == 0) {
      console.log("running");
      // setOrderData("")
      dataLoad("", "", rowsPerPage, statusFilter, recentDay);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dataLoad(search, 0, rowsPerPage, statusFilter);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dataLoad(search, newPage, rowsPerPage, statusFilter);
  };

  const chip = (status) => {
    if (status === 1) {
      return <Chip label="Processing" size="small" chipcolor="primary" />;
    } else if (status === 2) {
      return <Chip label="Canceled" size="small" chipcolor="orange" />;
    } else if (status === 3) {
      return <Chip label="Complete" size="small" chipcolor="success" />;
    } else if (status === 4) {
      return <Chip label="RTO" size="small" chipcolor="orange" />;
    } else if (status === 6) {
      return <Chip label="Undelivered" size="small" chipcolor="orange" />;
    } else if (status == 8) {
      return <Chip label="Splitted" size="small" chipcolor="secondary" />;
    }
  };
  const filterOrders = () => {
    if (awbNumber) {
      dataLoad(search, page, rowsPerPage, statusFilter, recentDay, awbNumber);
    } else {
      dataLoad(search, page, rowsPerPage, statusFilter, recentDay);
    }
  };

  const orderApproved = (order, status) => {
    console.log("order", order, status);
    axios
      .post(process.env.REACT_APP_PATH + "/products/approveCrmOrder", {
        orderId: order._id,
        isApproved: !status,
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "Successfully Order approved");
          window.location.reload()
        } else {
          alert(res.data?.message || "Someting went wrong");
        }
      })
      .catch((error) => {
        alert("Someting went wrong");
      });
  };

  const CheckOrder = (SelectedOrder) => {
    if (
      SelectedOrder.hasOwnProperty("isVerified") === false ||
      SelectedOrder.isVerified === true
    ) {
      return <Switch {...label} checked={true} disabled={true} />;
    } else if (SelectedOrder.isVerified === false) {
      return <Switch {...label} checked={false} disabled={true} />;
    }
  };

  useEffect(() => {
    dataLoad(search, page, rowsPerPage, statusFilter, recentDay);
    return setRecentDay(0);
  }, []);

  if (!pageLoad) {
    return (
      <Paper align="center">
        <img
          alt="Example Alt"
          style={{ width: "200px", height: "130px" }}
          src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
          align="center"
        />
      </Paper>
    );
  }

  const LoadingComp = () => {
    if (loading2) {
      return (
        <Paper style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="Example Alt"
            style={{ width: "200px", height: "130px" }}
            src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
            align="center"
          />
        </Paper>
      );
    }
  };
  return (
    <>
      <MainCard
        content={false}
        title="CRM Orders"
        // secondary={<SecondaryAction link="https://next.material-ui.com/components/tables/" />}
      >
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid row container alignItems="center" spacing={2}>
              <Grid item lg={3} md={4} xs={12} sm={6}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                  placeholder="Search Seller By Number"
                  value={search}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid lg={3} md={4} xs={12} sm={6} item>
                <form onSubmit={handleSearch1} style={{ width: "100%" }}>
                  <Grid container item lg={12} alignItems="center" spacing={1}>
                    <Grid item xs={12} lg={8} sm={6}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <SearchIcon fontSize="small" /> */}
                            </InputAdornment>
                          ),
                        }}
                        style={{ width: "100%" }}
                        onChange={handleShipChange}
                        placeholder="Search By Ship Rocket Id"
                        value={shipSearch}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={2} sm={6}>
                      <LoadingButton
                        variant="contained"
                        style={{
                          width: "100%",
                        }}
                        loadingIndicator="loading..."
                        type="submit"
                        loading={loading}
                        fullWidth
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12} lg={2} textAlign={"center"}>
                <TextField
                  id="datetime-local"
                  label="Start Date"
                  type="date"
                  defaultValue={date.startDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                  // size="small"
                  onChange={(e) =>
                    setDate({ ...date, startDate: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} lg={2} textAlign={"center"}>
                <TextField
                  id="datetime-local"
                  label="End Date"
                  type="date"
                  defaultValue={date.endDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setDate({ ...date, endDate: e.target.value })
                  }
                  // size="small"
                />
              </Grid>

              <Grid item lg={2} md={3} xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => filterOrders()}
                  fullWidth
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={2} item>
                {
                  data && data.length>0  &&   <CSVLink 
                  filename={`ProcessingOrder.csv`} 
                  data={data} 
                  headers={Headers} 
                  style={{ 
                    textDecoration: 'none', 
                    border: '1px solid #999', 
                    padding: '10px 20px', 
                    backgroundColor: '#95c7f1' 
                    }}>
                    Download
                  </CSVLink>
                }
          

          </Grid>

            </Grid>

          </Grid>
        </CardContent>
        {/* table */}
        <TableContainer className={classes.container} ref={ref}>
          {loading2 ? <LoadingComp /> : null}
          {orderdata.length && loading2 == false > 0 ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Crm Aproved</TableCell>
                  <TableCell align="left">Ship Order Id</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Phone Number</TableCell>
                  <TableCell align="left">Total Amount</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Audit Verification</TableCell>
                  <TableCell align="left">Action</TableCell>
                  <TableCell align="left">Payment Link</TableCell>
                  <TableCell align="left">Order Cancel</TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                style={{
                  userSelect:
                    role == 1 || role == 2 || role == 4 ? "text" : "none",
                }}
              >
                {orderdata.length && loading2 == false > 0
                  ? orderdata
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order, id) => {
                        return (
                          <TableRow
                            sx={{ py: 3 }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                          >
                            <TableCell align="left">
                              <Switch
                                value={order?.isCrmOrderApproved || false}
                                onChange={() =>
                                  orderApproved(
                                    order,
                                    order?.isCrmOrderApproved || false
                                  )
                                }
                                checked={
                                  order?.isCrmOrderApproved || false || loading2
                                }
                                disabled={
                                  access.includes(phoneNumber) ? false : true
                                }
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              <Link
                                to={`/orderdetails/${order._id}/false`}
                                style={{
                                  textDecoration: "none",
                                  color:
                                    order.paymentMethod == 1 ? "#000" : "green",
                                }}
                                target="_blank"
                              >
                                {order.shiprocketOrderId}
                              </Link>
                              {order?.groupedOrderId == "NA" ? (
                                " "
                              ) : (
                                <Typography>
                                  GroupID:- {order?.groupedOrderId}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: order.isBeuPartnerOrder ? "red" : "#000",
                              }}
                            >
                              {order.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: order.isBeuPartnerOrder ? "red" : "#000",
                              }}
                            >
                              {order.phoneNumber}
                            </TableCell>
                            <TableCell align="left">
                              {Math.round(order.totalPayableAmount)}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 200 }}>
                              {new Date(order.createdAt).toDateString()} -
                              {new Date(order.createdAt).toLocaleTimeString()}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 150 }}>
                              {order.monthDiffFromFirstOrder >= 1
                                ? "Repeat Order"
                                : "New Order"}
                            </TableCell>
                            <TableCell align="left">
                              {/* {order.status} */}
                              {order.status === 3 && chip(3)}
                              {order.status === 2 && chip(2)}
                              {order.status === 4 && chip(4)}
                              {order.status === 6 && chip(6)}
                              {order.status === 8 && chip(8)}
                              {order.awbNumber !== undefined &&
                              order.status === 1 ? (
                                <Chip
                                  label="In Transit"
                                  size="small"
                                  chipcolor="primary"
                                />
                              ) : order.status === 1 &&
                                order.awbNumber === undefined ? (
                                chip(1)
                              ) : null}
                            </TableCell>
                            <TableCell>{CheckOrder(order)}</TableCell>
                            <TableCell align="left">
                              <Link
                                to={`/orderdetails/${order._id}/false`}
                                style={{
                                  textDecoration: "none",
                                  backgroundColor: "#f6f6f6",
                                  padding: "10px 15px",
                                }}
                                target="_blank"
                              >
                                Details
                              </Link>
                            </TableCell>
                            <TableCell>
                              {order?.paymentMethod == 1 && (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setSelectOrder(order);
                                    setPaymentModalOpen(true);
                                  }}
                                >
                                  Generate Payment Link
                                </Button>
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setSelectOrder(order);
                                  setOrderCancelModalOpen(true);
                                }}
                                color="error"
                                disabled={accessForCancelButton.includes(phoneNumber)?false:true}
                              >
                                Cancel
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          ) : (
            <Grid item xs={12} style={{ width: "1000px" }}>
              {loading2 == false && (
                <Typography variant="h4" padding="15px 0 0 15px">
                  Orders Not Found
                </Typography>
              )}
            </Grid>
          )}
        </TableContainer>

        <Typography mt={1} variant="h3" py={2} ml={1}>
          {!loading2 ? " Order without inventory" : ""}
        </Typography>
        <TableContainer className={classes.container}>
          {orderNotInInventory.length && loading2 == false > 0 ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Crm Aproved</TableCell>
                  <TableCell align="left">Ship Order Id</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Phone Number</TableCell>
                  <TableCell align="left">Total Amount</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Audit Verification</TableCell>
                  <TableCell align="left">Action</TableCell>
                  <TableCell align="left" style={{ width: 300 }}>
                    Payment Link
                  </TableCell>
                  <TableCell align="left">Order Cancel</TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                style={{
                  userSelect:
                    role == 1 || role == 2 || role == 4 ? "text" : "none",
                }}
              >
                {orderNotInInventory.length && loading2 == false > 0
                  ? orderNotInInventory
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order, id) => {
                        return (
                          <TableRow
                            sx={{ py: 3 }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                          >
                            <TableCell align="left">
                              <Switch
                                value={order?.isCrmOrderApproved || false}
                                onChange={() =>
                                  orderApproved(
                                    order,
                                    order?.isCrmOrderApproved || false
                                  )
                                }
                                checked={order?.isCrmOrderApproved || false}
                                disabled={true}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              <Link
                                to={`/orderdetails/${order._id}/false`}
                                style={{
                                  textDecoration: "none",
                                  color:
                                    order.paymentMethod == 1 ? "#000" : "green",
                                }}
                                target="_blank"
                              >
                                {order.shiprocketOrderId}
                              </Link>
                              {order?.groupedOrderId == "NA" ? (
                                " "
                              ) : (
                                <Typography>
                                  GroupID:- {order?.groupedOrderId}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: order.isBeuPartnerOrder ? "red" : "#000",
                              }}
                            >
                              {order.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: order.isBeuPartnerOrder ? "red" : "#000",
                              }}
                            >
                              {order.phoneNumber}
                            </TableCell>
                            <TableCell align="left">
                              {Math.round(order.totalPayableAmount)}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 200 }}>
                              {new Date(order.createdAt).toDateString()} -
                              {new Date(order.createdAt).toLocaleTimeString()}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 150 }}>
                              {order.monthDiffFromFirstOrder >= 1
                                ? "Repeat Order"
                                : "New Order"}
                            </TableCell>
                            <TableCell align="left">
                              {/* {order.status} */}
                              {order.status === 3 && chip(3)}
                              {order.status === 2 && chip(2)}
                              {order.status === 4 && chip(4)}
                              {order.status === 6 && chip(6)}
                              {order.status === 8 && chip(8)}
                              {order.awbNumber !== undefined &&
                              order.status === 1 ? (
                                <Chip
                                  label="In Transit"
                                  size="small"
                                  chipcolor="primary"
                                />
                              ) : order.status === 1 &&
                                order.awbNumber === undefined ? (
                                chip(1)
                              ) : null}
                            </TableCell>
                            <TableCell>{CheckOrder(order)}</TableCell>
                            <TableCell align="left">
                              <Link
                                to={`/orderdetails/${order._id}/false`}
                                style={{
                                  textDecoration: "none",
                                  backgroundColor: "#f6f6f6",
                                  padding: "10px 15px",
                                }}
                                target="_blank"
                              >
                                Details
                              </Link>
                            </TableCell>
                            <TableCell>
                              {order?.paymentMethod == 1 && (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setSelectOrder(order);
                                    setPaymentModalOpen(true);
                                  }}
                                  disabled
                                >
                                  Generate Payment Link
                                </Button>
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setSelectOrder(order);
                                  setOrderCancelModalOpen(true);
                                }}
                                color="error"
                                disabled={accessForCancelButton.includes(phoneNumber)?false:true}
                              >
                                Cancel
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          ) : (
            <Grid item xs={12} style={{ width: "1000px" }}>
              {loading2 == false && (
                <Typography variant="h4" padding="15px 0 0 15px">
                  Orders Not Found
                </Typography>
              )}
            </Grid>
          )}
        </TableContainer>

        {/* table pagination */}
        <TablePagination
          rowsPerPageOptions={[100,200,500]}
          component="div"
          count={totalpage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </MainCard>
      <PaymentLinkModal
        open={PaymentModalOpen}
        onClose={() => {
          setSelectOrder({});
          setPaymentModalOpen(false);
        }}
        orderDetails={selectOrder}
      />
      <OrderCancelModal
        open={orderCancelModalOpen}
        handleClose={() => {
          setSelectOrder({});
          setOrderCancelModalOpen(false);
        }}
        orderDetails={selectOrder}
        handleSubmit={() => {
          setSelectOrder({});
          setOrderCancelModalOpen(false);
          dataLoad(search, page, rowsPerPage, statusFilter, recentDay);
        }}
      />
    </>
  );
}
