import React, { useEffect, useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Menu, TableRow } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { numericCommaSeparation } from "../../ui-component/NumericSeperation";
import { LoadingButton } from "@material-ui/lab";

function ReachedAtDestinationOrders() {
  const [date, setDate] = useState({
    startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    endDate: new Date().toISOString().slice(0, 10)
  });

  const [customerName, setCustomerName] = React.useState("");
  const [InvoiceId, setInvoiceId] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [data, setdata] = React.useState([]);
  const [selectedAgents, setSelectedAgents] = React.useState([]);
  const [sortBy, setSortBy] = React.useState(0);  
  const [agentsData, setAgentsData] = React.useState([]);
  const [numericOrderId, setNumericOrderId] = React.useState("");
  const [totalInvoiceAmount, setTotalInvoiceAmount] = React.useState(0);
  const [totalPayableAmounts, setTotalPayableAmounts] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalpage, setTotalPage] = useState();
  const [loading,setLoading] = useState(false)  

  
  const handleSearch = (cp=page,limit=rowsPerPage) => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_PATH}/sales/getDestinationReachedOrders`, {
        customerName,
        invoiceNumber: InvoiceId,
        phoneNumber,
        startDate: date.startDate,
        endDate: date.endDate,
        sortBy:sortBy,
        agentIds:selectedAgents.map((item)=>item._id),
        numericOrderId,
        page: cp,
        limit: limit,
      })
      .then((res) => {
        if (res.data.success) {
          let tempData = res.data.data[0]?.data
          setTotalPage(res.data.data[0]?.metaData[0]?.totalDocuments||0)

          const groupData = {}
          const childData = []
          let tempTotal = 0
          let tempTotalInvoice=  0
          
          tempData.forEach((item) => {
            if(groupData[item.phoneNumber]){
              groupData[item.phoneNumber].push(item)
            }else{
              groupData[item.phoneNumber] = [item]
            } 
          })

          for(const key in groupData){
            
            let sortData = groupData[key].sort((a,b)=>moment(b.reachedAtDestinationAt).diff(a.reachedAtDestinationAt))
            let sortReverse = sortData.reverse()
            let totalInvoice = groupData[key].reduce((acc,curr)=>acc+curr.zohoInvoice?.total,0)
            let totalPay = groupData[key].reduce((acc,curr)=>acc+curr.totalPayableAmount,0)
            let totalDays = moment(moment.now()).diff(sortReverse[0].reachedAtDestinationAt,'day')
            tempTotal+=totalPay
            tempTotalInvoice+=totalInvoice
            childData.push({
              details:{...sortReverse[0],totalInvoice:totalInvoice,totalPay:totalPay,totalDaysDiff:totalDays},
              allOrders:sortReverse
            })
          }
          setTotalInvoiceAmount(tempTotalInvoice)
          setTotalPayableAmounts(tempTotal)
          setdata(childData);
          setLoading(false)
        } else {
          alert(res.data.message);
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.message || "something went wrong");
        setLoading(false)
      });
  };



  function CollapesRow(props) {
    const { item,allOrders } = props;
    const [open, setOpen] = React.useState(false);
    const [cnLinkLoading,setCnLinkLoading] = useState(false)
    const getCNLink = (orderId,invoiceUrl) => {
      if(invoiceUrl){
        window.open(invoiceUrl, "_blank");
        return;
      }
  
      setCnLinkLoading(true)
      axios
        .post(`https://prelive.buyume.io/products/getCreditNotePdf`, {
          orderId: orderId,
        })
        .then((res) => {
          if (res.data.success) {
            handleSearch(page,rowsPerPage)
            setCnLinkLoading(false)
            window.open(res.data.message, "_blank");
          } else {
            alert(res.data.message);
            setCnLinkLoading(false)
          }
  
        })
        .catch((err) => {
          console.log(err);
          setCnLinkLoading(false)
          alert(err?.response?.data?.message || "something went wrong");
        });
    }
  
    

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{maxWidth:200}}>{item.name}</TableCell>
          <TableCell>{item.phoneNumber}</TableCell>
          <TableCell>{allOrders.length}</TableCell>
          <TableCell>{numericCommaSeparation(item.totalInvoice)}</TableCell>
          <TableCell>{numericCommaSeparation(Math.round(item.totalPay))}</TableCell>
          <TableCell>
            {item?.totalDaysDiff}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Invoice Id</TableCell>
                      <TableCell>Invoice Amount</TableCell>
                      <TableCell>Total Payabel</TableCell>
                      <TableCell>Days after reaching Destination</TableCell>
                      <TableCell>Invoice Link</TableCell>
                      <TableCell>CN Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { allOrders && allOrders.map((orders) => (
                      <TableRow>
                        <TableCell>{orders.name}</TableCell>
                        <TableCell>{orders.numericOrderId}</TableCell>
                        <TableCell>{orders.zohoInvoice?.zohoInvoiceNumber}</TableCell>
                        <TableCell>{numericCommaSeparation(orders.zohoInvoice?.total)}</TableCell>
                        <TableCell>{numericCommaSeparation(Math.round(orders.totalPayableAmount))}</TableCell>
                        <TableCell>{moment(moment.now()).diff(orders.reachedAtDestinationAt,'day') }</TableCell>
                        <TableCell>{
                          <a
                              href={orders?.zohoInvoice?.invoiceUrl}
                              target="_blank"
                            >
                              <p>Open</p>
                            </a>
                        }</TableCell>
                        <TableCell>
                          <LoadingButton
                          loading={cnLinkLoading}
                          loadingIndicator='Loading...' 

                            variant='contained'
                            onClick={()=>{getCNLink(orders._id,orders.zohoInvoice?.creditNoteInvoiceUrl)}}
                          >Open</LoadingButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PATH}/admin/getBulkAgents`)
      .then((res) => {
        if (res.data.success) {
          if (res?.data?.data?.length > 0) {
            setAgentsData(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    handleSearch( "", +event.target.value);
  };

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
      setRowsPerPage(rowsPerPage);
      handleSearch(newPage, rowsPerPage);
  };


  return (
    <SubCard>
      <Grid container spacing={2}>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
            fullWidth
          />

        </Grid>

        <Grid item lg={3}>
          <Select
            label="Sort By"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            fullWidth
          >
            <MenuItem value={0}>Sort By Days</MenuItem>
            <MenuItem value={1}>Sort By Amount</MenuItem>
          </Select>
        </Grid>

      </Grid>
      <Grid container lg={12} spacing={2} mt={2} alignItems={'center'}>
        <Grid item lg={3} md={3}>
          <TextField
            label="Customer Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Invoice Id"
            value={InvoiceId}
            onChange={(e) => setInvoiceId(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Order Id"
            value={numericOrderId}
            onChange={(e) => setNumericOrderId(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <Autocomplete 
              id="tags-outlined"
              options={agentsData}
              fullWidth
              multiple
              getOptionLabel={(option) => `${option.name} (${option.userName})`}
              onChange={(e, v) => setSelectedAgents(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Agents"
                  placeholder="Select Agents"
                />
              )}
              value={selectedAgents}            
          />
        </Grid>
        <Grid item lg={3}>
        <LoadingButton
            variant="contained"
            color="primary"
            onClick={()=>handleSearch(page,rowsPerPage)}
            fullWidth
            loading={loading}
            loadingIndicator="Loading..."
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
      {/* Table */}
      <TableContainer style={{ maxHeight: 440, marginTop: 20 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Orders</TableCell>
              <TableCell>Invoice Amount ({numericCommaSeparation(totalInvoiceAmount||0)})</TableCell>
              <TableCell>Total Payabel ({numericCommaSeparation(totalPayableAmounts||0)})</TableCell>
              <TableCell>Last Order</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data&& data.length>0 &&data.sort((a,b)=> (sortBy==0? b.details.totalDaysDiff-a.details.totalDaysDiff:b.details.totalPay-a.details.totalPay)).map((orderData,index) => (
              <CollapesRow key={index} item={orderData.details} allOrders = {orderData?.allOrders}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
            rowsPerPageOptions={[10, 25, 100,200]}
            component="div"
            count={totalpage ? totalpage : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </SubCard>
  );
}

export default ReachedAtDestinationOrders;
