import axios from "axios";
import React, { useEffect, useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { numericCommaSeparation } from "../../ui-component/NumericSeperation";
import { FormControl, InputLabel } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Label } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import OrderCancelModal from "../ordersCrm/OrderCancelModal";
import { getDecryptDataFromLocal } from "../../utils/encrypt";
import OrderCancelRemarkModal from "../Bulk/OrderCancelRemarkModal";

const stageDetails = [
  "Stage 0: Required agent & client approval",
  "Stage 1: Required warehouse inventory (pushpender) approval",
  "Stage 2: Required agent approval",
  "Stage 3: Warehouse picking/packing & uc invoicing is pending",
  "Stage 4: Zoho invoicing is pending",
  "Stage 5: Order dispatching is pending",
  "Stage 6: Order is dispatched",
];

const orderType = {
  1:'BULK',
  2:'GT',
  3:"BLINKIT"
}

const Headers = [
  { key: "numericOrderId", label: "order id" },
  { key: "name", label: "Name" },
  { key: "phoneNumber", label: "phoneNumber" },
  { key: "orderStageV2", label: "orderStageV2" },
  { key: "totalPayableAmount", label: "totalPayableAmount" },
  { key: "orderType2", label: "Order Type" },
  { key: "agentName", label: "Agent Name" },
  { key: "agentRemark", label: "Agent Remark" },
  { key: "clientRemark", label: "Client Remark" },
  { key: "percentageOfTotalAvailable", label: "Percentage Of Total Available" },
  { key: "stage1", label: "Stage 1 Date" },
  { key: "stage2", label: "Stage 2 Date" },
  { key: "stage3", label: "Stage 3 Date" },
  { key: "stage4", label: "Stage 4 Date" },
  { key: "stage5", label: "Stage 5 Date" },
  { key: "stage6", label: "Stage 6 Date" },
]

function StageWiseOrders() {
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const [data, setdata] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(0);
  const [orderData, setOrderData] = useState(null);
  const [clientRemark, setClientRemark] = useState("");
  const [agentRemark, setAgentRemark] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [clientVerified, setClientVerified] = useState(false);
  const [agentVerified, setAgentVerified] = useState(false);
  const [numericOrderId, setNumericOrderId] = useState(null);
  const [allOnlineEANCodeQuantity,setAllOnlineEANCodeQuantity] = useState([])
  const [allBulkEANCodeQuantity,setAllBulkEANCodeQuantity] = useState([])
  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false)
  const [selectOrderId,setSelectOrderId]  = useState(null)
  const phoneNumber = getDecryptDataFromLocal("phoneNumber");
  const lorealOrMatrix =['60f8198155498632e803cefc','60f8198155498632e803ceec']

  const handleSearch = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_PATH}/products/stageWiseBulkOrders`, {
        startDate: date.startDate,
        endDate: date.endDate,
        orderStageV2: stage,
        numericOrderId: numericOrderId,
      })
      .then((res) => {
        if (res.data.success) {
          let tempData = res.data.data;
          let tempOrder = res.data?.data?.orders;
          tempOrder &&
            tempOrder?.length > 0 &&
            tempOrder.forEach((item) => {
              let totalQuantity = 0
              let availableQuantity = 0
              let bulkAvailableInv = 0
              item.orderType2 = orderType[item.orderType]
              item.clientRemark = item?.clientLevelApproval
                ? item?.clientLevelApproval[
                    item?.clientLevelApproval?.length - 1
                  ]?.remark || ""
                : "";
              item.agentRemark = item?.agentLevelApproval
                ? item?.agentLevelApproval[item?.agentLevelApproval?.length - 1]
                    ?.remark || ""
                : "";

                if(stage==0){ 
                  item.products.forEach((pro)=>{
                    let onlineInv = lorealOrMatrix.includes(pro.brandId)?0:(allBulkEANCodeQuantity[pro.EANCode]||0)
                    let bulkInv =( allOnlineEANCodeQuantity[pro.EANCode]||0)
                    totalQuantity+=pro.quantity
                    availableQuantity+=((bulkInv+onlineInv)>=pro.quantity?pro.quantity:(bulkInv+onlineInv)||0)
                    bulkAvailableInv+=((bulkInv)>=pro.quantity?pro.quantity:(bulkInv)||0)

                  })
                  item.percentageOfTotalAvailable = ((availableQuantity/totalQuantity)*100).toFixed(2)
                  item.percentageOfBulkAvailable = ((bulkAvailableInv/totalQuantity)*100).toFixed(2)
                }
               item?.orderStageV2Log && item?.orderStageV2Log.length>0 && item?.orderStageV2Log.forEach((logs)=>{
                if(logs.stageChangedTo==1){
                  item.stage1 = moment(logs.stageChangedAt).format('hh:mm A DD-MM-YYYY')
                }
                if(logs.stageChangedTo==2){
                  item.stage2 = moment(logs.stageChangedAt).format('hh:mm A DD-MM-YYYY')
                }
                if(logs.stageChangedTo==3){
                  item.stage3 = moment(logs.stageChangedAt).format('hh:mm A DD-MM-YYYY')
                }
                if(logs.stageChangedTo==4){
                  item.stage4 = moment(logs.stageChangedAt).format('hh:mm A DD-MM-YYYY')
                }
                if(logs.stageChangedTo==5){
                  item.stage5 = moment(logs.stageChangedAt).format('hh:mm A DD-MM-YYYY')
                }
                if(logs.stageChangedTo==6){
                  item.stage6 = moment(logs.stageChangedAt).format('hh:mm A DD-MM-YYYY')
                }
               }) 
            });
          setOrderData(tempData?.deatils || null);
          setdata(tempOrder || []);
          setLoading(false);
        } else {
          alert(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.message || "something went wrong");
        setLoading(false);
      });
  };

  const handleClientSubmit = () => {
    axios
      .post(
        process.env.REACT_APP_PATH +
          "/products/approveBulkOrdersAsClientAndAgentLevel",
        {
          orderId: selectedOrder._id,
          isApproved: clientVerified,
          isAgentOrClientLevel: clientRemark.trim().length > 0 ? 1 : null,
          remark: clientRemark,
        }
      )
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "Successfully submit");
          setClientRemark("");
          handleSearch();
          handelClose();
        } else {
          alert(res.data?.message || "Someting went wrong");
        }
      })
      .catch((error) => {
        alert(res.data?.message || "Someting went wrong");
      });
  };
  const handelOrderCancel = () => {
    setIsRemarkModalOpen(true);
  };

  const getOnLineUcInventory = async (EANCode) => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_PATH}/products/getAllUcInventoryV2`,{
        isBulkOrOnline:true
      })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            setAllOnlineEANCodeQuantity(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.message || "something went wrong");
      });
  };
  const getBulkUcInventory = async (EANCode) => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_PATH}/products/getAllUcInventoryV2`,{
        isBulkOrOnline:false
      })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            setAllBulkEANCodeQuantity(res.data.data);
            setLoading(false)
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
        alert(err?.response?.data?.message || "something went wrong");
      });
  };

  const handelClose = () => {
    setOpenModal(false);
    setSelectedOrder(null);
    setClientRemark("");
    setAgentRemark("");
  };
  const handleAgentSubmit = () => {
    console.log("Agent Remark Submitted:", agentRemark);
    axios
      .post(
        process.env.REACT_APP_PATH +
          "/products/approveBulkOrdersAsClientAndAgentLevel",
        {
          orderId: selectedOrder._id,
          isApproved: agentVerified,
          isAgentOrClientLevel: agentRemark.trim().length > 0 ? 2 : null,
          remark: agentRemark,
        }
      )
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "Successfully submit");
          setAgentRemark("");
          handleSearch();
          handelClose();
        } else {
          alert(res.data?.message || "Someting went wrong");
        }
      })
      .catch((error) => {
        alert(res.data?.message || "Someting went wrong");
      });
  };
  const CancelOrder = (remark) => {
      axios
        .post(
          process.env.REACT_APP_PATH + "/products/cancelBulkInvoiceOrders",
          {
            orderId: selectOrderId,
            remarks:remark
          }
        )
        .then((res) => {
          if (res.data.success) {
            alert(res.data?.message || "Successfully cancel order");
            setIsRemarkModalOpen(false)
            setSelectOrderId(null)
            handelClose()
            handleSearch()
          } else {
            alert(res.data?.message || "Someting went wrong");
          }
        })
        .catch((error) => {
          console.log("error", error);
          alert("Someting went wrong");
        });
  
  };

  useEffect(()=>{
    if(allOnlineEANCodeQuantity && allBulkEANCodeQuantity){
      handleSearch()
    }
  },[stage])

  useEffect(()=>{
    getOnLineUcInventory()
    getBulkUcInventory()
  },[])

  return (
    <SubCard>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <TextField
            id="numericOrderId"
            label="Numeric Order id"
            defaultValue={numericOrderId}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) => setNumericOrderId(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} position={"relative"}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-helper-label"
              style={{ backgroundColor: "#fff" }}
            >
              Select Stage
            </InputLabel>
            <Select
              label="Select Stage"
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper-label"
              value={stage}
              onChange={(e) => setStage(e.target.value)}
              fullWidth
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={0}>Stage 0</MenuItem>
              <MenuItem value={1}>Stage 1</MenuItem>
              <MenuItem value={2}>Stage 2</MenuItem>
              <MenuItem value={3}>Stage 3</MenuItem>
              <MenuItem value={4}>Stage 4</MenuItem>
              <MenuItem value={5}>Stage 5</MenuItem>
              <MenuItem value={6}>Stage 6</MenuItem>
            </Select>
          </FormControl>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            style={{ position: "absolute", right: -20, top: -15 }}
          >
            <Tooltip
              title={
                <Box>
                  {stageDetails.map((detail, index) => (
                    <Typography color={"#fff"} key={index} variant="body2">
                      {detail}
                    </Typography>
                  ))}
                </Box>
              }
              arrow
              color="error"
            >
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleSearch()}
            fullWidth
            loading={loading}
            loadingIndicator="Loading..."
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {orderData && (
          <Grid my={2} item>
            <Box>
              <Card sx={{ maxWidth: 300, p: 2, boxShadow: 3, borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Order Summary
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="body1">
                      <strong>Total Orders:</strong>{" "}
                      {orderData.totalOrdersCount}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Total Amount:</strong> {orderData.totalAmount}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        )}
        <Grid item>
          {data?.length > 0 && (
            <CSVLink
              data={data}
              style={{
                textDecoration: "none",
                border: "1px solid #999",
                padding: "10px 20px",
                backgroundColor: "#95c7f1",
                marginTop: 15,
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: 120,
              }}
              headers={Headers.filter((item)=>(stage!=0? item.key!='percentageOfTotalAvailable':true))}
              title="StageWise Orders"
              filename="StageWise Orders"
            >
              Download
            </CSVLink>
          )}
        </Grid>
      </Grid>

      <Grid container>
        <TableContainer style={{ marginTop: 20, maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Order Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Agent Name</TableCell>
                <TableCell>Stage</TableCell>
                <TableCell>Order Type</TableCell>
                <TableCell>Total Payable</TableCell>
                {[6,'all'].includes(stage) &&  <TableCell>AWB Number</TableCell>}
                {[5,6,'all'].includes(stage) && <TableCell>Zoho Invoice Number</TableCell>}
                {[5,6,'all'].includes(stage) &&  <TableCell>Zoho Invoice Link</TableCell>}
               {stage==0 &&<TableCell>Percentage Of Available Quanitity</TableCell>} 
               {stage==0 &&<TableCell>Percentage Of Bulk Quanitity</TableCell>} 

                <TableCell>Action</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                          <a
                            target="_blank"
                            href={`/bulk/searchorders?orderId=${item?.numericOrderId}`
                            }
                          >
                           {item?.numericOrderId}
                          </a>
                       
                      </TableCell>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.phoneNumber}</TableCell>
                      <TableCell>{item?.agentName||''}</TableCell>
                      <TableCell>{item?.orderStageV2}</TableCell>
                      <TableCell>{orderType[item?.orderType]}</TableCell>
                      <TableCell>{numericCommaSeparation(Math.round(item?.totalPayableAmount))}</TableCell>
                      {[6,'all'].includes(stage) &&<TableCell>{item?.awbNumber}</TableCell>}
                      {[5,6,'all'].includes(stage)&&<TableCell>{item?.zohoInvoice?.zohoInvoiceNumber||''}</TableCell>}
                      {[5,6,'all'].includes(stage)&&<TableCell>
                        {
                         item?.zohoInvoice?.zohoInvoiceNumber &&  <Button onClick={()=>{
                          window.open(item?.zohoInvoice?.invoiceUrl)
                        }}
                        >
                              Open Invoice
                          </Button>
                        }
                     
                </TableCell>}
                    {stage==0 && <TableCell>{item?.percentageOfTotalAvailable||0} %</TableCell>}  
                    {stage==0 && <TableCell>{item?.percentageOfBulkAvailable||0} %</TableCell>}  

                      <TableCell>
                        {item?.orderStageV2 == 0 && (
                          <Button
                            onClick={() => {
                              setOpenModal(true);
                              setSelectedOrder(item);
                              setSelectOrderId(item._id)
                              setAgentRemark(
                                item?.agentLevelApproval
                                  ? item?.agentLevelApproval[
                                      item?.agentLevelApproval?.length - 1
                                    ]?.remark || ""
                                  : ""
                              );
                              setClientRemark(
                                item?.clientLevelApproval
                                  ? item?.clientLevelApproval[
                                      item?.clientLevelApproval?.length - 1
                                    ]?.remark || ""
                                  : ""
                              );
                              setClientVerified(item?.isApprovedByClient);
                              setAgentVerified(item?.isApprovedByAgent);
                            }}
                            variant="outlined"
                          >
                            Order Verification
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/stagebulkorderdetails/${item._id}`}
                          style={{
                            color: item.paymentMethod == 1 ? "#000" : "green",
                          }}
                          target="_blank"
                        >
                          Details
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Modal
        open={openModal}
        onClose={handelClose}
        aria-labelledby="order-verification"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            // height: 300,
          }}
        >
          {/* Header */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Order Verification</Typography>
            <IconButton onClick={handelClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Client Remark */}
          <Box mt={6} display="flex" alignItems="center" gap={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={clientVerified}
                  onChange={() => setClientVerified(!clientVerified)}
                />
              }
              label="Approve by client"
            />
            <TextField
              label="Client Remark"
              fullWidth
              variant="outlined"
              size="small"
              value={clientRemark}
              onChange={(e) => setClientRemark(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleClientSubmit}
              disabled={clientRemark.trim().length == 0 ? true : false}
            >
              Submit
            </Button>
          </Box>

          {/* Agent Remark */}
          <Box mt={8} display="flex" alignItems="center" gap={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agentVerified}
                  onChange={() => setAgentVerified(!agentVerified)}
                />
              }
              label="Approve by agent"
            />
            <TextField
              label="Agent Remark"
              fullWidth
              variant="outlined"
              size="small"
              value={agentRemark}
              onChange={(e) => setAgentRemark(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAgentSubmit}
              disabled={agentRemark.trim().length == 0 ? true : false}
            >
              Submit
            </Button>
          </Box>
          <Box mt={6} display="flex" alignItems="center" gap={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handelOrderCancel}
              disabled={
                ["8178277221", "9719836187"].includes(phoneNumber)
                  ? false
                  : true
              }
            >
              Order cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* <OrderCancelModal
        open={orderCancelModal}
        handleClose={() => {
          setSelectedOrder({});
          setOrderCancelModal(false);
        }}
        orderDetails={selectedOrder}
        handleSubmit={() => {
          setOrderCancelModal(false);
          setSelectedOrder({});
          handleSearch();
        }}
      /> */}

      <OrderCancelRemarkModal 
        open={isRemarkModalOpen}
        onClose={()=>{
          setIsRemarkModalOpen(false)
          setSelectOrderId(null)
        }}
        onSubmit={CancelOrder}
      />
    </SubCard>
  );
}

export default StageWiseOrders;
