import React, { useState } from "react";
import SubCard from "../../../ui-component/cards/SubCard";
import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

function MoveBulkStageOrder() {
  const [orderId, setOrderId] = useState("");
  const [revertOrderId,setRevertOrderId] =  useState('')
  const [loading, setLoading] = useState(false);
  const [revertLoading,setRevertLoading] = useState(false)
  const handelRevert = () => {
    let confirm = window.confirm('Do you want to revert this order? Please confirm.')
    if(!confirm) return
    setLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/products/moveBulkOrderInStage1", {
        numericOrderId: revertOrderId,
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "order revert successfully");
          window.location.reload();
          setLoading(false);
        } else {
          alert(res.data?.message || "Someting went wrong");
          setLoading(false);
        }
      })
      .catch((error) => {
        alert("Someting went wrong");
        setLoading(false);
      });
  };
  const handleMoveOrder = () => {
    let confirm = window.confirm('Are you sure , you want to Move this order?')
    if(!confirm) return
    setRevertLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/products/moveOrderStageV2ToZero", {
        numericOrderId: orderId,
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "Successfully Move Order in stage 1");
          window.location.reload();
          setLoading(false);
        } else {
          alert(res.data?.message || "Someting went wrong");
          setLoading(false);
        }
      })
      .catch((error) => {
        alert("Someting went wrong");
        setLoading(false);
      });
  };
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} item>
        <SubCard title={"Revert Order"}>
          <Grid container>
            <TextField
              value={revertOrderId}
              onChange={(t) => setRevertOrderId(t.target.value)}
              title="Enter Order Id"
              sx={{ marginRight: 1 }}
              placeholder="Enter Order Id"
            />
            <LoadingButton
              loading={revertLoading}
              loadingIndicator="Loading..."
              variant="outlined"
              onClick={handelRevert}
            >
              Submit
            </LoadingButton>
          </Grid>
        </SubCard>
      </Grid>
      <Grid xs={12} md={6} item>
        <SubCard title={"Move Bulk Order Stage in 0"}>
          <Grid container>
            <TextField
              value={orderId}
              onChange={(t) => setOrderId(t.target.value)}
              title="Enter Order Id"
              sx={{ marginRight: 1 }}
              placeholder="Enter Order Id"
              
            />
            <LoadingButton
              loading={loading}
              loadingIndicator="Loading..."
              variant="outlined"
              onClick={handleMoveOrder}
            >
              Submit
            </LoadingButton>
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
}

export default MoveBulkStageOrder;
